import { coreApi } from './coreApiSlice';
import type { Alignment, AlignmentRequest, AlignmentsSummary, AlignmentsSummaryRequest, AlignmentsSummaryResult, AlignmentSummaryResponseItem, CosmosAlignment } from '../../../../shared/src/alignmentTypes';
import { MercatorTransform } from '@shared/geometry/transforms/transforms';
import { Datum, type GeodeticCoordinate } from '@shared/geometry/core/Coordinate';

export const alignmentApi = coreApi.injectEndpoints({
	endpoints: (build) => ({
		getAlignmentsSummary: build.query<AlignmentsSummaryResult, AlignmentsSummaryRequest>({
			query: (details) => ({
				url: 'alignments/' + details.jobId + '/summary',
				method: 'GET'
			}),

			transformResponse: (response: AlignmentSummaryResponseItem[], meta, arg) => {
				const queryCost = parseFloat(meta?.response?.headers.get('x-paveset-query-cost') || '-1');
				const roads = groupAlignmentsByRoad(response);

				return {
					queryCost,
					jobId: arg.jobId,
					summary: roads
				};
			}
		}),

		getAlignmentTMR: build.query<Alignment, AlignmentRequest>({
			query: (details) => ({
				url: 'alignments/TMR-METRO/' + details.roadId + '/' + details.carriagewayCode,
				method: 'GET'
			}),

			transformResponse: (response: CosmosAlignment, _meta, arg): Alignment => {
				const transform = MercatorTransform.createGDA2020();

				return {
					jobId: response.jobId,
					id: response.id,

					roadId: arg.roadId,
					sectionId: arg.carriagewayCode,

					name: response.roadName,
					description: response.description,
					vertices: response.segments.map(([ch, lat, lon]) => {
						const loc: GeodeticCoordinate = {
							lat,
							lon,
							datum: Datum.GDA2020
						};
						const coord = transform.transformRedfearn(loc);

						return {
							chainage: ch,
							loc,
							coord
						};
					})
				};
			}
		})
	}),

	overrideExisting: false,
});

export const {
	useGetAlignmentsSummaryQuery,
	useLazyGetAlignmentsSummaryQuery,

	useGetAlignmentTMRQuery,
	useLazyGetAlignmentTMRQuery,

	usePrefetch: useAlignmentsPrefetch
} = alignmentApi;

function groupAlignmentsByRoad(alignments: AlignmentSummaryResponseItem[]): AlignmentsSummary[] {
	const grouped = new Map<string, AlignmentsSummary>();

	for (const alignment of alignments) {
		let summary = grouped.get(alignment.roadId);
		if (!summary) {
			summary = {
				roadId: alignment.roadId,
				roadName: alignment.roadName,
				carriageways: []
			};
			grouped.set(alignment.roadId, summary);
		}

		summary.carriageways.push({
			code: alignment.carriagewayCode,
			description: alignment.carriagewayDescription
		});
	}

	for (const road of grouped) {
		road[1].carriageways.sort((a, b) => a.code.localeCompare(b.code));
	}

	return Array.from(grouped.values()).filter((s) => !!s.roadId && !!s.roadName && !s.carriageways.some((c) => !c.code || !c.description));
}