import type { CMVType, FailureReason } from '@analysis/RollerAnalysis';
import type { Point, Rect } from '@shared/geometry/core/Coordinate';
import type { RollerCell } from '../../../data/rollerTypes';
import type { Alignment, AlignmentVertex } from '@shared/alignmentTypes';
import { CanvasState } from '../map/CanvasState';
import { Vector2 } from '@shared/geometry/core/Vectors';

export interface DrawingState {
	ctx: CanvasRenderingContext2D;
	canvasState: CanvasState;
	offset: Point;
	clip?: Rect;
}

export function diffPoints(p1: Point, p2: Point) {
	return {
		x: p1.x - p2.x,
		y: p1.y - p2.y
	};
}

export const passCountColors: string[] = [
	'darkred',
	'red',
	'orange',
	'yellow',
	//'greenyellow',
	'limegreen',
	//'cyan',
	'blue',
	//'purple',
	'magenta',
];

export const cmvColors: string[] = [
	'red',
	'orangered',
	'darkorange',
	'orange',
	'yellow',
	'limegreen',
	'springgreen',
	'cyan',
	'dodgerblue',
	'blue',
];

export interface CMVRange {
	min: number; // inclusive min
	max: number; // exclusive max
	color: string;
}

export const CMVBins: number[] = [
	0,
	10,
	20,
	30,
	40,
	50,
	60,
	70,
	80,
	90,
	100,
	110,
	120,
	130,
	140,
	150,
	160,
	170,
	180,
	190,
	200,
	210,
	220,
	230,
	240,
	250
];

export const cmvColorRanges: CMVRange[] = [
	{ min: 0, max: 10, color: 'darkred' },
	{ min: 10, max: 0, color: 'red' },
	{ min: 20, max: 0, color: 'orange' },
	{ min: 30, max: 0, color: 'yellow' },
	{ min: 40, max: 0, color: 'greenyellow' },
	{ min: 60, max: 0, color: 'limegreen' },
	{ min: 80, max: 0, color: 'springgreen' },
	{ min: 100, max: 0, color: 'dodgerblue' },
	{ min: 150, max: 250, color: 'blue' },
];

(function initCMVColorRanges() {
	for (let i = 0; i < cmvColorRanges.length - 1; ++i) {
		cmvColorRanges[i].max = cmvColorRanges[i + 1].min;
	}
})();

export const failureColors: Record<FailureReason, string> = {
	// asphalt spec failures
	passes: 'red',
	temperature: 'orange',
	time: 'blue',

	// base spec failures
	cmv: 'red',
	invalid: 'black'
};

export function getCellColor(cell: RollerCell): string {
	if (cell.passes.length >= passCountColors.length) {
		return passCountColors[passCountColors.length - 1];
	}

	return passCountColors[cell.passes.length - 1];
}

export function getCMVBinIndex(cmv: number): number {
	for (let i = CMVBins.length - 1; i >= 0; --i) {
		if (cmv >= CMVBins[i]) {
			return i;
		}
	}

	return 0;
}

export function getCMVColorIndex(cmv: number): number {
	for (let i = cmvColorRanges.length - 1; i >= 0; --i) {
		const range = cmvColorRanges[i];
		if (cmv >= range.min) {
			return i;
		}
	}

	return 0; // default to the lowest color
}

export function getBaseMapCellColor(cell: RollerCell, type: CMVType): string {
	const cmv = cell.getCMV(type);
	// if CMV does not exist or somehow has a garbage (< 0) value, return black
	if (cmv === null || cmv < 0) {
		return 'black';
	}

	return cmvColorRanges[getCMVColorIndex(cmv)].color;
}

export function inflateRect(rect: Rect, padding: number): Rect {
	return {
		x: rect.x - padding,
		y: rect.y - padding,
		width: rect.width + 2 * padding,
		height: rect.height + 2 * padding
	};
}

export function isWithinRect(point: Point, rect: Rect): boolean {
	return (
		point.x >= rect.x &&
    point.x <= rect.x + rect.width &&
    point.y >= rect.y &&
    point.y <= rect.y + rect.height
	);
}

function getVisibleVertices(state: DrawingState, alignment: Alignment): AlignmentVertex[] {
	const vertices = alignment.vertices;
	const vertexCount = vertices.length;

	if (vertexCount < 2) {
		return [];
	}

	if (state.clip) {
		const clip = inflateRect(state.clip, 50);
		return vertices.filter((v) => isWithinRect(v.coord, clip));
	}

	return vertices;
}

export function drawAlignment(state: DrawingState, alignment: Alignment): void {
	const { ctx, canvasState, offset } = state;

	const visibleVertices = getVisibleVertices(state, alignment);
	const visibleVertexCount = visibleVertices.length;
	if (visibleVertexCount < 2) {
		return;
	}

	const markers = visibleVertices.map((v, i) => createChainageMarker(v, visibleVertices[(i + 1) % visibleVertexCount], i === 0));

	ctx.strokeStyle = 'black';
	ctx.lineWidth = 1 / canvasState.scale;

	ctx.beginPath();
	ctx.moveTo(visibleVertices[0].coord.x - offset.x, visibleVertices[0].coord.y - offset.y);
	for (let i = 1; i < visibleVertexCount; ++i) {
		ctx.lineTo(visibleVertices[i].coord.x - offset.x, visibleVertices[i].coord.y - offset.y);
	}
	ctx.stroke();

	for (const marker of markers.filter((m) => Math.floor(m.chainage * 1000) % 100 === 0)) {
		ctx.beginPath();
		ctx.moveTo(marker.start.x - offset.x, marker.start.y - offset.y);
		ctx.lineTo(marker.end.x - offset.x, marker.end.y - offset.y);
		ctx.stroke();

		drawChainageText(state, marker, 25);
	}
}

function drawChainageText(state: DrawingState, marker: ChainageMarker, chainageOffset: number) {
	const { ctx, offset } = state;
	ctx.save();

	const fontSize = 12 / state.canvasState.scale;
	ctx.font = `${fontSize.toFixed(2)}px Arial`;
	ctx.textAlign = 'left';
	ctx.textBaseline = 'middle';

	const text = `CH ${(marker.chainage * 1000).toFixed(0)}`;
	const fontMetrics = ctx.measureText(text);
	const fontHeight = fontSize; // fontMetrics.actualBoundingBoxAscent + fontMetrics.actualBoundingBoxDescent;
	const fontHeightOffset = fontHeight / 2;

	const v = Vector2.directional(marker.start, marker.end, true);
	const angle = v.calculateAngle();

	const textStart = v.multiplyScalar(chainageOffset).add(marker.center);

	scaleAndRotateAt(ctx, 1, -1, angle, textStart.x - offset.x, textStart.y - offset.y);
	ctx.fillStyle = 'white';
	ctx.fillRect(textStart.x - offset.x - 5, textStart.y - offset.y - fontHeightOffset - 3, fontMetrics.width + 10, fontHeight + 4);

	ctx.fillStyle = 'black';
	ctx.fillText(text, textStart.x - offset.x, textStart.y - offset.y);
	ctx.restore();
}

function scaleAndRotateAt(ctx: CanvasRenderingContext2D, scaleX: number, scaleY: number, angle: number, px: number, py: number) {
	ctx.translate(px, py);
	ctx.scale(scaleX, scaleY);
	ctx.rotate(angle);
	ctx.translate(-px, -py);
}

export interface ChainageMarker {
	chainage: number;

	center: Point;

	start: Point;
	end: Point;
}

export function createChainageMarker(a: AlignmentVertex, b: AlignmentVertex, createStartMarker: boolean): ChainageMarker {
	const lineVectr = Vector2.directional(a.coord, b.coord, true);
	const perpVectr = lineVectr.perpendicular('counterclockwise');
	const chainage = createStartMarker ? a.chainage : b.chainage;
	const coord = createStartMarker ? a.coord : b.coord;

	const size = 5;
	const start = perpVectr.multiplyScalar(size).add(coord);
	const end = perpVectr.multiplyScalar(-size).add(coord);

	return { chainage, center: coord, start, end };
}
