import { useIsXSmall } from '@hooks/responsiveHooks';
import type { SxProps } from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { memo, useMemo } from 'react';
import styles from './DataPickerSection.module.css';

export interface DataPickerSectionProps {
	children: React.ReactNode;
	containerStyles?: SxProps<Theme>;

	section: number;
	title: string;
	titleComponent?: (titleHeight: number, titleClass: string) => React.ReactNode;

	isComplete: boolean;
}

const DataPickerSection = (props: DataPickerSectionProps) => {
	const { section, title, children, isComplete, containerStyles, titleComponent } = props;
	const isXSmall = useIsXSmall();

	const titleHeight = isXSmall ? 48 : 56;
	const numberingClass = isXSmall ? styles.numberingTextSmall : styles.numberingText;
	const titleClass = isXSmall ? styles.titleTextSmall : styles.titleText;

	const paperStyle: SxProps<Theme> = useMemo(() => ({
		overflow: 'hidden',
		width: '100%',
		pl: 1,
		pr: 1,
		pb: 1,
		borderRadius: 4,
		mt: { xs: 'unset', sm: 'auto!important' },

		...containerStyles,
	}), [containerStyles]);

	const titleElement = useMemo(() => {
		if (titleComponent) {
			return titleComponent(titleHeight, titleClass);
		}

		return (
			<Typography
				height={titleHeight}
				width='100%'
				textAlign='center'
				alignContent='center'
				variant='h6'
				className={titleClass}>
				{title}
			</Typography>
		);
	}, [title, titleClass, titleComponent, titleHeight]);

	return (
		<Paper
			elevation={3}
			sx={paperStyle}>
			<Stack direction='column' height='100%'>
				<Stack direction='row' width='100%' height={titleHeight}>
					<Typography
						variant='h5'
						bgcolor={isComplete ? 'primary.light' : 'error.light'}
						alignContent='center'
						color='white'
						className={numberingClass}>
						{section}
					</Typography>

					{titleElement}
				</Stack>

				<Divider orientation='horizontal' />

				{children}
			</Stack>
		</Paper>
	);
};

export default memo(DataPickerSection);
