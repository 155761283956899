import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type {
	GridActionsCellItemProps,
	GridCellParams,
	GridColDef,
	GridEventListener,
	GridRenderEditCellParams,
	GridRowClassNameParams,
	GridRowEditStopParams,
	GridRowId,
	GridRowModel,
	GridRowModesModel,
	GridRowsProp,
	GridSlotsComponent,
	GridSlotsComponentsProps,
	GridToolbarProps,
	GridTreeNode,
	GridValidRowModel,
} from '@mui/x-data-grid';

import {
	DataGrid,
	GridActionsCellItem,
	GridRowEditStopReasons,
	GridRowModes,
	GridToolbarContainer,
	gridClasses,
} from '@mui/x-data-grid';

import {
	isAsphaltSpec,
	isBaseSpec,
	type CMVRange,
	type CompactionSpecification,
	type ReportType,
} from '@analysis/RollerAnalysis';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import CompactNumericInput from '@components/CompactNumericInput';
import { useIsXSmall } from '@hooks/responsiveHooks';
import { alpha, styled, type SxProps, type Theme } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectOverview, updateOverview, type PavementLayer } from '../dataPickerSlice';
import ReportOverviewFields from './ReportOverviewFields';

interface AsphaltRowModel {
	id: number;
	specNumber: number;
	type: 'ASPHALT';

	targetPasses: number;
	timeLimit: number | null;
	tempCutoff: number | null;

	isNew: boolean;
}

interface BaseRowModel {
	id: number;
	specNumber: number;
	type: 'BASE';

	targetCMV: number | null;
	targetEVib: number | null;

	targetCMVRange: CMVRange;

	isNew: boolean;
}

type RowModel = AsphaltRowModel | BaseRowModel;

function isAsphaltRow(row: RowModel): row is AsphaltRowModel {
	return (row as AsphaltRowModel).type === 'ASPHALT';
}

function isAsphalt(rows: readonly RowModel[]): rows is AsphaltRowModel[] {
	return rows.every(isAsphaltRow);
}

function isBaseRow(row: RowModel): row is BaseRowModel {
	return (row as BaseRowModel).type == 'BASE';
}

function isBase(rows: readonly RowModel[]): rows is BaseRowModel[] {
	return rows.every(isBaseRow);
}

interface EditToolbarProps {
	reportType: ReportType;
	rows: GridRowsProp<RowModel>;

	setRows: (newRows: (oldRows: RowModel[]) => RowModel[]) => void;
	setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

declare module '@mui/x-data-grid' {
	// eslint-disable-next-line @typescript-eslint/no-empty-object-type
	interface ToolbarPropsOverrides extends EditToolbarProps {}
}

function EditToolbar(props: GridToolbarProps & EditToolbarProps) {
	const { rows, setRows, setRowModesModel } = props;

	const handleClick = () => {
		const newId = rows.length + 1;

		setRows((oldRows) => {
			if (props.reportType === 'ASPHALT') {
				return [
					...oldRows,
					{
						id: newId,
						specNumber: newId,
						type: 'ASPHALT',
						targetPasses: 5,
						timeLimit: null,
						tempCutoff: null,
						isNew: true,
					},
				];
			} else {
				return [
					...oldRows,
					{
						id: newId,
						specNumber: newId,
						type: 'BASE',
						targetCMV: 0,
						targetEVib: null,
						targetCMVRange: {
							min: 20,
							max: 80,
							step: 10,
						},
						isNew: true,
					},
				];
			}
		});

		setRowModesModel((oldModel) => {
			// if there's an existing row in edit mode, save it
			for (const id in oldModel) {
				if (oldModel[id]?.mode === GridRowModes.Edit) {
					oldModel[id] = { mode: GridRowModes.View };
				}
			}

			return {
				...oldModel,
				[newId]: {
					mode: GridRowModes.Edit,
					fieldToFocus: props.reportType === 'ASPHALT' ? 'targetPasses' : 'targetCMV',
				},
			};
		});
	};

	return (
		<GridToolbarContainer>
			<Button
				color='primary'
				startIcon={<AddIcon />}
				onClick={handleClick}
				sx={{
					ml: 1,
				}}>
				Add Compaction Spec
			</Button>
		</GridToolbarContainer>
	);
}

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
	[`& .${gridClasses.row}.even`]: {
		backgroundColor: theme.palette.grey[100],
		'&:hover': {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		},
		'&.Mui-selected': {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
			'&:hover': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
				),
				// Reset on touch devices, it doesn't add specificity
				'@media (hover: none)': {
					backgroundColor: alpha(
						theme.palette.primary.main,
						ODD_OPACITY + theme.palette.action.selectedOpacity
					),
				},
			},
		},
	},
	[`&.${gridClasses.root} .${gridClasses['row--editing']} .${gridClasses.cell}`]: {
		backgroundColor: 'transparent',
	},
	[`&.${gridClasses.root} .${gridClasses['row--editing']} .MuiInputBase-root`]: {
		backgroundColor: 'white',
	},

	[`&.${gridClasses.root} .${gridClasses['cell--editing']}`]: {
		padding: 4,
	},
}));

interface ReportConfigTableProps {
	reportType: ReportType;
	readonly?: boolean;

	specs?: CompactionSpecification[];
	onSpecsUpdated?: (specs: CompactionSpecification[]) => void;

	onEditModeChange?: (isInEditMode: boolean) => void;
}

function createRows(specs?: CompactionSpecification[]): RowModel[] {
	if (specs && specs.length > 0) {
		if (isAsphaltSpec(specs[0])) {
			return specs.filter(isAsphaltSpec).map((spec) => ({
				id: spec.specNumber,
				type: spec.type,
				specNumber: spec.specNumber,
				targetPasses: spec.targetPasses,
				timeLimit: spec.timeLimit?.asMinutes() ?? null,
				tempCutoff: spec.temperatureCutoff ?? null,

				isNew: false,
			}));
		} else {
			return specs.filter(isBaseSpec).map((spec) => ({
				id: spec.specNumber,
				type: spec.type,
				specNumber: spec.specNumber,
				targetCMV: spec.targetCMV ?? null,
				targetEVib: spec.targetEVib ?? null,

				targetCMVRange: spec.cmvRange,

				isNew: false,
			}));
		}
	}

	return [];
}

function createSpecs(rows: readonly RowModel[]): CompactionSpecification[] {
	if (isAsphalt(rows)) {
		return rows.map((row) => ({
			type: 'ASPHALT',
			specNumber: row.specNumber,
			targetPasses: row.targetPasses,
			timeLimit: row.timeLimit ? dayjs.duration({ minutes: row.timeLimit }) : undefined,
			temperatureCutoff: row.tempCutoff ?? undefined,
		}));
	} else if (isBase(rows)) {
		return rows.map((row) => ({
			type: 'BASE',
			specNumber: row.specNumber,
			targetCMV: row.targetCMV ?? 0,
			targetEVib: row.targetEVib ?? 0,

			cmvType: 'firstPass',
			cmvRange: {
				min: 20,
				max: 100,
				step: 10,
			},
		}));
	} else {
		return [];
	}
}

function ReportConfigTable(props: ReportConfigTableProps) {
	const { reportType, readonly, specs, onSpecsUpdated, onEditModeChange } = props;
	const [rows, setRows] = useState<RowModel[]>(createRows(specs));
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
	const isXSmall = useIsXSmall();

	const overview = useAppSelector(selectOverview);
	const dispatch = useAppDispatch();

	const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((
		params: GridRowEditStopParams<RowModel>,
		event
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
	}, []);

	/**
	 * When the report type changes, clear the rows
	 */
	useEffect(() => {
		setRows((currentRows) => {
			if (
				(reportType === 'ASPHALT' && !isAsphalt(currentRows)) ||
				(reportType === 'BASE' && !isBase(currentRows))
			) {
				return [];
			}
			return currentRows;
		});
	}, [reportType]);

	const handleEditClick = useCallback((id: GridRowId) => () => {
		for (const row of rows) {
			if (row.id === id) {
				continue;
			}

			if (rowModesModel[row.id]?.mode === GridRowModes.Edit) {
				return;
			}
		}

		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.Edit },
		});
	}, [rowModesModel, rows]);

	const handleSaveClick = useCallback((id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
	}, [rowModesModel]);

	const handleDeleteClick = useCallback((id: GridRowId) => () => {
		setRows(rows.filter((row) => row.id !== id));
	}, [rows]);

	const handleCancelClick = useCallback((id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row) => row.id === id);
		if (editedRow!.isNew) {
			setRows(rows.filter((row) => row.id !== id));
		}
	}, [rowModesModel, rows]);

	const getRowActions: ({ id }) => React.ReactElement<GridActionsCellItemProps>[] = useCallback(({ id }: { id: GridRowId }) => {
		const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

		if (isInEditMode) {
			return [
				<GridActionsCellItem
					key='save'
					icon={<SaveIcon />}
					label='Save'
					sx={{
						color: 'primary.main',
					}}
					onClick={handleSaveClick(id)}
				/>,
				<GridActionsCellItem
					key='cancel'
					icon={<CancelIcon />}
					label='Cancel'
					className='textPrimary'
					onClick={handleCancelClick(id)}
					color='inherit'
				/>,
			];
		}

		return [
			<GridActionsCellItem
				key='edit'
				icon={<EditIcon />}
				label='Edit'
				className='textPrimary'
				onClick={handleEditClick(id)}
				color='inherit'
			/>,
			<GridActionsCellItem
				key='delete'
				icon={<DeleteIcon />}
				label='Delete'
				onClick={handleDeleteClick(id)}
				color='inherit'
			/>,
		];
	}, [rowModesModel, handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick]);

	const columns = useMemo(() => createColumns(isXSmall, reportType, readonly ?? false, getRowActions), [isXSmall, reportType, readonly, getRowActions]);
	const processRowUpdate = useCallback((newRow: GridValidRowModel) => {
		const updatedRow = { ...newRow, isNew: false } as GridRowModel<RowModel>;
		setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
		return updatedRow;
	}, [rows]);

	const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel);
	}, []);

	const handleCellDoubleClick = useCallback((params: GridCellParams<GridRowModel, unknown, unknown, GridTreeNode>) => {
		params.isEditable = !readonly;
	}, [readonly]);

	useEffect(() => {
		// if (readonly) {
		//   for (const id in rowModesModel) {
		//     if (rowModesModel[id]?.mode === GridRowModes.Edit) {
		//       setRowModesModel({
		//         ...rowModesModel,
		//         [id]: { mode: GridRowModes.View },
		//       });
		//     }
		//   }
		// }

		onEditModeChange?.(Object.values(rowModesModel).some((mode) => mode.mode === GridRowModes.Edit));
	}, [rowModesModel, onEditModeChange, readonly]);

	useEffect(() => {
		if (onSpecsUpdated) {
			onSpecsUpdated(createSpecs(rows));
		}
	}, [onSpecsUpdated, rows]);


	const handleJobIdUpdated = useCallback(
		(jobId: string | null) => {
			dispatch(updateOverview({ jobId }));
		},
		[dispatch]
	);

	const handleLayerUpdated = useCallback(
		(layer: PavementLayer | null) => {
			dispatch(updateOverview({ layer }));
		},
		[dispatch]
	);

	const handleMixTypeUpdated = useCallback(
		(mixType: string | null) => {
			dispatch(updateOverview({ mixType }));
		},
		[dispatch]
	);

	const handleThicknessUpdated = useCallback(
		(thickness: number | null) => {
			dispatch(updateOverview({ thickness }));
		},
		[dispatch]
	);

	//console.log('[ReportConfigTable] rendering...');

	const slots: Partial<GridSlotsComponent> = useMemo(() => ({
		toolbar: EditToolbar,
		pagination: () => null,
		footer: () => null,
	}), []);

	const slotProps: GridSlotsComponentsProps = useMemo(() => ({
		toolbar: { reportType, rows, setRows, setRowModesModel },

		// set dropdown cells to have maximum width of 200px
		// so they don't look stretched on desktop display
		baseSelect: {
			slotProps: {
				root: {
					sx: {
						maxWidth: 200,
					},
				},
			},
		},
	}), [reportType, rows, setRows, setRowModesModel]);

	const getRowClassName = useCallback((params: GridRowClassNameParams<GridValidRowModel>) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'), []);
	const style: SxProps<Theme> = useMemo(() => ({
		ml: -2,
		mr: -2,
		borderBottomWidth: 0,
		borderTopWidth: 0,
		// disable the focus outline for the various scenarios
		[`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
			outline: 'none',
		},
		[`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
			outline: 'none',
		},
		[`.${gridClasses.cell}.${gridClasses['cell--editing']}:focus-within`]: {
			outline: 'none',
		},
		// show the column separator
		['& .MuiDataGrid-columnSeparator']: {
			visibility: 'visible',
		},
	}), []);

	return (
		<Box
			sx={{
				height: '100%',
				minHeight: 120,
				width: '100%',
				'& .actions': {
					color: 'text.secondary',
				},
				'& .textPrimary': {
					color: 'text.primary',
				},
				display: 'flex',
				flexDirection: 'column',
			}}>
			<ReportOverviewFields
				isXSmall={isXSmall}
				overview={overview}
				onJobIdChanged={handleJobIdUpdated}
				onLayerChanged={handleLayerUpdated}
				onMixTypeChanged={handleMixTypeUpdated}
				onThicknessChanged={handleThicknessUpdated}
			/>

			<StripedDataGrid
				rows={rows}
				columns={columns}
				editMode='row'
				onCellDoubleClick={handleCellDoubleClick}
				rowSelection={false}
				rowModesModel={rowModesModel}
				onRowModesModelChange={handleRowModesModelChange}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				slots={slots}
				slotProps={slotProps}
				sx={style}
				getRowClassName={getRowClassName}
			/>
		</Box>
	);
}

export default ReportConfigTable;

function createColumns(isXSmall: boolean, reportType: ReportType, readonly: boolean, getRowActions: ({ id }) => React.ReactElement<GridActionsCellItemProps>[]) {
	const columns: GridColDef[] = [
		{
			field: 'specNumber',
			headerName: '#',
			maxWidth: isXSmall ? 48 : 64,
			minWidth: isXSmall ? 48 : 64,
			align: 'center',
			headerAlign: 'center',
			editable: false,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			valueFormatter: (value: number) => {
				return `#${value}`;
			},
		},
	];

	if (reportType === 'ASPHALT') {
		columns.push(
			...([
				{
					field: 'targetPasses',
					headerName: isXSmall ? 'Target' : 'Target Passes',
					type: 'singleSelect',
					flex: 0.33,
					align: 'center',
					headerAlign: 'center',
					editable: true,
					disableColumnMenu: true,
					sortable: false,
					resizable: false,
					valueOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
					valueFormatter(value: number, row: AsphaltRowModel, _column: GridColDef, apiRef) {
						if (apiRef.current.getRowMode(row.id) === 'edit') {
							//return `${value}`;
						}
						return `${value} passes`;
					},

					getOptionLabel(value) {
						return `${value as number}`;
						//return `${value as number} passes`;
					},

					renderEditCell(params: GridRenderEditCellParams<AsphaltRowModel, number>) {
						const handleChange = async (value: number | null) => {
							await params.api.setEditCellValue({
								id: params.id,
								field: params.field,
								value,
							});
						};

						return (
							<CompactNumericInput
								style={{ margin: '2px' }}
								value={params.value ?? 0}
								onValueChange={handleChange}
							/>
						);
					},
				},
				{
					field: 'timeLimit',
					headerName: isXSmall ? 'Time' : 'Time Limit (mins)',
					headerAlign: 'center',
					align: 'center',
					type: 'singleSelect',
					flex: 0.33,
					editable: true,
					disableColumnMenu: true,
					sortable: false,
					resizable: false,
					valueOptions: ['', 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
					valueFormatter: (value?: number | null) => {
						if (value) {
							return `${value} mins`;
						} else {
							return 'No Limit';
						}
					},
					valueGetter: (value?: number | null) => {
						if (!value) {
							return '';
						}
						return value;
					},
					getOptionLabel: (value) => {
						if (value as number) {
							return `${value as number} mins`;
						}

						return 'No Limit';
					},
				},
				{
					field: 'tempCutoff',
					headerName: isXSmall ? 'Cutoff' : 'Temp. Cutoff (°C)',
					headerAlign: 'center',
					align: 'center',
					flex: 0.33,
					editable: true,
					resizable: false,
					type: 'singleSelect',
					valueOptions: ['', 40, 50, 60, 70, 80, 90, 100, 110, 120],
					valueFormatter: (value?: number | null) => {
						if (value) {
							return `${value}°C`;
						} else {
							return 'No Cutoff';
						}
					},
					valueGetter: (value?: number | null) => {
						if (!value) {
							return '';
						}
						return value;
					},

					getOptionLabel(value) {
						if (value as number) {
							return `${value as number}°C`;
						}
						return 'No Cutoff';
					},

					disableColumnMenu: true,
					sortable: false,
				},
			] as GridColDef[])
		);
	} else {
		columns.push(
			...([
				{
					field: 'targetCMV',
					headerName: isXSmall ? 'CMV' : 'Target CMV',
					type: 'number',
					flex: 1.0,
					align: 'center',
					headerAlign: 'center',
					editable: true,
					disableColumnMenu: true,
					sortable: false,
					resizable: false,
					valueFormatter(value: number) {
						return `${value}`;
					},

					renderEditCell(params: GridRenderEditCellParams<BaseRowModel, number>) {
						const handleChange = async (value: number | null) => {
							await params.api.setEditCellValue({
								id: params.id,
								field: params.field,
								value,
							});
						};

						return (
							<CompactNumericInput
								style={{ margin: '2px' }}
								value={params.value ?? 0}
								onValueChange={handleChange}
							/>
						);
					},
				},
				// {
				// 	field: 'targetCMVRange',
				// 	headerName: isXSmall ? 'CMV' : 'Target CMV',
				// 	type: 'custom',
				// 	flex: 1.0,
				// 	align: 'center',
				// 	headerAlign: 'center',
				// 	editable: true,
				// 	disableColumnMenu: true,
				// 	sortable: false,
				// 	resizable: false,
				// 	valueFormatter(value: CMVRange) {
				// 		return `${value.min} → ${value.max} (Δ${value.step})`;
				// 	},

				// 	renderEditCell(params: GridRenderEditCellParams<BaseRowModel, CMVRange>) {
				// 		const handleChange = async (value: CMVRange) => {
				// 			await params.api.setEditCellValue({
				// 				id: params.id,
				// 				field: params.field,
				// 				value,
				// 			});
				// 		};

				// 		return <CMVRangeSelector value={params.value!} onChange={handleChange} />;
				// 	},
				// },
				{
					field: 'targetEVib',
					headerName: isXSmall ? 'E-Vib' : 'Target E-Vib',
					type: 'number',
					flex: 1.0,
					align: 'center',
					headerAlign: 'center',
					editable: true,
					disableColumnMenu: true,
					sortable: false,
					resizable: false,
					valueFormatter(value: number | null) {
						if (!value) {
							return 'N/A';
						}

						return `${value} MPa`;
					},

					renderEditCell(params: GridRenderEditCellParams<BaseRowModel, number>) {
						const handleChange = async (value: number | null) => {
							await params.api.setEditCellValue({
								id: params.id,
								field: params.field,
								value,
							});
						};

						return (
							<CompactNumericInput
								style={{ margin: '2px' }}
								value={params.value ?? 0}
								onValueChange={handleChange}
							/>
						);
					},
				},
			] as GridColDef[])
		);
	}

	// only show the actions column if we're not in readonly mode
	if (!readonly) {
		columns.push({
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			getActions: getRowActions,
		});
	}

	return columns;
}
