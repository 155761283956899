import { isDataItem, type FileOrDataItem } from '../features/dataPicker/dataPickerSlice';
import ConplantCSV from './ConplantCSV';
import DynapacCSV from './DynapacCSV';
import DynapacJSON from './DynapacJSON';
import type { RollerData } from './rollerTypes';

export default async function readRollerData(filesOrKeys: FileOrDataItem[]): Promise<RollerData> {
	if (filesOrKeys.length === 0) {
		throw new Error('No files to read');
	}

	const files = filesOrKeys.filter((fileOrKey) => fileOrKey instanceof File) as File[];
	const keys = filesOrKeys.filter(isDataItem).map((item) => item.key);

	if (files.length > 0) {
		const isConplant = await isConplantCSV(files[0]);
		if (!isConplant) {
			return DynapacCSV.read(files);
		} else {
			return ConplantCSV.read(files);
		}
	}

	return await DynapacJSON.read(keys);
}

async function isConplantCSV(file: File): Promise<boolean> {
	const header = await file.slice(0, 119).text();
	const parts = header.split(';');

	return parts.length > 10;
}