import { setOrRemove, type PersistHandlers } from '@tools/persistHelpers';
import { coreApi } from './coreApiSlice';
import type {
	DynapacError,
	DynapacProjectDetails,
	DynapacJobListRequest,
	DynapacLoginDetails,
	DynapacTokenResponse,
	DynapacReportStatusResponse,
	DynapacReportStatusRequest,
	DynapacCreateReportResponse,
	DynapacCreateReportRequest
} from '@shared/dynapacTypes';

export const dynapacApi = coreApi.injectEndpoints({
	endpoints: (builder) => ({
		getDynapacAccessToken: builder.query<DynapacTokenResponse, DynapacLoginDetails>({
			query: (details) => ({
				url: 'dynapac/login?' + new URLSearchParams(details).toString(),
				cache: 'default',
				method: 'GET'
			}),

			transformErrorResponse: (response): DynapacError => {
				if (response.data as { Unauthorized: string }) {
					return { detail: 'Invalid email or password' };
				}

				return { detail: 'Unknown Error' };
			},
		}),

		getDynapacJobsList: builder.query<DynapacProjectDetails[], DynapacJobListRequest>({
			query: (req) => ({
				url: 'dynapac/jobs',
				method: 'POST',
				body: {
					email: req.email,
				},
			}),
			keepUnusedDataFor: 15 * 60, // 15 minutes
			transformErrorResponse: (response): DynapacError => {
				if (response.status === 400) {
					return {
						detail: response.data as unknown as string
					};
				}

				const invalidEmailError = response.data as { status: string, response: string };
				if (invalidEmailError.status && invalidEmailError.response) {
					return { detail: `${invalidEmailError.status}: ${invalidEmailError.response}` };
				}

				const typeError = response.data as { status: string, message: string };
				if (typeError.status && typeError.message) {
					return { detail: `${typeError.status}: ${typeError.message}` };
				}

				const tokenError = response.data as { detail: string };
				if (tokenError.detail) {
					return { detail: tokenError.detail };
				}

				return { detail: 'Unknown Error' };
			}
		}),

		startDynapacCreateReport: builder.query<DynapacCreateReportResponse, DynapacCreateReportRequest>({
			query: (req: DynapacCreateReportRequest) => {
				const params = new URLSearchParams({
					layerId: req.layerId,
					layerName: req.layerName,
					isBaseData: req.isBaseData.toString()
				});
				if (req.fields) {
					params.append('fields', req.fields);
				}

				return {
					url: 'dynapac/report-create?' + params.toString(),
					method: 'GET',
				};
			},
		}),

		getDynapacReportStatus: builder.query<DynapacReportStatusResponse, DynapacReportStatusRequest>({
			query: (req: DynapacReportStatusRequest) => {
				const params = new URLSearchParams({
					id: req.id,
					retries: `${req.retries ?? 0}`,
				});

				return {
					url: 'dynapac/report-status?' + params.toString(),
					method: 'GET',
				};
			},
		}),
	})
});

export const {
	useGetDynapacAccessTokenQuery,
	useGetDynapacJobsListQuery,

	/* start creating report */
	useStartDynapacCreateReportQuery,
	useLazyStartDynapacCreateReportQuery,

	/* check report status */
	useGetDynapacReportStatusQuery,
	useLazyGetDynapacReportStatusQuery,

	usePrefetch: useDynapacPrefetch
} = dynapacApi;