import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { useState } from 'react';

/**
 * download the data stream from a fetch request with progress callback
 * @param progress
 * @returns
 */
async function fetchData(progress: (progress: number) => void) {
	const response = await fetch('/api/test/download/stream', {
		method: 'GET',
	});

	const reader = response.body?.getReader();

	if (!reader) {
		return;
	}

	let receivedLength = 0;
	const chunks: Uint8Array[] = [];
	const totalLength = response.headers.get('Content-Length');
	if (!totalLength) {
		console.error('Content-Length header is missing');
		return;
	}

	const total = totalLength ? parseInt(totalLength, 10) : 0;

	let cont = true;
	while (cont) {
		const { done, value } = await reader.read();

		if (done) {
			cont = false;
			break;
		}

		if (value) {
			chunks.push(value);
			receivedLength += value.length;
			progress((receivedLength / total) * 100);
		}
	}
}

const AccountView = () => {
	const [progress, setProgress] = useState(0);

	const handleClick = () => {
		fetchData(setProgress);
	};

	return (
		<Box
			marginLeft='auto'
			marginRight='auto'
			paddingTop={2}
			alignSelf='center'
			maxWidth={400}>
			<Typography variant='h5' textAlign='center'>
				User Account
			</Typography>

			<Typography marginTop={2} variant='body1' textAlign='center'>
				This is where the user account details will be displayed.
			</Typography>

			<Button
				fullWidth
				variant='contained'
				color='primary'
				onClick={handleClick}
				sx={{ mt: 2 }}>
				DOWNLOAD TEST
			</Button>

			<LinearProgress variant='determinate' value={Math.max(0, progress)} sx={{ mt: 2, height: '8px' }} />
		</Box>
	);
};

export default AccountView;
