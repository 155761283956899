

export const isDebug = import.meta.env.MODE !== 'production';

export function isTouchDevice(): boolean {
	return !!('ontouchstart' in window // works on most browsers
    || navigator.maxTouchPoints); // works on IE10/11 and Surface
}

export function isScrollbarVisible(): boolean {
	return !!window?.visualViewport && window.visualViewport.width < window.innerWidth;
}

export function debug(msg: string): void {
	if (isDebug) {
		console.log(msg);
	}
}

export function print<T>(obj: T): void {
	debug(JSON.stringify(obj));
}

export function isIE(): boolean {
	const ua = window.navigator.userAgent;
	const isIE11 = ua.indexOf('Trident/') > 0;
	const isIE = ua.indexOf('MSIE ') > 0;
	return isIE11 || isIE;

	// return !!window['MSInputMethodContext' as keyof Window]
	// && !!window.document['documentMode' as keyof Document];
}

export function isIE11(): boolean {
	return window.navigator.userAgent.indexOf('Trident/') > 0;
}

export function isEdge(): boolean {
	return window.navigator.userAgent.indexOf('Edge/') > 0;
}

export function isFirefox(): boolean {
	return window.navigator.userAgent.indexOf('Firefox') > 0;
}

export type SliceActions<T> = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[K in keyof T]: T[K] extends (...args: any[]) => infer A ? A : never;
}[keyof T];

export function time(func: () => void, header?: string, flag?: 'noconsole' | 'warmup'): void {
	if (flag === 'warmup') {
		for (let i = 0; i < 10; i++) {
			func();
		}
	}

	const start = window.performance.now();
	func();
	const end = window.performance.now();

	if (flag !== 'noconsole') {
		header ??= 'Execution Time';
		console.log(`${header}: ${(end - start).toFixed(1)}ms`);
	}
}

export class Mutex {
	private locked: boolean = false;

	lock(): Promise<void> {
		return new Promise((resolve) => {
			if (this.locked) {
				setTimeout(() => this.lock().then(resolve), 10);
			} else {
				this.locked = true;
				resolve();
			}
		});
	}

	unlock(): void {
		this.locked = false;
	}
}