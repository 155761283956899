import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Cache } from '@data/Cache';
import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Box, CircularProgress, Fab, Stack, TextField, Tooltip } from '@mui/material';
import type { DynapacLayerDetails, DynapacObjectDetails, DynapacProjectDetails } from '@shared/dynapacTypes';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { memo, useCallback } from 'react';
import { addRollerFile, selectRollerKeys, type DynapacSelectionState } from '../../dataPickerSlice';
import ActiveLayersView from './ActiveLayers/ActiveLayersView';

function isLayersEmpty(object: DynapacObjectDetails | null | undefined): boolean {
	if (!object) return true;

	return object.Layers?.filter((l) => l.Layer_ID && l.Layer_Name).length === 0;
}

export interface ProjectsViewProps {
	isLoading: boolean;

	data?: DynapacProjectDetails[];
	project?: DynapacProjectDetails | null;
	object?: DynapacObjectDetails | null;
	layer?: DynapacLayerDetails | null;

	updateSelection: (selection: Partial<DynapacSelectionState>) => void;
}

const ProjectsView = (props: ProjectsViewProps) => {
	const {
		isLoading,

		data,
		project,
		object,
		layer,

		updateSelection,
	} = props;

	const dispatch = useAppDispatch();
	const items = useAppSelector(selectRollerKeys);
	const isItemInActiveView = (key?: string) => !!items.find((item) => item.key === key);

	const handleAddLayer = useCallback(async () => {
		if (!layer) return;
		if (items.find((item) => item.key === layer.Layer_ID)) return;
		const exists = await Cache.exists('rollers', layer.Layer_ID);

		dispatch(
			addRollerFile({
				name: layer.Layer_Name,
				key: layer.Layer_ID,
				isAvailable: exists,
			})
		);
	}, [dispatch, items, layer]);

	return (
		<Stack direction='column' spacing={1}>
			<Autocomplete
				loading={isLoading}
				options={data?.map((job) => job) ?? []}
				getOptionLabel={(option) => option.Project_Name}
				getOptionKey={(option) => option.Project_ID}
				isOptionEqualToValue={(option, value) => option.Project_ID === value?.Project_ID}
				fullWidth
				value={project}
				onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: DynapacProjectDetails | null) =>
					updateSelection({ project: newValue, object: null, layer: null })
				}
				renderOption={({ key, ...props }, option) => (
					<Box
						component='li'
						sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
						key={key}
						{...props}>
						{option.Project_Name} ({option.Objects?.length || 0} objects)
					</Box>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						label='Jobs'
						variant='outlined'
						slotProps={{
							input: {
								...params.InputProps,
								endAdornment: (
									<>
										{isLoading && <CircularProgress color='inherit' size={20} />}
										{params.InputProps.endAdornment}
									</>
								),
							},
						}}
					/>
				)}
			/>
			<Autocomplete
				loading={isLoading}
				options={project?.Objects ?? []}
				getOptionKey={(option) => option.Object_ID}
				getOptionLabel={(option) => option?.Object_Name ?? ''}
				isOptionEqualToValue={(option, value) => option.Object_ID === value?.Object_ID}
				fullWidth
				value={object}
				onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: DynapacObjectDetails | null) => {
					updateSelection({ object: newValue, layer: null });
				}}
				renderOption={({ key, ...props }, option) => {
					return (
						<Box
							component='li'
							sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
							key={key}
							{...props}>
							{option.Object_Name} ({option.Layers?.filter((l) => l.Layer_ID && l.Layer_Name).length || 0}{' '}
							layers)
						</Box>
					);
				}}
				renderInput={(params) => <TextField {...params} label='Objects' variant='outlined' />}
			/>

			<Autocomplete
				loading={isLoading}
				options={isLayersEmpty(object) ? [] : object!.Layers}
				contentEditable={false}
				getOptionKey={(option) => option.Layer_ID}
				getOptionLabel={(option) => option.Layer_Name ?? 'N/A'}
				getOptionDisabled={(option) => isItemInActiveView(option.Layer_ID)}
				autoFocus={false}
				isOptionEqualToValue={(option, value) => option.Layer_ID === value?.Layer_ID}
				fullWidth
				value={layer}
				onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: DynapacLayerDetails | null) => {
					updateSelection({ layer: newValue });
				}}
				renderInput={(params) => <TextField {...params} label='Layers' variant='outlined' />}
				renderOption={({ key, ...props }, option, { inputValue }) => {
					const matches = match(option.Layer_Name, inputValue, { insideWords: true });
					const parts = parse(option.Layer_Name, matches);

					return (
						<li key={key} {...props}>
							<div>
								{parts.map((part, index) => (
									<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
										{part.text}
									</span>
								))}
							</div>
						</li>
					);
				}}
			/>

			<Stack direction='row' spacing={1}>
				<ActiveLayersView items={items} />

				<Tooltip enterDelay={1000} title='Add/Download IC Layer'>
					<Fab
						color='primary'
						sx={{ width: 48, minWidth: 48, height: 48, alignSelf: 'start' }}
						disabled={isItemInActiveView(layer?.Layer_ID)}
						onClick={handleAddLayer}>
						<AddIcon />
					</Fab>
				</Tooltip>
			</Stack>
			{/* <ActiveLayersView items={selectedItems} /> */}
		</Stack>
	);
};

const MemoizedProjectsView = memo(ProjectsView);
export default MemoizedProjectsView;
