import { createTheme, type ThemeOptions } from '@mui/material/styles';
import { red, blue, green } from '@mui/material/colors';
import type { LinkProps } from '@mui/material/Link';
import type {} from '@mui/x-data-grid/themeAugmentation';
import LinkBehavior from '@components/LinkBehavior';

declare module '@mui/material/styles' {
	interface Theme {
		status: {
			success: string;
			danger: string;
			selection: string;
		};
	}

	// allow configuration using `createTheme`
	interface ThemeOptions {
		status?: {
			success?: string;
			danger?: string;
			selection?: string;
		};
	}
}

// A custom theme for this app
const theme = createTheme({
	cssVariables: true,
	palette: {
		primary: {
			main: '#556cd6',
		},
		secondary: {
			main: '#19857b',
		},
		error: {
			main: red.A400,
		},
	},
	status: {
		danger: red[700],
		success: green[500],
		selection: blue[700], //'#f50057', // accent-type color
	},
	components: {
		MuiDataGrid: {
			styleOverrides: {
				root: {
					fontFamily: 'montserrat, roboto, sans-serif',
				},
			},
		},

		MuiLink: {
			defaultProps: {
				component: LinkBehavior,
			} as LinkProps,
		},

		/**
		 * customize button base to remove focus outline
		 * and set props for LinkComponent for react-router
		 */
		MuiButtonBase: {
			defaultProps: {
				LinkComponent: LinkBehavior,
			},
			styleOverrides: {
				root: {
					'&:focus': {
						outline: 'none',
					},
					fontFamily: 'montserrat, roboto, sans-serif',
				},
			},
		},

		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: 'montserrat, roboto, sans-serif',
				},
			},
		},

		MuiListItemButton: {
			defaultProps: {
				LinkComponent: LinkBehavior,
			},
			styleOverrides: {
				root: ({ theme }) => ({
					'&.Mui-selected': {
						backgroundColor: `${theme.status.selection}24`,
						'&:hover': {
							backgroundColor: `${theme.status.selection}2F`,
						},
					},
					' > .MuiTouchRipple-root': {
						color: theme.status.selection,
					},
				}),
			},
		},
		MuiTouchRipple: {
			styleOverrides: {},
		},
		MuiTypography: {
			defaultProps: {
				fontFamily: 'Montserrat, sans-serif',
				fontWeight: 500,
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: ({ theme }) => ({
					fontFamily: 'montserrat, roboto, sans-serif',
					'&.Mui-error': {
						color: theme.palette.error.dark,
					},
				}),
			},
		},

		MuiInputBase: {
			styleOverrides: {
				root: {
					fontFamily: 'montserrat, roboto, sans-serif',
				},
			},
		},

		MuiInputAdornment: {
			styleOverrides: {
				root: ({ theme }) => ({
					'&.Mui-error p': {
						color: theme.palette.error.dark,
					},
				}),
			},
		},

		MuiListItemText: {
			styleOverrides: {
				root: {
					color: 'rgba(0,0,0,0.54)',
				},
			},
		},

		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontFamily: 'montserrat, roboto, sans-serif',
				},
			},
		},

		MuiTab: {
			styleOverrides: {
				root: {
					fontFamily: 'montserrat, roboto, sans-serif',
					fontWeight: 600,
				},
			},
		},
	},
});

export default theme;
