import type { AsphaltCompactionSpecification, BaseCompactionSpecification, FailureReason, ReportType } from '@analysis/RollerAnalysis';
import { } from '@react-pdf/renderer';
import { type GridCoordinate, type Point, type Rect } from '@shared/geometry/core/Coordinate';
import type {
	fill,
	fillColor,

	lineWidth,
	opacity,
	path,
	polygon,
	restore,
	save,
	scale,
	rotate,
	stroke,
	strokeColor,

	font,
	fontSize,
	text,
	translate,

	moveTo,
	lineTo
} from 'pdfkit';

import type { BoundaryCollection, RollerCell, RollerData } from '../../../data/rollerTypes';
import { createChainageMarker, failureColors, getBaseMapCellColor, getCellColor, inflateRect, isWithinRect, type ChainageMarker } from '../map/helpers';
import type { Alignment, AlignmentVertex } from '@shared/alignmentTypes';
import { Vector2 } from '@shared/geometry/core/Vectors';

declare global {
	interface PDFDocument {
		drawCell(coords: GridCoordinate, offset: Point, size: number): void;
	}
}

export type RuleValue = 'evenodd' | 'nonzero' | 'even-odd' | 'non-zero';

export interface PDFKitWrapper {
	save: typeof save;
	restore: typeof restore;
	path: typeof path;
	fill: typeof fill;
	font: typeof font;
	fontSize: typeof fontSize;
	text: typeof text;
	rect: (x: number, y: number, width: number, height: number) => this;
	scale: typeof scale;
	rotate: typeof rotate;
	translate: typeof translate;
	polygon: typeof polygon;
	fillColor: typeof fillColor;
	strokeColor: typeof strokeColor;
	stroke: typeof stroke;
	lineWidth: typeof lineWidth;
	opacity: typeof opacity;

	moveTo: typeof moveTo;
	lineTo: typeof lineTo;
}

export function drawCell(painter: PDFKitWrapper, cell: RollerCell, offset: Point, size: number) {
	painter
		.rect(
			cell.coords.x - offset.x - size / 2,
			cell.coords.y - offset.y - size / 2,
			size,
			size
		)
		.fill();
}

export function drawCells(painter: PDFKitWrapper,
	data: RollerData,
	spec: AsphaltCompactionSpecification | BaseCompactionSpecification | null,
	type: ReportType,
	offset: Point,
	cellSize: number) {

	if (spec) {
		// draw spec
		const cells = data.groupedCells.get(spec);
		if (!cells) {
			console.log('No cells found for spec: ', spec);
			return;
		}

		painter.opacity(0.33);
		painter.fillColor('limegreen');
		for (const passed of cells.passed.outside) {
			drawCell(painter, passed, offset, cellSize);
		}
		for (const reason in cells.failed.outside) {
			const result = reason as FailureReason;
			painter.fillColor(failureColors[result]);
			for (const failed of cells.failed.outside[result]) {
				drawCell(painter, failed, offset, cellSize);
			}
		}

		painter.opacity(1.0);
		painter.fillColor('limegreen');
		for (const passed of cells.passed.inside) {
			drawCell(painter, passed, offset, cellSize);
		}

		for (const reason in cells.failed.inside) {
			const result = reason as FailureReason;
			painter.fillColor(failureColors[result]);
			for (const failed of cells.failed.inside[result]) {
				drawCell(painter, failed, offset, cellSize);
			}
		}
	} else {
		// draw pass count
		const inside = data.getCells().where((c) => c.isWithinBoundary);
		const outside = data.getCells().where((c) => !c.isWithinBoundary);

		//TODO: store the CMV type somewhere so we don't need to keep passing it around separately
		//  - maybe store it on the RollerData object in a 'config' property and have it so we set it once for the entire report
		const colorGetter: (cell: RollerCell) => string = type === 'ASPHALT'
			? getCellColor
			: (cell) => getBaseMapCellColor(cell, 'firstPass');

		painter.opacity(0.33);
		for (const cell of outside) {
			painter.fillColor(colorGetter(cell));
			drawCell(painter, cell, offset, cellSize);
		}
		painter.fill();

		painter.opacity(1.0);
		for (const cell of inside) {
			painter.fillColor(colorGetter(cell));
			drawCell(painter, cell, offset, cellSize);
		}
		painter.fill();
	}
}

export function drawBoundaries(painter: PDFKitWrapper, boundaries: BoundaryCollection, offset: Point) {
	for (const boundary of boundaries) {
		const poly = boundary.coords.map((coord) => ([
			coord.x - offset.x,
			coord.y - offset.y,
		]));

		painter.polygon(...poly).lineWidth(0.25).stroke();
	}
}

function getVisibleVertices(alignment: Alignment, bounds?: Rect): AlignmentVertex[] {
	const vertices = alignment.vertices;
	const vertexCount = vertices.length;

	if (vertexCount < 2) {
		return [];
	}

	if (bounds) {
		const clip = inflateRect(bounds, 50);
		return vertices.filter((v) => isWithinRect(v.coord, clip));
	}

	return vertices;
}

export function drawAlignment(painter: PDFKitWrapper, alignment: Alignment, offset: Point, chainageOffset: number): void {

	const visibleVertices = getVisibleVertices(alignment);
	const visibleVertexCount = visibleVertices.length;
	if (visibleVertexCount < 2) {
		return;
	}

	const markers = visibleVertices.map((v, i) => createChainageMarker(v, visibleVertices[(i + 1) % visibleVertexCount], i === 0));

	// painter.strokeColor('black').opacity(0.5).lineWidth(0.5);

	// painter.moveTo(visibleVertices[0].coord.x - offset.x, visibleVertices[0].coord.y - offset.y);
	// for (let i = 1; i < visibleVertexCount; ++i) {
	// 	painter.lineTo(visibleVertices[i].coord.x - offset.x, visibleVertices[i].coord.y - offset.y);
	// }
	// painter.stroke();

	painter.strokeColor('black').opacity(1).lineWidth(0.5);
	for (const marker of markers.filter((m) => Math.floor(m.chainage * 1000) % 100 === 0)) {
		painter.moveTo(marker.start.x - offset.x, marker.start.y - offset.y);
		painter.lineTo(marker.end.x - offset.x, marker.end.y - offset.y);
		painter.stroke();

		drawChainageText(painter, offset, marker, chainageOffset);
	}
}

function drawChainageText(painter: PDFKitWrapper, offset: Point, marker: ChainageMarker, chainageOffset: number) {
	painter.save();

	const fontSize = 8;
	const chText = (marker.chainage * 1000).toFixed(0);
	const text = `CH ${chText}`;

	// TODO: currently there is no way to access the pdfkit 'widthOfString' function from the ReactPDF canvas painter.
	const fontWidth = 18 + 3.25 * chText.length + 4;
	const fontHeight = fontSize;
	const fontHeightOffset = fontHeight / 2;

	const v = Vector2.directional(marker.start, marker.end, true);
	const angle = v.calculateAngle().toDegrees();

	const textStart = v.multiplyScalar(chainageOffset).add(marker.center);

	scaleAndRotateAt(painter, 1, -1, angle, textStart.x - offset.x, textStart.y - offset.y);
	painter.rect(textStart.x - offset.x - 5, textStart.y - offset.y - fontHeightOffset - 3, fontWidth + 10, fontHeight + 4);
	painter.fillColor('white');
	painter.fill();


	painter.font('Courier').fontSize(fontSize).fillColor('black');
	painter.text(text, textStart.x - offset.x, textStart.y - offset.y, {
		fill: true,
		lineBreak: false,
		baseline: 'middle',
		align: 'left',
	});

	painter.restore();
}

function scaleAndRotateAt(ctx: PDFKitWrapper, scaleX: number, scaleY: number, angle: number, px: number, py: number) {
	ctx.translate(px, py);
	ctx.scale(scaleX, scaleY);
	ctx.rotate(angle);
	ctx.translate(-px, -py);
}
