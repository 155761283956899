import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import type { DynapacError, DynapacLoginDetails, DynapacProjectDetails } from '@shared/dynapacTypes';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef } from 'react';
import '../../api/coreApiSlice';
import { useGetDynapacAccessTokenQuery, useGetDynapacJobsListQuery } from '../../api/dynapacApiSlice';
import {
	selectDynapacAuthState,
	selectDynapacSelection,
	setDynapacAuth,
	updateDynapacSelection,
	type DynapacSelectionState,
} from '../dataPickerSlice';
import ProjectsView from './downloader/ProjectsView';
import { getLayer, getObject, getProject } from './helpers';
import { loginDetails } from '../shared/dynapacLoginDetails';

interface DynapacDataPickerProps {
	//selectedItems: DataItem[];

	forceRefresh?: boolean;
	onRefreshStatusChanged?: (isRefreshing: boolean) => void;
}

const DynapacDataPicker = (props: DynapacDataPickerProps) => {
	const { forceRefresh, onRefreshStatusChanged } = props;

	const refreshing = useRef(false);
	const dispatch = useAppDispatch();
	const authState = useAppSelector(selectDynapacAuthState);

	const login = useGetDynapacAccessTokenQuery(loginDetails, {
		skip: authState.isAuth && !authState.getIsExpired(),
		pollingInterval: 45 * 60 * 1000,
	});

	const jobsList = useGetDynapacJobsListQuery(
		{
			email: loginDetails.email,
		},
		{
			skip: !authState.auth || authState.getIsExpired(),
			refetchOnMountOrArgChange: 15 * 60
		}
	);

	const { project, object, layer } = useAppSelector(selectDynapacSelection);

	const updateSelection = useCallback(
		(selection: Partial<DynapacSelectionState>) => {
			dispatch(updateDynapacSelection(selection));
		},
		[dispatch]
	);

	const overwriteSelection = useCallback((data: DynapacProjectDetails[] | null | undefined) => {
		const newProject = getProject(data, project);
		const newObject = getObject(data, newProject, object);
		const newLayer = getLayer(data, newProject, newObject, layer);

		if (newProject === project && newObject === object && newLayer === layer) {
			console.log('selection is the same, not updating');
			return;
		}

		console.log('overwriting selection:', { newProject, newObject, newLayer });

		updateSelection({ project: newProject, object: newObject, layer: newLayer });
	}, [layer, object, project, updateSelection]);

	// useEffect(() => {
	// 	if (
	// 		login.isSuccess &&
	// 		!login.isFetching &&
	// 		login.data &&
	// 		login.data.token.access_token !== authState.auth?.accessToken
	// 	) {
	// 		dispatch(
	// 			setDynapacAuth({
	// 				accessToken: login.data.token.access_token,
	// 				expiration: dayjs().add(login.data.token.expires_in_minutes, 'minutes'),
	// 			})
	// 		);
	// 	}
	// }, [authState.auth, dispatch, login.data, login.isFetching, login.isSuccess]);

	useEffect(() => {
		if (jobsList.isError) {
			console.error('jobsList error:', jobsList.error);
			const error = jobsList.error as DynapacError;
			if (error?.detail?.includes('Authentication credentials were not provided')) {
				dispatch(setDynapacAuth(undefined));
			}
		}
	}, [dispatch, jobsList.error, jobsList.isError]);

	useEffect(() => {
		if (refreshing.current) return;

		if (forceRefresh) {
			if (jobsList.isSuccess || jobsList.isError) {
				(async () => {
					console.log('refetching jobs...');
					refreshing.current = true;
					if (!authState.isAuth || authState.getIsExpired()) {
						console.log('refreshing access token...');
						await login.refetch();
					}

					await jobsList.refetch();
					refreshing.current = false;
				})();
			}
		}
	},
	[
		dispatch,
		forceRefresh,
		jobsList.isSuccess,
		login,
		authState,
		onRefreshStatusChanged,
		jobsList,
		overwriteSelection
	]);

	useEffect(() => {
		onRefreshStatusChanged?.(jobsList.isFetching);
	}, [onRefreshStatusChanged, jobsList.isFetching]);

	useEffect(() => {
		if (jobsList.isSuccess && !jobsList.isFetching) {
			overwriteSelection(jobsList.data);
		}
	}, [jobsList.data, jobsList.isFetching, jobsList.isSuccess, overwriteSelection]);

	const LoadingView = useCallback(() => {
		const content = login.isFetching
			? 'Logging in to Dynapac API...'
			: jobsList.isFetching
				? 'Querying jobs list...'
				: '';

		return (
			<>
				<Box sx={{ display: 'flex', justifyContent: 'center' }} padding={2}>
					<CircularProgress
						size={40}
						content='Loading...'
					/>
				</Box>

				<Typography
					textAlign='center'
					padding={2}>
					{content}
				</Typography>
			</>
		);
	}, [login, jobsList.isFetching]);

	return (
		<div>
			{/* we're loading, show loading ui */}
			{(login.isLoading || jobsList.isLoading) && LoadingView()}

			{/* everything is good, show combo boxes n shit */}
			{jobsList.isSuccess && (
				<Stack direction='column'>
					<ProjectsView
						isLoading={jobsList.isFetching}
						project={project}
						object={object}
						layer={layer}
						data={jobsList.data}
						updateSelection={updateSelection}
					/>
				</Stack>
			)}

			{/* error occurred, show error message */}
			{jobsList.isError && <div>Error: {`${JSON.stringify(jobsList.error)}`}</div>}
		</div>
	);
};

export default DynapacDataPicker;