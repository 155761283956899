import type { RouteProps } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import DataPicker from './features/dataPicker/DataPicker';
import ReportViewer from './features/viewer/ReportViewer';
import AccountView from './features/account/AccountView';
import TestPage from './features/tests/TestPage';

import withAuthProtection from './components/withAuthProtection';
import ArcGISCallback from './features/dataPicker/shared/arcgis/ArcGISCallback';

const ProtectedDataPicker = withAuthProtection(DataPicker, '/home');
const ProtectedViewer = withAuthProtection(ReportViewer, '/viewer');
const ProtectedAccountView = withAuthProtection(AccountView, '/account');

const ProtectedTestView = withAuthProtection(TestPage, '/tests');
const AppRoutes: RouteProps[] = [
	{
		// this route is used in the popup window that is opened by the ArcGIS OAuth flow
		path: '/arcgis-auth',
		element: <ArcGISCallback />,
	},
	{
		path: '/home',
		element: <ProtectedDataPicker />,
	},
	{
		path: '/viewer',
		element: <ProtectedViewer />,
	},
	{
		path: '/tests',
		element: <ProtectedTestView />,
	},
	{
		path: '/account',
		element: <ProtectedAccountView />,
	},
	// {
	//   path: '/uploader',
	//   element: <Uploader />
	// },
	{
		/* default route redirects to /home route */
		path: '*',
		element: <Navigate to='/home' replace />,
	},
];

export default AppRoutes;
