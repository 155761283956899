
export default class MathEx {
	static round(value: number, decimals: number) {
		const factor = Math.pow(10, decimals);
		return Math.round(value * factor) / factor;
	}

	static pow2(value: number) {
		return 1 << value;
	}

	static min(values: number[]) {
		let min = Number.MAX_VALUE;
		for (const value of values) {
			if (value < min) {
				min = value;
			}
		}
		return min;
	}
}