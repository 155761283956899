type LineCallback = (line: string) => void;
interface TextFileConfig {
	headerLines: number;
}

/**
 * Processes a large CSV file using a File object.
 * This function reads the file as a stream and processes it line by line.
 * @param file The CSV file to process.
 * @returns A Promise that resolves when the file has been completely processed.
 */
export async function processTextFile(file: File, config: TextFileConfig, onLine: LineCallback, onError: (error: unknown) => void): Promise<void> {
	const reader = file.stream().getReader();
	const decoder = new TextDecoder('utf-8');
	let leftover = '';
	let headerLines = config.headerLines;

	try {
		while (true) {
			const { done, value } = await reader.read();
			if (done) {
				// Process any remaining text in leftover
				if (leftover) {
					onLine(leftover);
				}
				break;
			}

			// Decode the chunk and combine with any leftover text from previous chunk
			const text = decoder.decode(value, { stream: true });
			const combinedText = leftover + text;
			// Split text into lines (handling both \n and \r\n)
			const lines = combinedText.split(/\r?\n/);
			// The last element might be an incomplete line; store it for the next chunk
			leftover = lines.pop() || '';

			// Process each complete line
			for (const line of lines) {
				if (headerLines-- > 0) {
					continue;
				}

				onLine(line);
			}
		}
	} catch (error) {
		console.error('Error reading file:', error);
		onError(error);
	}
}