import { selectCoverages, selectSetup } from '@analysis/analysisSlice';
import {
	isAsphaltSpec,
	isBaseSpec,
	type AsphaltCompactionSpecification,
	type BaseCompactionSpecification,
	type CompactionReportSetup,
	type CompactionSpecCoverageResult,
	type CompactionSpecification,
	type ReportType,
} from '@analysis/RollerAnalysis';
import { useAppSelector } from '@app/hooks';
import { Button, Input, Paper, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
	Canvas,
	Document,
	Image,
	PDFDownloadLink,
	PDFViewer,
	Page,
	StyleSheet,
	Text,
	View,
} from '@react-pdf/renderer';
import { Buffer } from 'buffer';
import type { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import { cmvColorRanges, passCountColors } from '../map/helpers';
import { selectAlignment, selectBackgroundImage, selectCoverageChartImage, type BackgroundImage } from '../viewerSlice';
import fhLogoUrl from './assets/fulton-hogan-icon.png';
import pavesetLogoUrl from './assets/paveset-icon.png';
import loadFonts from './fontHelper';
import { drawAlignment, drawBoundaries, drawCells, type PDFKitWrapper } from './pdfHelper';
import Table from './table/Table';
import TableCell from './table/TableCell';
import TableRow from './table/TableRow';
import type { Alignment } from '@shared/alignmentTypes';
import StyledNumberField from '@components/NumberField';

interface ReportMetaOptions {
	startChainage: number | null;
	endChainage: number | null;

	date: Dayjs | null;

	chainageOffset: number;
}

export interface ReportDocProps {
	coverageChartImg?: string;
	backgroundImage?: BackgroundImage;
	analysisSetup?: CompactionReportSetup;
	coverages?: CompactionSpecCoverageResult[];

	alignment?: Alignment;
	metaOptions: ReportMetaOptions;
}

loadFonts();

function getSpecTitle(spec: CompactionSpecification): string {
	if (isAsphaltSpec(spec)) {
		let title = `${spec.targetPasses} Passes`;
		if (spec.temperatureCutoff) {
			title += ` @ ${spec.temperatureCutoff}°C`;
		}
		if (spec.timeLimit) {
			title += ` in ${spec.timeLimit.asMinutes().toFixed(0)} mins`;
		}

		return title;
	} else if (isBaseSpec(spec)) {
		//return ` – ${spec.targetCMV} CMV`;
		return `≥ ${spec.targetCMV} CMV`;
	}

	return '';
}

const ReportDoc = (args: ReportDocProps) => {
	const { coverageChartImg, analysisSetup, backgroundImage, coverages, alignment, metaOptions } = args;
	const { rollerData, boundaries, overview, dates, analysisType } = analysisSetup ?? {
		analysisType: 'ASPHALT',
	};

	const boundaryArea = boundaries?.area ?? 1;
	const cellsArea = (rollerData?.cellsWithinBoundary.length ?? 0) * (rollerData?.cellArea ?? 0);

	const noCMVCells =
		rollerData?.cellsWithinBoundary.filter((cell) => cell.getCMV('firstPass') === null).length ?? 0;
	const testArea = analysisType === 'BASE' ? cellsArea - noCMVCells * (rollerData?.cellArea ?? 0) : cellsArea;

	const totalCoverage = (testArea / boundaryArea) * 100;
	const nonTestArea = boundaryArea - testArea;
	const nonTestCoverage = (nonTestArea / boundaryArea) * 100;

	const styles = StyleSheet.create({
		page: {
			flexDirection: 'column',
			backgroundColor: 'white',
		},
		section: {
			margin: 10,
			padding: 10,
			flexGrow: 1,
		},

		rollerPlot: {
			width: '100%',
			height: 400,
			position: 'relative',
			overflow: 'hidden',
		},

		canvasAdjust: {
			marginTop: -400,
		},

		legend: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			paddingTop: 8,
			paddingBottom: 8,
		},

		specLegendItem: {
			textAlign: 'center',
			marginHorizontal: 4,
			borderRadius: 4,
			fontSize: 12,
			paddingVertical: 4,
		},

		stack: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},

		todo: {
			fontSize: 12,
		},

		row: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
		},

		header: {
			fontFamily: 'Montserrat',
			fontWeight: 'medium',
			fontSize: 12,
			marginRight: 8,
			textAlign: 'right',
			width: 120,
		},

		value: {
			fontFamily: 'Montserrat',
			fontWeight: 'light',
			fontSize: 12,
			position: 'absolute',
			left: 128,
			width: '100%',
		},

		title: {
			fontFamily: 'Montserrat',
			fontWeight: 'medium',
			fontSize: 16,
			color: '#0d47a1',
			marginBottom: 8,
		},

		subtitle: {
			fontFamily: 'Montserrat',
			fontWeight: 'light',
			fontSize: 10,
			marginTop: -8,
			marginBottom: 8,
		},
	});

	const paintCanvas = (
		type: ReportType,
		spec: AsphaltCompactionSpecification | BaseCompactionSpecification | null,
		painter: PDFKitWrapper,
		availableWidth: number,
		availableHeight: number
	) => {
		if (!painter || !rollerData || !backgroundImage?.imageBuffer) {
			return null;
		}

		const realWidth = backgroundImage.pixelSize * backgroundImage.image.width;
		const realHeight = backgroundImage.pixelSize * backgroundImage.image.height;
		const scaleWidth = availableWidth / realWidth;
		const scaleHeight = availableHeight / realHeight;
		const scale = Math.min(scaleWidth, scaleHeight);

		painter.rect(0, 0, availableWidth, availableHeight).stroke('black');

		const offset = {
			x: backgroundImage.position.x,
			y: backgroundImage.position.y,
		};

		painter.scale(scale, -scale);
		drawCells(painter, rollerData, spec, type, offset, 0.38);
		if (boundaries) {
			painter.strokeColor('black');
			drawBoundaries(painter, boundaries, offset);
		}

		if (alignment) {
			drawAlignment(painter, alignment, offset, metaOptions.chainageOffset);
		}
		return null;
	};

	const buffer = backgroundImage?.imageBuffer ? Buffer.from(backgroundImage.imageBuffer) : undefined;

	const datesString = metaOptions.date?.format('DD/MM/YYYY') ?? dates?.[0].format('DD/MM/YYYY') ?? 'N/A';

	return (
		<Document title='IC Report'>
			<Page size='A4' style={styles.page}>
				<View style={styles.section}>
					<View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<View style={[styles.stack, { width: '100%' }]}>
							<Text style={styles.title}>Asphalt IC Report</Text>
							<View style={styles.row}>
								<Text style={styles.header}>Shift Date(s):</Text>
								<Text style={styles.value}>{datesString}</Text>
							</View>

							<View style={styles.row}>
								<Text style={styles.header}>Job ID:</Text>
								<Text style={styles.value}>{overview?.jobId?.toUpperCase() ?? 'N/A'}</Text>
							</View>

							<View style={styles.row}>
								<Text style={styles.header}>Pavement Layer:</Text>
								<Text style={styles.value}>{overview?.layer}</Text>
							</View>

							<View style={styles.row}>
								<Text style={styles.header}>Chainages:</Text>
								{metaOptions.startChainage !== null && metaOptions.endChainage !== null && (
									<Text style={styles.value}>
										{metaOptions.startChainage} to {metaOptions.endChainage}
									</Text>
								)}
							</View>

							{analysisType !== 'BASE' && (
								<>
									<View style={styles.row}>
										<Text style={styles.header}>Mix Type:</Text>
										<Text style={styles.value}>{overview?.mixType?.toUpperCase()}</Text>
									</View>

									<View style={styles.row}>
										<Text style={styles.header}>Thickness:</Text>
										<Text style={styles.value}>{overview?.thickness ?? ' - '}mm</Text>
									</View>
								</>
							)}
						</View>

						<View style={{ flexDirection: 'row' }}>
							<Image
								src={fhLogoUrl}
								style={{
									marginRight: 16,
									marginTop: -4,
									width: 72,
									objectFit: 'contain',
									objectPositionY: 0,
								}}
							/>
							<Image src={pavesetLogoUrl} style={{ width: 64, objectFit: 'contain', objectPositionY: 0 }} />
						</View>
					</View>
				</View>

				<View style={styles.section}>
					<Text style={styles.title}>Coverage & Stats</Text>
					<Table>
						<TableRow header style={{ borderBottom: '1px solid black' }}>
							<TableCell width='100%'>Report Item</TableCell>
							<TableCell width={150}>Value</TableCell>
							<TableCell width={150}>Coverage (Total)</TableCell>
							<TableCell width={150}>Coverage (Tested)</TableCell>
						</TableRow>

						<TableRow>
							<TableCell width='100%'>Total Area</TableCell>
							<TableCell width={150}>{boundaryArea.toFixed(1)}m²</TableCell>
							<TableCell width={150}>—</TableCell>
							<TableCell width={150}>—</TableCell>
						</TableRow>

						<TableRow>
							<TableCell width='100%'>Tested Area</TableCell>
							<TableCell width={150}>{testArea.toFixed(1)}m²</TableCell>
							<TableCell width={150}>{totalCoverage.toFixed(1)}%</TableCell>
							<TableCell width={150}>100%</TableCell>
						</TableRow>

						<TableRow>
							<TableCell width='100%'>Non-Tested Area</TableCell>
							<TableCell width={150}>{nonTestArea.toFixed(1)}m²</TableCell>
							<TableCell width={150}>{nonTestCoverage.toFixed(1)}%</TableCell>
							<TableCell width={150}>—</TableCell>
						</TableRow>

						{coverages &&
							coverages.map((result, index) => {
								return (
									<TableRow key={index}>
										<TableCell width='100%'>{getSpecTitle(result.spec)}</TableCell>
										<TableCell width={150}>{result.area.toFixed(1)}m²</TableCell>
										<TableCell width={150}>{result.coverage.toFixed(1)}%</TableCell>
										<TableCell width={150}>{((result.area / testArea) * 100).toFixed(1)}%</TableCell>
									</TableRow>
								);
							})}
					</Table>
				</View>

				{analysisType === 'ASPHALT' && (
					<View style={styles.section}>
						<View style={styles.row}>
							<Text style={styles.title}>Coverage Chart</Text>
						</View>
						<View style={styles.row}>
							<Text style={styles.subtitle}>
								Cumulative coverage data for each compaction specification defined for this report
							</Text>
						</View>
						<Image style={{ width: '100%' }} src={coverageChartImg}></Image>
					</View>
				)}

				{analysisType === 'BASE' && (
					<View style={styles.section}>
						<View style={styles.row}>
							<Text style={styles.title}>CMV Coverage Charts</Text>
						</View>
						<View style={styles.row}>
							<Text style={styles.subtitle}>
								Cumulative coverage line and the individual coverage for each data bin, which are 10 CMV wide
								(e.g. The bin at CMV 40 includes the cells 40 ≤ CMV {'<'} 50)
							</Text>
						</View>
						<Image style={{ width: '100%' }} src={coverageChartImg}></Image>
					</View>
				)}
			</Page>

			{/**
			 * canvas plot for ASPHALT report
			 */}
			{analysisType === 'ASPHALT' && (
				<Page size='A4' style={styles.page}>
					<View style={styles.section}>
						<Image style={[styles.rollerPlot, { objectFit: 'contain', objectPositionX: 0 }]} src={buffer} />

						<Canvas
							style={[styles.rollerPlot, styles.canvasAdjust]}
							paint={(p, w, h) => paintCanvas(analysisType, null, p as PDFKitWrapper, w, h)}
						/>

						<View style={styles.legend}>
							{passCountColors.map((color, index) => (
								<View key={index} style={[styles.stack, { width: '10%' }]}>
									<Text style={[styles.specLegendItem, { paddingVertical: 0 }]}>#{index + 1}</Text>
									<View style={[styles.specLegendItem, { backgroundColor: color }]}></View>
								</View>
							))}
						</View>
					</View>
				</Page>
			)}

			{/**
			 * canvas plot for BASE report
			 */}
			{analysisType === 'BASE' && (
				<Page size='A4' style={styles.page}>
					<View style={styles.section}>
						<View style={styles.row}>
							<Text style={styles.title}>Colour-Coded CMV Map</Text>
						</View>
						<View style={[styles.row]}>
							<Text style={[styles.subtitle]}>
								IC map where the colour of each cell represents the CMV. Black cells are cells without any CMV
								data.
							</Text>
						</View>

						<Image style={[styles.rollerPlot, { objectFit: 'contain', objectPositionX: 0 }]} src={buffer} />

						<Canvas
							style={[styles.rollerPlot, styles.canvasAdjust]}
							paint={(p, w, h) => paintCanvas(analysisType, null, p as PDFKitWrapper, w, h)}
						/>

						<View style={styles.legend}>
							{cmvColorRanges.map((range, index) => (
								<View key={index} style={[styles.stack, { width: '10%' }]}>
									<Text style={[styles.specLegendItem, { paddingVertical: 0, fontSize: 10 }]}>
										{range.min + ' – ' + range.max}
									</Text>
									<View style={[styles.specLegendItem, { backgroundColor: range.color }]}></View>
								</View>
							))}
						</View>
					</View>
				</Page>
			)}

			{rollerData?.compactionSpecs.map((spec, index) => {
				if (isAsphaltSpec(spec)) {
					const widthStyle = {
						width: spec.temperatureCutoff ? '25%' : '33%',
					};

					return (
						<Page key={index} size='A4' style={styles.page}>
							<View style={styles.section}>
								<Image style={styles.rollerPlot} src={buffer} />
								<Canvas
									style={[styles.rollerPlot, styles.canvasAdjust]}
									paint={(p, w, h) => paintCanvas(analysisType, spec, p as PDFKitWrapper, w, h)}
								/>
								<View style={styles.legend}>
									<View style={[styles.stack, widthStyle]}>
										<Text style={[styles.specLegendItem]}>Passed</Text>
										<View style={[styles.specLegendItem, { backgroundColor: 'limegreen' }]}></View>
									</View>

									<View style={[styles.stack, widthStyle]}>
										<Text style={[styles.specLegendItem]}>
											Over {spec.timeLimit?.asMinutes().toFixed(0)} mins
										</Text>
										<View style={[styles.specLegendItem, { backgroundColor: 'blue' }]}></View>
									</View>

									{spec.temperatureCutoff && (
										<View style={[styles.stack, widthStyle]}>
											<Text style={[styles.specLegendItem]}>Under {spec.temperatureCutoff}°C</Text>
											<View style={[styles.specLegendItem, { backgroundColor: 'orange' }]}></View>
										</View>
									)}

									<View style={[styles.stack, widthStyle]}>
										<Text style={[styles.specLegendItem]}>Less than {spec.targetPasses} Passes</Text>
										<View style={[styles.specLegendItem, { backgroundColor: 'red' }]}></View>
									</View>
								</View>
							</View>
						</Page>
					);
				} else {
					const widthStyle = {
						width: '33%',
					};

					return (
						<Page key={index} size='A4' style={styles.page}>
							<View style={styles.section}>
								<View style={styles.row}>
									<Text style={styles.title}>
										Base Spec Map {spec.specNumber}: {'  ≥ '}
										{spec.targetCMV} CMV
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.subtitle, { marginTop: -8, marginBottom: 8 }]}>
										IC map showing pass/fail against the current CMV cut-off of {spec.targetCMV} CMV.
									</Text>
								</View>

								<Image
									style={[styles.rollerPlot, { objectFit: 'contain', objectPositionX: 0 }]}
									src={buffer}
								/>
								<Canvas
									style={[styles.rollerPlot, styles.canvasAdjust]}
									paint={(p, w, h) => paintCanvas(analysisType, spec, p as PDFKitWrapper, w, h)}
								/>
								<View style={styles.legend}>
									<View style={[styles.stack, widthStyle]}>
										<Text style={[styles.specLegendItem]}>Passed</Text>
										<View style={[styles.specLegendItem, { backgroundColor: 'limegreen' }]}></View>
									</View>

									<View style={[styles.stack, widthStyle]}>
										<Text style={[styles.specLegendItem]}>Less than {spec.targetCMV} CMV</Text>
										<View style={[styles.specLegendItem, { backgroundColor: 'red' }]}></View>
									</View>

									<View style={[styles.stack, widthStyle]}>
										<Text style={[styles.specLegendItem]}>No CMV Data</Text>
										<View style={[styles.specLegendItem, { backgroundColor: 'black' }]}></View>
									</View>
								</View>
							</View>
						</Page>
					);
				}
			})}
		</Document>
	);
};

const ExportFileView = () => {
	const coverageChartImg = useAppSelector(selectCoverageChartImage);
	const setup = useAppSelector(selectSetup);
	const backgroundImage = useAppSelector(selectBackgroundImage);
	const coverages = useAppSelector(selectCoverages);
	const alignment = useAppSelector(selectAlignment);

	const [metaOpts, setMetaOpts] = useState<ReportMetaOptions>({
		startChainage: null,
		endChainage: null,
		date: setup?.dates[0] ?? null,

		chainageOffset: 25
	});

	if (!navigator.pdfViewerEnabled) {
		console.log('PDF Viewer is not enabled');
	}

	const reportDoc = useMemo(() => {
		return (
			<ReportDoc
				coverageChartImg={coverageChartImg}
				analysisSetup={setup}
				backgroundImage={backgroundImage}
				coverages={coverages}
				alignment={alignment}
				metaOptions={metaOpts}
			/>
		);
	}, [backgroundImage, coverageChartImg, setup, coverages, alignment, metaOpts]);

	if (!backgroundImage?.image || !setup || !setup?.rollerData || !setup?.specifications) {
		return (
			<Typography variant='body1' textAlign='center'>
				Report data is missing. Please ensure you have loaded a valid report.
			</Typography>
		);
	}

	return (
		<Stack direction='column' spacing={2} height='100%'>
			<Paper elevation={3} sx={{ p: 3, pt: 2 }}>
				<Grid2
					container
					spacing={2}
					alignItems='end'>
					<Grid2 size={12} sx={{ p: 0, m: 0, pl: 2 }}>
						<Typography variant='subtitle1'>Report Details</Typography>
					</Grid2>

					<Grid2 justifyItems='center' size={{ xs: 12, sm: 6, md: 3 }}>
						<Input
							placeholder='Start Chainage'
							type='number'
							sx={{ display: 'flex' }}
							inputProps={{
								sx: {
									MozAppearance: 'textfield',
									textAlign: 'center',
									'&::-webkit-inner-spin-button': {
										WebkitAppearance: 'none',
										margin: 0,
									},
								},
							}}
							onChange={(e) =>
								setMetaOpts((prev) => ({
									...prev,
									startChainage: +e.target.value,
								}))
							}
						/>
					</Grid2>
					<Grid2 justifyItems='center' size={{ xs: 12, sm: 6, md: 3 }}>
						<Input
							placeholder='End Chainage'
							type='number'
							sx={{ display: 'flex' }}
							inputProps={{
								sx: {
									MozAppearance: 'textfield',
									textAlign: 'center',
									'&::-webkit-inner-spin-button': {
										WebkitAppearance: 'none',
										margin: 0,
									},
								},
							}}
							onChange={(e) =>
								setMetaOpts((prev) => ({
									...prev,
									endChainage: +e.target.value,
								}))
							}
						/>
					</Grid2>

					<Grid2 justifyItems='center' size={{ xs: 12, sm: 6, md: 3 }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label='Shift Date'
								format='DD/MM/YYYY'
								value={metaOpts.date}
								onChange={(e) =>
									setMetaOpts((prev) => ({
										...prev,
										date: e,
									}))
								}
							/>
						</LocalizationProvider>
					</Grid2>

					<Grid2 justifyItems='center' size={{ xs: 12, sm: 6, md: 3 }}>
						{/* <NumericInput value={metaOpts.chainageOffset} onChange={(e) => setMetaOpts((prev) => ({ ...prev, chainageOffset: e ?? 0 }))} /> */}
						<StyledNumberField value={metaOpts.chainageOffset} onChange={(e) => setMetaOpts((prev) => ({ ...prev, chainageOffset: e ?? 0}))} />
					</Grid2>
				</Grid2>
			</Paper>

			{navigator.pdfViewerEnabled && <PDFViewer height='100%'>{reportDoc}</PDFViewer>}

			{/* TODO: generate proper name for file download */}
			{!navigator.pdfViewerEnabled && (
				<Button variant='contained' color='primary' disabled={!setup?.rollerData}>
					<PDFDownloadLink document={reportDoc} fileName='test.pdf' style={{ color: 'white' }}>
						{({ loading }) => (loading ? 'Loading document...' : 'Download now!')}
					</PDFDownloadLink>
				</Button>
			)}
		</Stack>
	);
};

export default ExportFileView;
