export type BaseTypeOfArray<T> = T extends (infer I)[] ? I : never;
export type ConstantOrFunction<F extends (...args: unknown[]) => unknown> = F | ReturnType<F>;
export type MaybeConstValue<T> = T extends (...args: unknown[]) => infer Return ? Return : T;
export type MaybePromise<T> = T | PromiseLike<T>;

type FaaCFunction = <T>(args: T) => React.ReactNode;
export function getChildrenOrFunction<T>(
	children: React.ReactNode | FaaCFunction,
	args: T
): React.ReactNode {
	if (typeof children === 'function') {
		return children(args);
	}
	return children;
}

//export type NotFunction<T> = Exclude<T, Function>;