import { coreApi } from './coreApiSlice';
import type {
	NearMapRequest,
	NearMapError,
	NearMapWMSRequest,
	NearMapWMSResponse
} from '@shared/nearMapTypes';


export const nearmapApi = coreApi.injectEndpoints({
	endpoints: (build) => ({
		getNearmapImage: build.query<ArrayBuffer, NearMapRequest>({
			query: (details) => ({
				url: 'nearmap/static',
				method: 'POST',
				body: details,
				responseHandler(response: Response): Promise<ArrayBuffer> {
					return response.arrayBuffer();
				},
			}),

			transformErrorResponse: (resp) => {
				console.log('error response:', resp);

				const error = resp.data as NearMapError;
				if (error) {
					console.log('error stuff..');
					return error;
				} else {
					return {
						status: resp.status,
						message: 'Unknown Error'
					};
				}
			}
		}),

		getNearmapImageWMS: build.query<NearMapWMSResponse, NearMapWMSRequest>({
			query: (details) => ({
				url: 'nearmap/wms',
				method: 'POST',
				body: details,
				async responseHandler(response: Response): Promise<NearMapWMSResponse> {
					const image = await response.arrayBuffer();

					const originX = response.headers.get('x-paveset-origin-x');
					const originY = response.headers.get('x-paveset-origin-y');

					const source = response.headers.get('x-paveset-image-source');
					console.log('Imagery Source:', source);
					if (source === 'nearmap') {
						console.log('Cache Status:', response.headers.get('x-paveset-image-cache-status'));
					}

					return {
						image,
						origin: { x: Number(originX), y: Number(originY) }
					};
				},
			}),

			transformErrorResponse: (resp) => {
				console.log('error response:', resp);

				const error = resp.data as NearMapError;
				if (error) {
					console.log('error stuff..');
					return error;
				} else {
					return {
						status: resp.status,
						message: 'Unknown Error'
					};
				}
			}
		}),
	}),

	overrideExisting: false,
});

export const { useGetNearmapImageQuery, useGetNearmapImageWMSQuery } = nearmapApi;