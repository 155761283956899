import { type Dayjs } from 'dayjs';
import { type Duration } from 'dayjs/plugin/duration';
import type { BoundaryCollection, RollerData } from '../../data/rollerTypes';
import type { OverviewSetup } from '../dataPicker/dataPickerSlice';
import { CMVBins, getCMVBinIndex } from '../viewer/map/helpers';

export interface Stats {
	totalArea: number;
}

export type FailureReason = 'time' | 'temperature' | 'passes' | 'cmv' | 'invalid';

export interface AsphaltCompactionSpecification {
	/**
   * The specification number. This is used to identify the spec in the report
   */
	specNumber: number;
	type: 'ASPHALT';

	/**
   * Target number of passes. This is required.
   */
	targetPasses: number;

	/**
   * Target time to complete the targetPasses.
   * This is optional and if not provided, no time cut-off is used.
   */
	timeLimit?: Duration;

	/**
   * The minimum temperature required for any of the passes up to targetPasses.
   * This is optional and if not provided, no temperature cut-off is used.
   */
	temperatureCutoff?: number;
}

export type CMVType = 'average' | 'firstPass' | 'lastPass' | 'minimum' | 'maximum';
export interface CMVRange {
	min: number;
	max: number;
	step: number;
}

export interface BaseCompactionSpecification {
	/**
   * The specification number. This is used to identify the spec in the report
   */
	specNumber: number;
	type: 'BASE';

	targetCMV: number;
	targetEVib: number;

	cmvType: CMVType;
	cmvRange: CMVRange;
}

export type CompactionSpecification = AsphaltCompactionSpecification | BaseCompactionSpecification;
export function isAsphaltSpec(spec: CompactionSpecification): spec is AsphaltCompactionSpecification {
	return spec.type === 'ASPHALT';
}

export function isBaseSpec(spec: CompactionSpecification): spec is BaseCompactionSpecification {
	return spec.type === 'BASE';
}

export interface RollingTemperatures {
	targetPass: number; // temp at target pass
	minimum: number; // minimum temp
	average: number; // average temp of all passes
}

/**
 * Stores the result of the specified {@link CompactionSpecification} for an individual cell
 * This is a base interface that is extended by the AsphaltCompactionSpecificationCellResult and BaseCompactionSpecificationCellResult interfaces
 */
export interface CoreCompactionSpecificationCellResult {
	spec: CompactionSpecification;

	passed: boolean;
	failureReason?: FailureReason;
}

/**
 * Stores the result of the specified {@link AsphaltCompactionSpecification} for an individual cell
 */
export interface AsphaltCompactionSpecificationCellResult extends CoreCompactionSpecificationCellResult {
	passCount: number;
	rollTime: Duration;
	rollTemperature: RollingTemperatures;
}

export interface CMVData {
	// average: number;
	// minimum: number;
	// maximum: number;

	// firstPass: number;
	// lastPass: number;

	// changes: number[];

	value: number | null;
	type: CMVType;
}

export interface BaseCompactionSpecificationCellResult extends CoreCompactionSpecificationCellResult {
	cmv: CMVData;
}

export type CompactionSpecificationCellResult = AsphaltCompactionSpecificationCellResult | BaseCompactionSpecificationCellResult;
export function isAsphaltSpecResult(result: CompactionSpecificationCellResult): result is AsphaltCompactionSpecificationCellResult {
	return (result as AsphaltCompactionSpecificationCellResult).spec !== undefined;
}

/**
 * Stores the coverage result of the specified {@link CompactionSpecification} for the entire data
 */
export interface CompactionSpecCoverageResult {
	spec: CompactionSpecification;

	area: number;
	coverage: number;
}

export type ReportType = 'BASE' | 'ASPHALT';
export interface CompactionReportSetup {
	// setup properties
	dates: Dayjs[];

	// data properties
	rollerData: RollerData;
	boundaries: BoundaryCollection;

	// compaction specs
	analysisType: ReportType;
	specifications: CompactionSpecification[];

	// overview info
	overview: OverviewSetup;
}

export interface CoverageDataPoint {
	/**
   * X-Axis value for the data point
   * ASPHALT: Time in seconds from the start of the compaction
   * BASE: CMV value
   */
	x: number;

	/**
   * Cumulative coverage after the elapsed time (in %)
   */
	coverageCumulative: number;

	/**
   * Coverage for this particular bin of data (in %)
   */
	coverage: number;

	/**
   * Cumulative coverage where the total-area is calculated from the rolled-area, not the boundary
   * For BASE CMV analysis only.
   */
	coverageCumulativeConstrained?: number;

	/**
   * The area of cells that have been compacted
   */
	area: number;
}

export interface AsphaltCoverageChartData {
	specNumber: number;
	data: CoverageDataPoint[];
}

export interface BaseCoverageChartData {
	chartType: 'CMV' | 'E-Vib'
	data: CoverageDataPoint[];
}

export interface CompactionAnalysis {
	setup: CompactionReportSetup;

	// calculated values
	chartsData: {
		['ASPHALT']: AsphaltCoverageChartData[];
		['BASE']: BaseCoverageChartData[];
	};

	coverages: CompactionSpecCoverageResult[];
}

function calculateSpecCoverages(setup: CompactionReportSetup): CompactionSpecCoverageResult[] {
	// ensure data has been processed against the boundaries & specs
	setup.rollerData.processBoundaries(setup.boundaries);
	setup.rollerData.processCompactionSpecs(setup.specifications);

	const totalArea = setup.boundaries.area;
	const coverages: CompactionSpecCoverageResult[] = [];

	for (const spec of setup.specifications) {
		let count = 0;
		for (const cell of setup.rollerData) {
			const cellResult = cell.getCompactionResults(spec);
			if (cellResult?.passed && cell.isWithinBoundary) {
				++count;
			}
		}

		console.log(`[Spec Coverage]: ${count} cells passed for spec ${spec.specNumber}`);
		const area = count * setup.rollerData.cellArea;
		const coverage = area / totalArea * 100;
		coverages.push({
			spec,
			area,
			coverage
		});
	}

	return coverages;
}

/**
 * Calculate the asphalt-analysis coverage data for each compaction spec for charting purposes
 * @param setup The setup for the compaction report
 * @returns The coverage data for each compaction spec
 */
function calculateAsphaltCoverageCharts(setup: CompactionReportSetup): AsphaltCoverageChartData[] {
	// ensure data has been processed against the boundaries & specs
	setup.rollerData.processBoundaries(setup.boundaries);
	setup.rollerData.processCompactionSpecs(setup.specifications);

	// create pass-time buckets
	// for each bucket the minTime is inclusive, maxTime is exclusive
	// bucket 00:00 -> step
	const step = 15;

	// buckets step -> (45:00 - step) in step x 2 increments
	// e.g. if step=30s, 00:30 -> 01:30, 01:30 -> 02:30... etc
	const count = 30 * 60 / (step * 2);

	// create the coverage data
	const coverageData: AsphaltCoverageChartData[] = [];

	// for each spec, calculate the coverage data
	for (const spec of setup.specifications.filter(isAsphaltSpec)) {
		console.log('Calculating asphalt coverage for spec:', spec.specNumber);

		// init the coverage data
		const target = spec.targetPasses;
		const cellArea = setup.rollerData.cellArea;

		const data: CoverageDataPoint[] = Array.from({ length: count })
			.map((_, i) => ({
				x: i * step * 2,
				coverage: 0,
				coverageCumulative: 0,
				area: 0
			}));

		// calculate the number of passing cells for each bucket
		const cells = setup.rollerData.getCells();
		let errorCount = 0;
		for (const cell of cells.where((c) => c.isWithinBoundary && c.passes.length >= target)) {
			const result = cell.getCompactionResults(spec) as AsphaltCompactionSpecificationCellResult;
			if (!result?.passed) {
				//console.log(result);
				continue;
			}

			const time = result.rollTime.asSeconds();
			const bucket = Math.round(time / (step * 2));

			if (bucket < 0) {
				// console.warn('Bucket is negative:', bucket);
				// console.warn('Cell:', cell);
				// console.warn('Time:', time);
				// throw new Error('Bucket is negative');
				++errorCount;
				continue;
			}

			data[Math.min(count - 1, bucket)].area += cellArea; // add the area of the cell
		}

		if (errorCount > 0) {
			console.error('Error count:', errorCount);
			throw new Error('Error count is greater than 0');
		}

		// calculate the cumulative coverage
		let runningRatio = 0.0;
		const totalArea = setup.boundaries.reduce((acc, b) => acc + b.area, 0);
		for (let i = 0; i < count; ++i) {
			const ratio = data[i].area / totalArea;
			runningRatio += ratio;

			data[i].coverage = ratio * 100;
			data[i].coverageCumulative = runningRatio * 100;
		}

		// add the data to the coverage data
		coverageData.push({ specNumber: spec.specNumber, data });
	}

	// return the coverage data
	return coverageData;
}

/**
 * Calculate the base-analysis coverage data for each compaction spec for charting purposes
 * @param setup The setup for the compaction report
 * @returns The coverage data for each compaction spec
 */
function calculateBaseCoverageCharts(setup: CompactionReportSetup): BaseCoverageChartData[] {
	// ensure data has been processed against the boundaries & specs
	setup.rollerData.processBoundaries(setup.boundaries);
	setup.rollerData.processCompactionSpecs(setup.specifications);

	// create CMV buckets
	// for each bucket the min is inclusive, max is exclusive
	// bucket 0 -> step
	const step = 10;

	// buckets step: 0 -> 100 in 'step' increments
	const count = 250 / step;

	// create the coverage data
	const coverageData: BaseCoverageChartData[] = [];

	coverageData.push(createCMVChartData(setup, step, count));

	// return the coverage data
	return coverageData;
}

function createCMVChartData(setup: CompactionReportSetup, step: number, count: number): BaseCoverageChartData {
	// init the coverage data
	const cellArea = setup.rollerData.cellArea;
	// create chart data buckets for each CMV range
	const data: CoverageDataPoint[] = CMVBins.map((val) => ({
		x: val,
		coverage: 0,
		coverageCumulative: 0,
		area: 0
	}));

	// calculate the number of passing cells for each bucket
	for (const cell of setup.rollerData.cellsWithinBoundary) {

		const cmv = cell.getCMV('firstPass');
		if (cmv === null) {
			continue;
		}

		const index = getCMVBinIndex(cmv);

		data[index].area += cellArea; // add the area of the cell
	}

	// calculate the coverage percentages
	let cumulativeRatioTotal = 0.0, cumulativeRatioTested = 0.0;
	const totalArea = setup.boundaries.area;
	const testedArea = data.reduce((acc, d) => acc + d.area, 0);
	for (let i = count - 1; i >= 0; --i) {
		const ratio = data[i].area / totalArea;
		cumulativeRatioTotal += ratio;
		data[i].coverageCumulative = cumulativeRatioTotal * 100;
		data[i].coverage = ratio * 100;

		const ratioTest = data[i].area / testedArea;
		cumulativeRatioTested += ratioTest;
		data[i].coverageCumulativeConstrained = cumulativeRatioTested * 100;
		//data[i].coverage = data[i].area / totalArea * 100;
	}

	// return the data to the coverage data
	return { chartType: 'CMV', data };
}

export function analyseReport(setup: CompactionReportSetup): CompactionAnalysis {
	// perform analysis
	console.log('Analysing report...');

	setup.rollerData.processBoundaries(setup.boundaries);
	setup.rollerData.processCompactionSpecs(setup.specifications);

	const isAsphaltAnalysis = setup.analysisType === 'ASPHALT';

	const coverages = calculateSpecCoverages(setup);

	return {
		setup,
		chartsData: {
			['ASPHALT']: isAsphaltAnalysis ? calculateAsphaltCoverageCharts(setup) : [],
			['BASE']: !isAsphaltAnalysis ? calculateBaseCoverageCharts(setup) : []
		},
		coverages
	};
}