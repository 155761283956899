import { Autocomplete, Button, CircularProgress, Grid2, TextField } from '@mui/material';
import { useGetAlignmentsSummaryQuery, useLazyGetAlignmentTMRQuery } from '../../api/alignmentApiSlice';
import { useState } from 'react';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectAlignment, setAlignment } from '../../viewer/viewerSlice';

const AlignmentPicker = () => {
	const dispatch = useAppDispatch();
	const alignment = useAppSelector(selectAlignment);
	const summaryQuery = useGetAlignmentsSummaryQuery({ jobId: 'TMR-METRO' });

	const [roadId, setRoadId] = useState<string | null>(alignment?.roadId ?? null);
	const [carriagewayCode, setCarriagewayCode] = useState<string | null>(alignment?.sectionId ?? null);

	const [alignmentTrigger, alignmentQuery] = useLazyGetAlignmentTMRQuery();

	const handleDownload = async () => {
		if (!roadId || !carriagewayCode) {
			return;
		}

		const result = await alignmentTrigger({ roadId, carriagewayCode }).unwrap();

		if (result) {
			//onAlignmentSelected(result);
			console.log(result);
			dispatch(setAlignment(result));
		}
	};

	return (
		<Grid2 container spacing={2}>
			<Grid2 size={{ xs: 12, sm: 6, md: 2 }}>
				<Autocomplete
					disablePortal
					options={summaryQuery.data?.summary || []}
					value={summaryQuery.data?.summary.find((s) => s.roadId === roadId) || null}
					getOptionLabel={(option) => option.roadId}
					noOptionsText='No roads found'
					fullWidth
					onChange={(_event, newValue) => setRoadId(newValue?.roadId || null)}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Road ID'
							slotProps={{
								input: {
									...params.InputProps,
									endAdornment: (
										<>
											{summaryQuery.isFetching ? <CircularProgress color='inherit' size={20} /> : null}
											{params.InputProps.endAdornment}
										</>
									),
								},
							}}
						/>
					)}
				/>
			</Grid2>
			<Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
				<Autocomplete
					disablePortal
					options={summaryQuery.data?.summary.find((s) => s.roadId === roadId)?.carriageways || []}
					getOptionLabel={(option) => `${option.code}: ${option.description}`}
					value={summaryQuery.data?.summary.find((s) => s.roadId === roadId)?.carriageways.find((c) => c.code === carriagewayCode) || null}
					noOptionsText='Select a road ID first'
					renderOption={({ key, ...element}, option) => (
						<li key={option.code} {...element}>
							<span style={{ fontWeight: 'bold', whiteSpace: 'pre' }}>{option.code}: </span>
							<span>{option.description}</span>
						</li>)}
					onChange={(_event, newValue) => setCarriagewayCode(newValue?.code || null)}
					fullWidth
					renderInput={(params) => <TextField {...params} label='Carriageway' />}
				/>
			</Grid2>

			<Grid2 size={{ xs: 12, sm: 6, md: 6 }}>
				<Button
					size='large'
					color='primary'
					variant='contained'
					sx={{ height: 1 }}
					startIcon={<CloudDownloadOutlinedIcon />}
					disabled={!roadId || !carriagewayCode}
					loading={alignmentQuery.isFetching}
					loadingPosition='center'
					loadingIndicator={<CircularProgress color='inherit' size={20} />}
					onClick={handleDownload}>
					Download
				</Button>
			</Grid2>
		</Grid2>
	);
};

export default AlignmentPicker;