import * as React from 'react';
import { NumberField } from '@base-ui-components/react/number-field';
import styles from './NumberField.module.css';
import { ClickAwayListener } from '@mui/material';

interface StyledNumberFieldProps {
	value: number;
	onChange: (value: number | null) => void;
}

export default function StyledNumberField(props: StyledNumberFieldProps) {
	const id = React.useId();
	const inputRef = React.useRef<HTMLInputElement>(null);

	const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			inputRef.current?.blur();
		}
	};

	return (
		<ClickAwayListener onClickAway={() => inputRef.current?.blur()}>
			<NumberField.Root
				id={id}
				defaultValue={0}
				value={props.value}
				onValueChange={props.onChange}
				className={styles.Field}>
				<NumberField.ScrubArea className={styles.ScrubArea}>
					<label htmlFor={id} className={styles.Label}>
						Chainage Offset
					</label>
					<NumberField.ScrubAreaCursor className={styles.ScrubAreaCursor}>
						<CursorGrowIcon />
					</NumberField.ScrubAreaCursor>
				</NumberField.ScrubArea>

				<NumberField.Group className={styles.Group}>
					<NumberField.Decrement className={styles.Decrement}>
						<MinusIcon />
					</NumberField.Decrement>
					<NumberField.Input ref={inputRef} className={styles.Input} onKeyUp={handleKeyUp} />
					<NumberField.Increment className={styles.Increment}>
						<PlusIcon />
					</NumberField.Increment>
				</NumberField.Group>
			</NumberField.Root>
		</ClickAwayListener>
	);
}

function CursorGrowIcon(props: React.ComponentProps<'svg'>) {
	return (
		<svg
			width='26'
			height='14'
			viewBox='0 0 24 14'
			fill='black'
			stroke='white'
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<path d='M19.5 5.5L6.49737 5.51844V2L1 6.9999L6.5 12L6.49737 8.5L19.5 8.5V12L25 6.9999L19.5 2V5.5Z' />
		</svg>
	);
}

function PlusIcon(props: React.ComponentProps<'svg'>) {
	return (
		<svg
			width='10'
			height='10'
			viewBox='0 0 10 10'
			fill='none'
			stroke='currentcolor'
			strokeWidth='1.6'
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<path d='M0 5H5M10 5H5M5 5V0M5 5V10' />
		</svg>
	);
}

function MinusIcon(props: React.ComponentProps<'svg'>) {
	return (
		<svg
			width='10'
			height='10'
			viewBox='0 0 10 10'
			fill='none'
			stroke='currentcolor'
			strokeWidth='1.6'
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<path d='M0 5H10' />
		</svg>
	);
}
