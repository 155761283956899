import type { DynapacProjectDetails, DynapacObjectDetails, DynapacLayerDetails } from '@shared/dynapacTypes';


export function getLayers(data?: DynapacProjectDetails[] | null,
	project?: DynapacProjectDetails | null,
	object?: DynapacObjectDetails | null): DynapacLayerDetails[] {
	if (!data || !project || !object) return [];
	const projectData = data.find((job) => job.Project_ID === project.Project_ID);
	const objectData = projectData?.Objects.find((obj) => obj.Object_ID === object.Object_ID);
	return objectData?.Layers ?? [];
}

export function hasProject(data: DynapacProjectDetails[] | null | undefined,
	project: DynapacProjectDetails): boolean {
	if (!data || !project) return false;
	return data.some((job) => job.Project_ID === project.Project_ID);
}

export function hasObject(data: DynapacProjectDetails[] | null | undefined,
	project: DynapacProjectDetails | null | undefined,
	object: DynapacObjectDetails): boolean {
	if (!data || !project || !object) return false;
	const projectData = data.find((job) => job.Project_ID === project.Project_ID);
	return projectData?.Objects.some((obj) => obj.Object_ID === object.Object_ID) ?? false;
}

export function hasLayer(data: DynapacProjectDetails[] | null | undefined,
	project: DynapacProjectDetails | null | undefined,
	object: DynapacObjectDetails | null | undefined,
	layer: DynapacLayerDetails): boolean {
	if (!data || !project || !object) return false;
	const projectData = data.find((job) => job.Project_ID === project.Project_ID);
	const objectData = projectData?.Objects.find((obj) => obj.Object_ID === object.Object_ID);
	return objectData?.Layers.some((l) => l.Layer_ID === layer.Layer_ID) ?? false;
}

export function getProject(data?: DynapacProjectDetails[] | null,
	project?: DynapacProjectDetails | null): DynapacProjectDetails | null {
	if (!data || !project) return null;
	return data.find((job) => job.Project_ID === project.Project_ID) ?? null;
}

export function getObject(data?: DynapacProjectDetails[] | null,
	project?: DynapacProjectDetails | null,
	object?: DynapacObjectDetails | null): DynapacObjectDetails | null {
	if (!data || !project || !object) return null;
	const projectData = data.find((job) => job.Project_ID === project.Project_ID);
	return projectData?.Objects.find((obj) => obj.Object_ID === object.Object_ID) ?? null;
}

export function getLayer(data?: DynapacProjectDetails[] | null,
	project?: DynapacProjectDetails | null,
	object?: DynapacObjectDetails | null,
	layer?: DynapacLayerDetails | null): DynapacLayerDetails | null {
	if (!data || !project || !object || !layer) return null;
	const projectData = data.find((job) => job.Project_ID === project.Project_ID);
	const objectData = projectData?.Objects.find((obj) => obj.Object_ID === object.Object_ID);
	return objectData?.Layers.find((l) => l.Layer_ID === layer.Layer_ID) ?? null;
}

