import type { SerializedError } from '@reduxjs/toolkit';
import {
	createApi,
	fetchBaseQuery,
	type FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { appendAuthorizationHeader, appendDynapacTokenHeader, type HeaderPreparer } from './helpers';

interface Error { error: FetchBaseQueryError | SerializedError }
interface Success<T> { data: T }

export function isSuccess<T>(response: Success<T> | Error): response is Success<T> {
	return 'data' in response;
}

export function isError<T>(response: Success<T> | Error): response is Error {
	return 'error' in response;
}

const combinePrepareHeaders: HeaderPreparer = async (headers, api) => {
	await appendAuthorizationHeader(headers, api);
	await appendDynapacTokenHeader(headers, api);
	return headers;
};

export const coreBaseQuery = fetchBaseQuery({
	baseUrl: '/api',
	prepareHeaders: combinePrepareHeaders,
});

// initialize an empty api service that we'll inject endpoints into later as needed
export const coreApi = createApi({
	reducerPath: 'coreApi',

	baseQuery: coreBaseQuery,
	endpoints: () => ({}),
});

export const ignoredApiActions = [
	'coreApi/executeQuery/fulfilled',
	'coreApi/executeQuery/rejected',
	'coreApi/executeMutation/fulfilled',
	'coreApi/executeMutation/rejected',
];

export const ignoredApiPaths = [
	'coreApi.queries',
	'coreApi.mutations'
];
