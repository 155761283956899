import { useAppDispatch, useAppSelector } from '@app/hooks';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Collapse, Tab } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo, useState } from 'react';
import * as ufp from 'use-file-picker';
import { FileSizeValidator, FileTypeValidator } from 'use-file-picker/validators';
import { selectBoundaryFiles, selectIsBoundarySetup, setBoundaryFiles } from '../dataPickerSlice';
import ArcDownloader from '../shared/arcgis/ArcDownloader';
import DataPickerSection from '../shared/DataPickerSection';
import { FilesTable } from '../shared/FilesTable';

const PickerButton = styled(Button)({
	borderBottomLeftRadius: '16px',
	borderTopLeftRadius: 0,
	borderBottomRightRadius: 0,
	borderTopRightRadius: '16px',
	width: 200,
	marginLeft: -8,
	marginBottom: -8,
});

const BoundaryDataPicker = () => {
	const dispatch = useAppDispatch();
	const boundaryFiles = useAppSelector(selectBoundaryFiles);
	const isBoundarySetup = useAppSelector(selectIsBoundarySetup);
	const [boundaryDataSource, setBoundaryDataSource] = useState('1');

	const boundaryPicker = ufp.useFilePicker({
		accept: ['.csv', '.txt', '.geojson'],
		readAs: 'Text',
		readFilesContent: undefined,
		validators: [
			new FileTypeValidator(['csv', 'txt', 'geojson']),
			new FileSizeValidator({
				minFileSize: 128, // 128 bytes minimum file size
			}),
		],
		onFilesSuccessfullySelected: (files) => {
			dispatch(setBoundaryFiles(files.plainFiles));
		},
		onFilesRejected: (files) => {
			console.log('rejected files:', files);
		},
		onClear: () => {
			dispatch(setBoundaryFiles([]));
		},
	});

	const boundaryFilesInfo = useMemo(() => {
		if (boundaryFiles.length === 0) return 'Select the file(s) that contain the roller boundary data';

		return boundaryFiles.map((file) => `${file.name} (${(file.size / 1024).toFixed(1)}KB)`).join('\n');
	}, [boundaryFiles]);

	const handlePickBoundaryFiles = useCallback(() => boundaryPicker.openFilePicker(), [boundaryPicker]);

	return (
		<DataPickerSection section={2} title='Boundary Data' isComplete={isBoundarySetup}>
			<Box sx={{ height: '100%' }}>
				<TabContext value={boundaryDataSource}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList
							onChange={(_event, newValue) => setBoundaryDataSource(newValue as string)}
							orientation='horizontal'
							variant='fullWidth'
							centered
							aria-label='boundary-data-source-tabs'>
							<Tab sx={{ fontFamily: 'montserrat' }} label='FILES(S)' value='1' />
							<Tab label='ARC GIS' value='2' />
						</TabList>
					</Box>

					<Collapse in={boundaryDataSource === '1'}>
						<TabPanel value='1' style={{ padding: '8px 8px 8px 0px' }}>
							<Box sx={{ width: '100%', height: '100%', p: 1 }}>
								{boundaryFiles.length === 0 && (
									<Typography
										p={{ xs: 1, sm: 2 }}
										sx={{
											fontStyle: 'italic',
											textAlign: 'center',
											whiteSpace: 'pre-wrap',
										}}>
										{boundaryFilesInfo}
									</Typography>
								)}
								{boundaryFiles.length > 0 && <FilesTable files={boundaryFiles} />}
							</Box>
						</TabPanel>
					</Collapse>

					<Collapse in={boundaryDataSource === '2'}>
						<TabPanel value='2' style={{ padding: 8 }}>
							{/* ArcGIS Boundary Data */}
							<ArcDownloader dataType='boundary' />
						</TabPanel>
					</Collapse>
				</TabContext>
			</Box>

			<PickerButton
				sx={{ display: boundaryDataSource === '1' ? 'flex' : 'none' }}
				startIcon={<AttachFileOutlinedIcon />}
				variant='contained'
				color='primary'
				size='large'
				onClick={handlePickBoundaryFiles}>
				Pick Files
			</PickerButton>
		</DataPickerSection>
	);
};

export default BoundaryDataPicker;
