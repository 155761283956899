import { type Theme, useMediaQuery, useTheme } from '@mui/material';

export const useIsMobile = () => {
	return useMediaQuery('@media(pointer: fine)');
};

export const useIsXSmall = () => {
	return useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
};

export const useIsSmall = () => {
	return useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
};

interface MinHeight {
	minHeight: number,
}

export function useAppBarHeight(): number {
	const {
		mixins: { toolbar },
		breakpoints,
	} = useTheme();

	const toolbarDesktopQuery = breakpoints.up('sm');
	const toolbarLandscapeQuery = `${breakpoints.up('xs')} and (orientation: landscape)`;
	const isDesktop = useMediaQuery(toolbarDesktopQuery);
	const isLandscape = useMediaQuery(toolbarLandscapeQuery);
	let currentToolbarMinHeight;
	if (isDesktop) {
		currentToolbarMinHeight = toolbar[toolbarDesktopQuery];
	} else if (isLandscape) {
		currentToolbarMinHeight = toolbar[toolbarLandscapeQuery];
	} else {
		currentToolbarMinHeight = toolbar;
	}

	return (currentToolbarMinHeight as MinHeight).minHeight;
}