import { StyleSheet, Text, View } from '@react-pdf/renderer';
import type { Style } from '@react-pdf/types';

const styles = StyleSheet.create({
	cell: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	cellText: {
		width: '100%',
		textAlign: 'center',
	},
});

export interface TableCellProps {
	width: number | string;

	containerStyle?: Style;
	textStyle?: Style;

	children?: React.ReactNode;
}

const TableCell: React.FC<TableCellProps> = (props: TableCellProps) => {
	const cs: Style[] = [
		styles.cell,
		{
			width: props.width,
		},
	];
	if (props.containerStyle) {
		cs.push(props.containerStyle);
	}

	const ts: Style[] = [styles.cellText];
	if (props.textStyle) {
		ts.push(props.textStyle);
	}
	return (
		<View style={cs}>
			<Text style={ts}>{props.children}</Text>
		</View>
	);
};

export default TableCell;
