type AppCaches = 'imagery' | 'rollers';

export interface ImageryCacheItem {
	image: Blob;
	zone: number;
	bbox: number[];
}

export class Cache {
	private static dbName = 'pavesetIC';
	private static imageryStore = 'imagery';
	private static dynapacStore = 'rollers';

	private static dbVersion = 1;

	private static cacheNames: { [key in AppCaches]: string } = {
		imagery: Cache.imageryStore,
		rollers: Cache.dynapacStore,
	};

	private static db: IDBDatabase | null = null;

	public static async init(): Promise<void> {
		if (Cache.db) {
			return Promise.resolve();
		}

		Cache.db = await Cache.open();
	}

	private static open(): Promise<IDBDatabase> {
		return new Promise((resolve, reject) => {
			const request = indexedDB.open(Cache.dbName, Cache.dbVersion);

			request.onerror = function (this: IDBRequest<IDBDatabase>, _ev: Event) {
				reject(request.error);
			};

			request.onsuccess = function (this: IDBRequest<IDBDatabase>, _ev: Event) {
				resolve(request.result);
			};

			request.onupgradeneeded = function (this: IDBOpenDBRequest, _ev: IDBVersionChangeEvent) {
				// don't resolve the promise here, as it will be resolved in the onsuccess handler
				const db = request.result;
				db.createObjectStore(Cache.cacheNames.imagery);
				db.createObjectStore(Cache.cacheNames.rollers);
			};
		});
	}

	public static async get<T>(cache: AppCaches, key: string): Promise<T> {
		Cache.db ??= await Cache.open();

		const transaction = Cache.db.transaction([Cache.cacheNames[cache]], 'readonly');
		const store = transaction.objectStore(Cache.cacheNames[cache]);
		const request = store.get(key);

		return new Promise((resolve, reject) => {
			request.onsuccess = function (this: IDBRequest<IDBValidKey>, _ev: Event) {
				resolve(request.result as T);
			};

			request.onerror = function (this: IDBRequest<IDBValidKey>, _ev: Event) {
				reject(request.error);
			};
		});
	}

	public static async save<T>(cache: AppCaches, key: string, value: T): Promise<void> {
		Cache.db ??= await Cache.open();

		return new Promise((resolve, reject) => {
			const transaction = Cache.db!.transaction(Cache.cacheNames[cache], 'readwrite');
			const store = transaction.objectStore(Cache.cacheNames[cache]);
			const request = store.put(value, key);

			request.onsuccess = function (_ev: Event) {
				resolve();
			};

			request.onerror = function (this: IDBRequest<IDBValidKey>, _ev: Event) {
				reject(request.error);
			};
		});
	}

	public static async exists(cache: AppCaches, key: string): Promise<boolean> {
		Cache.db ??= await Cache.open();

		return new Promise((resolve, reject) => {
			const transaction = Cache.db!.transaction(Cache.cacheNames[cache], 'readonly');
			const store = transaction.objectStore(Cache.cacheNames[cache]);
			const getKeyRequest = store.getKey(key);

			getKeyRequest.onerror = (_event) => {
				reject(`Get request error: ${getKeyRequest.error}`);
			};

			getKeyRequest.onsuccess = (_event) => {
				if (getKeyRequest.result !== undefined) {
					resolve(true);
				} else {
					resolve(false);
				}
			};
		});
	}

	public static async getImageryFile(key: string): Promise<ImageryCacheItem> {
		return Cache.get<ImageryCacheItem>('imagery', key);
	}

	public static async saveImageryFile(key: string, value: ImageryCacheItem): Promise<void> {
		return Cache.save('imagery', key, value);
	}

	public static getRollerFile(key: string): Promise<string> {
		return Cache.get<string>('rollers', key);
	}

	public static saveRollerFile(key: string, value: string): Promise<void> {
		return Cache.save('rollers', key, value);
	}
}