declare global {
  interface Number {
    /**
     * Return the square of the number.
     */
    square(): number;

    /**
     * Raise the number to the power of 3.
     */
    cube(): number;

    /**
     * Raise the number to the power of 4.
     */
    pow4(): number;

    /**
     * Raise the number to the power of 5.
     */
    pow5(): number;

    /**
     * Raise the number to the power of 6.
     */
    pow6(): number;

    /**
     * Raise the number to the power of x.
     * @param x The power to raise the number to.
     */
    pow(x: number): number;

    convertArcSecsToRadians(): number;

    toRadians(): number;
    toDegrees(): number;
  }

  interface Math {
    /**
     * Raises 2 to the power of x.
     * @param x The power of 2 to raise 2 to.
     */
    pow2(x: number): number;

    /**
     * Return the square of the number.
     */
    square(x: number): number;

    /**
     * Clamps a value between a minimum and maximum value.
     * @param value The value to clamp.
     * @param min The minimum value.
     * @param max The maximum value.
     */
    clip(value: number, min: number, max: number): number;
  }
}

if (!Number.prototype.square) {
  const ARCSECS_TO_RADIANS = Math.PI / (180.0 * 3600.0);
  const DEGREES_TO_RADIANS = Math.PI / 180;
  const RADIANS_TO_DEGREES = 180 / Math.PI;

  Number.prototype.square = function (this: number) {
    return this * this;
  };

  Number.prototype.cube = function (this: number) {
    return this * this * this;
  }

  Number.prototype.pow4 = function (this: number) {
    return this * this * this * this;
  }

  Number.prototype.pow5 = function (this: number) {
    return this * this * this * this * this;
  }

  Number.prototype.pow6 = function (this: number) {
    return this * this * this * this * this * this;
  }

  Number.prototype.pow = function (this: number, x: number) {
    return Math.pow(this, x);
  }

  Number.prototype.convertArcSecsToRadians = function (this: number) {
    return this * ARCSECS_TO_RADIANS;
  };

  Number.prototype.toRadians = function (this: number) {
    return this * DEGREES_TO_RADIANS;
  };

  Number.prototype.toDegrees = function (this: number) {
    return this * RADIANS_TO_DEGREES;
  };
}

if (!Math.pow2) {
  Math.pow2 = function (x: number) {
    return 1 << x;
  };

  Math.square = function (x: number) {
    return x * x;
  }

  Math.clip = function (value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max);
  }
}

// dummy export to force this file to be a 'module'
export {};