import { type DataItem } from '../../../dataPickerSlice';
import { Stack } from '@mui/material';
import ActiveLayersViewItem from './ActiveLayersViewItem';

export interface ActiveLayerItem {
	layerName: string;
	layerId: string;
}

export interface ActiveLayersViewProps {
	items: DataItem[];
	onRemoveItem?: (item: DataItem) => void;
}

const ActiveLayersView = (props: ActiveLayersViewProps) => {
	const { items } = props;

	return (
		<Stack direction='column' spacing={1} width={1.0}>
			{/* <ActiveLayersViewItem
				key={0}
				item={{ key: 'acdd89a7-59ec-4e50-b8a0-0c19ddbfb7e5', name: 'Testing...', isAvailable: true }}
			/> */}

			{items.map((item) => (
				<ActiveLayersViewItem key={item.key} item={item} />
			))}
		</Stack>
	);
};

export default ActiveLayersView;
