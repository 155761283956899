import { forwardRef } from 'react';
import { Link as RouterLink, type LinkProps as RouterLinkProps } from 'react-router-dom';

const LinkBehavior = forwardRef<
	HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
	const { href, ...other } = props;
	// Map href (MUI) -> to (react-router)
	return <RouterLink
		ref={ref}
		to={href}
		role={undefined}
		{...other}
	/>;
});

LinkBehavior.displayName = 'react-router(RouterLink)';

export default LinkBehavior;
