import { setAnalysis } from '@analysis/analysisSlice';
import {
	analyseReport,
	type CompactionReportSetup,
	type CompactionSpecification,
	type ReportType,
} from '@analysis/RollerAnalysis';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useIsXSmall } from '@hooks/responsiveHooks';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import {
	Alert,
	Box,
	CircularProgress,
	Slide,
	Snackbar,
	Stack,
	styled,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	type SnackbarCloseReason,
	type SnackbarOrigin,
	type SxProps,
	type Theme,
} from '@mui/material';
import { Datum } from '@shared/geometry/core/Coordinate';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import readRollerData from '../../data/rollerReader';
import { Boundary, BoundaryCollection, type RollerData } from '../../data/rollerTypes';
import { time } from '../../tools/helpers';
import BoundaryDataPicker from './boundary/BoundaryDataPicker';
import ReportConfigTable from './config/ReportConfigTable';
import {
	selectBoundaryFiles,
	selectCompactionSpecs,
	selectConfigStatus,
	selectDynapacAuthState,
	selectOverview,
	selectReportType,
	selectRollerItemsByCurrentSource,
	setCompactionSpecs,
	updateOverview,
	type ConfigStatus,
} from './dataPickerSlice';
import RollerDataPicker from './roller/RollerDataPicker';
import DataPickerSection from './shared/DataPickerSection';
import { LoadingButtonWithTooltip } from './shared/LoadingButtonWithTooltip';
import { useAlignmentsPrefetch } from '../api/alignmentApiSlice';
import { useDynapacPrefetch } from '../api/dynapacApiSlice';
import useStartupEffect from '@hooks/useStartupEffect';
import { loginDetails } from './shared/dynapacLoginDetails';
import { dynapacApi } from '../api/dynapacApiSlice';
import { alignmentApi } from '../api/alignmentApiSlice';

function isConfigComplete(status: ConfigStatus) {
	return status.rollerFiles && status.boundaryFiles && status.config;
}

function createTooltipMessage(status: ConfigStatus, isEditing: boolean) {
	if (!status.rollerFiles) {
		return 'Select the file(s) that contain the roller pass data';
	}

	if (!status.boundaryFiles) {
		return 'Select the file(s) that contain the roller boundary data';
	}

	if (!status.config) {
		return 'Configure the analysis specifications';
	}

	if (isEditing) {
		return 'Save or Cancel the current configuration to continue';
	}

	return 'Press the Process Report button to start the analysis';
}

const rollerAndBoundaryStackStyle: SxProps<Theme> = {
	pt: { xs: 1, sm: 2 },
	pl: { xs: 1, sm: 2 },
	pr: { xs: 1, sm: 2 },
	pb: { xs: 1, sm: 2 },
	mt: 0,
	mb: 0,
	overflowY: 'auto',
	flexGrow: 1,
	flexShrink: 1,
};

const configSectionStyle: SxProps<Theme> = {
	pb: 0,
};

const processSnackbarAnchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'center' };

const ProcessLoadingIndicator = styled(CircularProgress)({
	color: 'inherit',
	width: 32,
	height: 32,
});

const DataPicker = () => {
	const isXSmall = useIsXSmall();
	const [isProcessing, setIsProcessing] = useState(false);
	const [analysisError, setAnalysisError] = useState<string | null>(null);
	const [showError, setShowError] = useState(false);

	const rollerFilesOrKeys = useAppSelector(selectRollerItemsByCurrentSource);
	const boundaryFiles = useAppSelector(selectBoundaryFiles);
	const compactionSpecs = useAppSelector(selectCompactionSpecs);
	const overview = useAppSelector(selectOverview);
	const reportType = useAppSelector(selectReportType);

	const status = useAppSelector(selectConfigStatus);

	const [isEditing, setIsEditing] = useState(false);

	const [isSetupComplete, setIsSetupComplete] = useState(isConfigComplete(status));
	const [tooltipMessage, setTooltipMessage] = useState<string>(createTooltipMessage(status, isEditing));

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const alignmentsSummaryPrefetch = useAlignmentsPrefetch('getAlignmentsSummary');
	const dynapacLoginPrefetch = useDynapacPrefetch('getDynapacAccessToken');
	const dynapacJobsPrefetch = useDynapacPrefetch('getDynapacJobsList');
	const dynAuthState = useAppSelector(selectDynapacAuthState);

	useStartupEffect(() => {
		if (!dynAuthState.auth || dynAuthState.getIsExpired()) {
			dynapacLoginPrefetch(loginDetails);
		} else {
			dynapacJobsPrefetch({
				email: loginDetails.email,
			});
		}

		alignmentsSummaryPrefetch({
			jobId: 'TMR-METRO',
		});

		(async function() {
			const result = await dispatch(dynapacApi.endpoints.getDynapacAccessToken.initiate(loginDetails)).unwrap();

		})();
	});

	useEffect(() => {
		if (isConfigComplete(status) && !isEditing) {
			setIsSetupComplete(true);
		} else {
			setIsSetupComplete(false);
		}

		setTooltipMessage(createTooltipMessage(status, isEditing));
	}, [status, isEditing]);

	const handleProcessRollerReport = () => {
		setIsProcessing(true);

		// push CPU intensive work to the back of the event queue so the UI can update
		setTimeout(async () => {
			// 1) process roller data - either CSV files or JSON from api (stored in IndexedDB)
			let data: RollerData | null = null;
			try {
				data = await readRollerData(rollerFilesOrKeys);
			} catch (e) {
				console.error(e);
				setIsProcessing(false);
				setAnalysisError('Failed Reading Roller Data...');
				setShowError(true);
				return;
			}

			console.log(`Loaded ${data.length} cells`);
			if (data.length === 0) {
				// handle error...
				setIsProcessing(false);
				setAnalysisError('Failed Reading Roller CSV Data...');
				setShowError(true);
				return;
			}

			// 2) process boundary data
			const boundaries: BoundaryCollection = new BoundaryCollection();
			for (const boundaryFile of boundaryFiles) {
				try {
					const boundaryData = await Boundary.parseFile(boundaryFile, Datum.GDA2020);

					if (Array.isArray(boundaryData)) {
						boundaries.push(...boundaryData);
					} else {
						boundaries.push(boundaryData);
					}
				} catch (error) {
					console.error(error);
					setIsProcessing(false);
					setAnalysisError('Failed Reading Boundary Data...');
					setShowError(true);
					return;
				}
			}

			data.boundaries = boundaries;
			time(() => {
				if (boundaries.length > 0) {
					for (const cell of data.getCells()) {
						cell.checkWithinBoundaries(boundaries);
					}
				}
			}, 'Check Boundaries');
			//console.log(data.boundaries);

			// 3) process configuration
			// TODO: extract job info from the roller data
			const reportSetup: CompactionReportSetup = {
				dates: data.overviews.map((overview) => overview.date),

				analysisType: reportType,
				specifications: compactionSpecs,
				overview,

				rollerData: data,
				boundaries,
			};

			try {
				const analysis = analyseReport(reportSetup);
				dispatch(setAnalysis(analysis));
			} catch (error) {
				console.error(error);
				setIsProcessing(false);
				setAnalysisError('Failed to Analyse Roller Data...');
				setShowError(true);
				return;
			}

			navigate('/viewer');
		}, 250);
	};

	const handleErrorAlertClose = (
		_event: Event | React.SyntheticEvent<unknown, Event>,
		_reason: SnackbarCloseReason
	) => {
		console.log('closing error alert...');
		setAnalysisError(null);
		setShowError(false);
	};

	const handleSpecsUpdated = useCallback(
		(specs: CompactionSpecification[]) => {
			dispatch(setCompactionSpecs(specs));
		},
		[dispatch]
	);

	const handleEditModeChange = (isInEditMode: boolean) => {
		setIsEditing(isInEditMode);
	};

	const handleReportTypeChange = useCallback((event: React.MouseEvent<HTMLElement>, newType: ReportType | null) => {
		dispatch(setCompactionSpecs([]));
		dispatch(
			updateOverview({
				reportType: newType ?? 'ASPHALT',
			})
		);
	}, [dispatch]);

	const getTitleComponent = useCallback((titleHeight: number, titleClass: string) => (
		<Box sx={{ width: '100%', display: 'flex' }}>
			<Typography
				height={titleHeight}
				width='100%'
				textAlign='center'
				alignContent='center'
				variant='h6'
				flex={1}
				className={titleClass}>
				Report Configuration
			</Typography>
			<ToggleButtonGroup
				color='primary'
				exclusive
				value={reportType}
				onChange={handleReportTypeChange}
				aria-label='Report Type Selector'
				sx={{
					mb: { xs: 0.5, sm: 1 },
					mt: { xs: 0.5, sm: 1 },
				}}>
				<ToggleButton value='ASPHALT'>Asphalt</ToggleButton>
				<ToggleButton value='BASE'>Base</ToggleButton>
			</ToggleButtonGroup>
		</Box>
	), [handleReportTypeChange, reportType]);

	const processButtonStyle: SxProps<Theme> = useMemo(() => ({
		'.MuiButton-startIcon>*:nth-of-type(1)': {
			fontSize: {
				xs: 24,
				md: 32,
			},
		},

		ml: { xs: 1, sm: 2 },
		mr: { xs: 1, sm: 2 },
		mb: { xs: 1, sm: 2 },
	}), []);
	const processAlertStyle: SxProps<Theme> = useMemo(() => ({
		width: '100%',
		pl: { xs: 2, sm: 4 },
		pr: { xs: 2, sm: 4 },
	}), []);

	const processSnackbarStyle: SxProps<Theme> = useMemo(() => ({
		mb: '72px',
	}), []);

	const rollerAndBoundaryStack = useMemo(() => {
		const orientation = isXSmall ? 'column' : 'row';

		return (
			<Stack direction={orientation} spacing={2}>
				{/*
                     * picker component for roller IC data
                     * 	- CSV file(s)
                     * 	- Dynapac API
                     */}
				<RollerDataPicker />

				{/* picker component for boundary data */}
				<BoundaryDataPicker />
			</Stack>
		);
	}, [isXSmall]);

	const mainLayoutStack = useMemo(() => {
		return (
			<Stack
				spacing={2}
				sx={rollerAndBoundaryStackStyle}>

				{/* roller and boundary data pickers */}
				{rollerAndBoundaryStack}

				{/* report configuration section */}
				<Box marginBottom='auto'>
					<DataPickerSection
						containerStyles={configSectionStyle}
						section={3}
						title='Report Configuration'
						titleComponent={getTitleComponent}
						isComplete={compactionSpecs.length > 0}>
						<Box width='100%' height='100%'>
							<ReportConfigTable
								reportType={reportType}
								specs={compactionSpecs}
								onSpecsUpdated={handleSpecsUpdated}
								onEditModeChange={handleEditModeChange}
							/>
						</Box>
					</DataPickerSection>
				</Box>
			</Stack>
		);
	}, [rollerAndBoundaryStack, getTitleComponent, compactionSpecs, reportType, handleSpecsUpdated]);

	return (
		<Stack direction='column' height='100%'>
			{mainLayoutStack}

			{/* <Box
				sx={{
					zIndex: 100,
					width: 'calc(100%)',
					paddingTop: '25px',
					overflow: 'hidden',
					ml: '0px',
					mr: '0px',
					boxShadow: '0px -4px 8px -2px rgba(120, 120, 120, 0.55)',
				}}></Box> */}

			<LoadingButtonWithTooltip
				tooltipText={tooltipMessage}
				disabled={!isSetupComplete}
				loading={isProcessing}
				loadingPosition='center'
				loadingIndicator={<ProcessLoadingIndicator />}
				startIcon={<AutoGraphOutlinedIcon />}
				onClick={handleProcessRollerReport}
				variant='contained'
				color='primary'
				size='large'
				sx={processButtonStyle}>
				<Typography
					variant='h6'
					fontSize={isXSmall ? '1rem' : '1.25rem'}
					padding={1}>
					Process Roller Report
				</Typography>
			</LoadingButtonWithTooltip>

			<Snackbar
				open={showError}
				anchorOrigin={processSnackbarAnchorOrigin}
				autoHideDuration={5000}
				onClose={handleErrorAlertClose}
				transitionDuration={1000}
				TransitionComponent={(props) => <Slide {...props} direction='up' />}
				sx={processSnackbarStyle}>
				<Alert
					severity='error'
					variant='filled'
					sx={processAlertStyle}>
					{analysisError}
				</Alert>
			</Snackbar>
		</Stack>
	);
};

export default DataPicker;
