import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

export const FilesTable = (props: { files: File[]; alignCenter?: boolean; }) => {
	const { files, alignCenter } = props;
	const align = alignCenter ? 'center' : 'left';

	return (
		<TableContainer component={Box}>
			<Table aria-label='roller-files-table' size='small'>
				<TableHead
					sx={{
						'th:last-child': {
							backgroundColor: 'white',
							position: 'sticky',
							right: 0,
							zIndex: 5,
						},
						'th:first-of-type': {
							position: 'sticky',
							left: 0,
							zIndex: 10,
						},
					}}>
					<TableRow>
						<TableCell align={align}> File Name </TableCell>
						<TableCell align='center' width={60} style={{ position: 'sticky' }}>
							Size
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{files.map((file, index) => {
						const size = file.size > 1024 * 1024 ? `${(file.size / 1024 / 1024).toFixed(1)}MB` : `${(file.size / 1024).toFixed(1)}KB`;

						return (
							<TableRow
								key={index}
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0,
									},
									'td:last-child': {
										position: 'sticky',
										right: 0,
										zIndex: 2,
										backgroundColor: 'white',
									},
								}}>
								<TableCell align={align} style={{ textWrap: 'nowrap' }}>
									{file.name}
								</TableCell>
								<TableCell align='center' width={60}>
									{size}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
