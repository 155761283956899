import type { AllReducerActions, RootState } from '@app/store';
import type { PayloadAction } from '@reduxjs/toolkit';

export type PersistHandlers<T extends PayloadAction | PayloadAction<unknown>> = {
	[K in T['type']]?: (state: RootState) => void;
};

export type PeristHandler2<T extends Partial<AllReducerActions>> = {
	[K in keyof T['type']]: (state: RootState) => void;
};

export function setOrRemove(key: string, value: unknown) {
	if (value) {
		localStorage.setItem(key, JSON.stringify(value));
	} else {
		localStorage.removeItem(key);
	}
}