import { Button, Stack } from '@mui/material';
import dayjs from 'dayjs';

const TestPage = () => {
	const handleTestClick = async () => {
		const start = dayjs();
		const resp = await fetch('/api/test/test-latency-id');
		const json = await resp.json();
		const end = dayjs();
		const diff = end.diff(start, 'ms');

		console.log('Query Time:', diff, 'ms');
		console.log('Response:', json);
	};
	return (
		<Stack
			direction='column'
			spacing={2}
			sx={{ p: 2 }}
			alignItems='center'>
			<Button variant='contained' onClick={handleTestClick} sx={{ width: 'auto' }}>
				Test API Latency
			</Button>
		</Stack>
	);
};

export default TestPage;
