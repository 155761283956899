import type { BaseTypeOfArray } from './typeHelpers';

declare global {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Generator<T, TReturn, TNext> {
		// first(predicate?: (item: T) => boolean): T | null;
		// last(predicate?: (item: T) => boolean): T;

		// contains(item: T): boolean;
		where(predicate: (current: T, previous: T) => boolean): Generator<T, TReturn, T>;
		// select<U>(selector: (item: T) => U): Generator<U, unknown, unknown>;
		// selectMany<U>(selector: (item: T) => U[] | Generator<U, unknown, unknown>): Generator<U, any, unknown>;

		// skip(count: number): Generator<T, unknown, unknown>;
		// take(count: number): Generator<T, unknown, unknown>;

		// min(selector: (item: T) => number): number;
		// max(selector: (item: T) => number): number;

		// minBy(selector: (item: T) => number): T;
		// maxBy(selector: (item: T) => number): T;

		// any(predicate?: (item: T) => boolean): boolean;
		// count(predicate?: (item: T) => boolean): number;

		// groupBy<TKey>(selector: (item: T) => TKey): Map<TKey, T[]>;

		// toArray?(): T[];
	}

	interface Array<T> {
		unique<S>(selector?: (item: T) => S): T[];
	}

	// interface Array<T> {
	//   where(predicate: (item: T, previous: T) => boolean): Generator<T, any, unknown>;
	//   select<U>(selector: (item: T) => U): Generator<U, any, unknown>;
	//   selectMany<U>(selector: (item: T) => U[] | Generator<U, any, unknown>): Generator<U, any, unknown>;

	//   minBy(selector: (item: T) => number): T;
	//   maxBy(selector: (item: T) => number): T;

	//   min(selector: (item: T) => number): number;
	//   max(selector: (item: T) => number): number;

	//   range(selector: (item: T) => number): ValueRange<number>;
	//   rangeBy(selector: (item: T) => number): ValueRange<T>;

	//   skip(count: number): Generator<T, any, unknown>;
	//   take(count: number): Generator<T, any, unknown>;

	//   calculateStats(selector: (item: T) => number): CollectionStats;

	//   first(predicate?: (item: T) => boolean): T | undefined;
	//   last(predicate?: (item: T) => boolean): T;

	//   contains(item: T): boolean;

	//   any(predicate?: (item: T) => boolean): boolean;
	//   count(predicate?: (item: T) => boolean): number;

	//   groupBy<TKey>(selector: (item: T) => TKey): Map<TKey, T[]>;

	//   toArray?(): T[];
	// }

	// interface Iterable<T> {
	//   any(predicate: (item: T) => boolean): boolean;
	//   groupBy<TKey>(selector: (item: T) => TKey): Map<TKey, T[]>;
	// }

	interface IterableIterator<T> {
		where(predicate: (current: T, previous: T) => boolean): IterableIterator<T>;
		toArray(): BaseTypeOfArray<T>[];

		groupBy<TKey>(selector: (item: T) => TKey): Map<TKey, T[]>;
	}

	interface MapIterator<T> {
		where(predicate: (current: T, previous: T) => boolean): IterableIterator<T>;
		toArray(): BaseTypeOfArray<T>[];

		groupBy<TKey>(selector: (item: T) => TKey): Map<TKey, T[]>;
	}

	interface Map<K, V> {
		//addOrUpdate(key: K, handler: (map: Map<K, V>, current?: V) => void): void;
		addOrUpdate(key: K, value: BaseTypeOfArray<V>): void;
	}
}

const whereGenerator = function*<T>(this: Iterable<T>, predicate: (item: T, previous: T) => boolean) {
	let prev: T = null!;

	for (const item of this) {
		if (predicate(item, prev)) {
			yield item;
			prev = item;
		}
	}
};

const whereIterator = function*<T>(this: Iterator<T>, predicate: (item: T, previous: T) => boolean) {
	let prev: T = null!;

	for (let result = this.next(); !result.done; result = this.next()) {
		const item = result.value;

		if (predicate(item, prev)) {
			yield item;
			prev = item;
		}
	}
};

function iterableGroupBy<T, U>(this: Iterator<T>, keySelector: (item: T) => U): Map<U, T[]> {
	const groups = new Map<U, T[]>();
	for (let result = this.next(); !result.done; result = this.next()) {
		const item = result.value;

		const key = keySelector(item);
		const group = groups.get(key) ?? [];
		group.push(item);
		groups.set(key, group);
	}

	return groups;
}

const toArrayFunction = function <T>(this: Iterable<T> | ArrayLike<T>): T[] {
	return Array.from(this);
};

const uniqueFunction = function <T, S>(this: T[], selector?: (item: T) => S): T[] {
	const seen = new Set<S | T>();
	return this.filter((item) => {
		const key = selector ? selector(item) : item;
		if (seen.has(key)) {
			return false;
		}

		seen.add(key);
		return true;
	});
};

// get the prototype of the basic generator
const gen = function* <T>(): Generator<T, unknown, T> {
	yield null as T;
	yield null as T;
	return null as T;

	//throw null as T;
};

const Generator = Object.getPrototypeOf(gen) as GeneratorFunction;

if (!Generator.prototype.where) {
	Generator.prototype.where = whereGenerator;
}

if (!Array.prototype.unique) {
	Array.prototype.unique = uniqueFunction;
}

const iterator = Object.getPrototypeOf(Object.getPrototypeOf([][Symbol.iterator]()));

if (!iterator.where) {
	iterator.toArray = toArrayFunction;
	iterator.where = whereIterator;
	iterator.groupBy = iterableGroupBy;
}

const mapIterator = Object.getPrototypeOf(Object.getPrototypeOf(new Map().values()));
if (!mapIterator.where) {
	mapIterator.toArray = toArrayFunction;
	mapIterator.where = whereIterator;
	mapIterator.groupBy = iterableGroupBy;
}

if (!Map.prototype.addOrUpdate) {
	// Map.prototype.addOrUpdate = function <K, V>(this: Map<K, V[]>, key: K, handler: (map: Map<K, V>, current?: V) => void): void {
	//   if (!this.has(key)) {
	//     this.set(key, [value instanceof Function ? value() : value]);
	//   }

	//   return this.get(key)!;
	// }

	Map.prototype.addOrUpdate = function <K, V>(this: Map<K, V[]>, key: K, value: V): void {
		if (!this.has(key)) {
			this.set(key, [value]);
		} else {
			this.get(key)!.push(value);
		}
	};
}