import styled from '@emotion/styled';
import { Button, type TooltipProps, Tooltip, tooltipClasses } from '@mui/material';
import type { Theme } from '@mui/material/styles';

export const LoadingButtonEx = styled(Button)({
	'&.Mui-disabled': {
		pointerEvents: 'auto',
	},
});

export const TooltipEx = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		textWrap: 'nowrap',
		padding: `${(theme as Theme).spacing(1)} ${(theme as Theme).spacing(3)}`,
		width: 'fit-content',
		maxWidth: 'fit-content',
		fontSize: '1rem',

		[(theme as Theme).breakpoints.down('sm')]: {
			fontSize: '0.75rem',
			padding: (theme as Theme).spacing(1),
		},
	},
}));

export interface ButtonWithTooltipProps extends React.ComponentProps<typeof LoadingButtonEx> {
	tooltipText: string;
}

export const LoadingButtonWithTooltip = ({
	tooltipText,
	disabled,
	onClick,
	...other
}: ButtonWithTooltipProps) => {
	const adjustedButtonProps = {
		disabled: disabled,
		component: disabled ? 'div' : undefined,
		onClick: disabled ? undefined : onClick,
	};
	return (
		<TooltipEx
			arrow
			title={tooltipText}
			enterDelay={1000}
			leaveDelay={250}
			sx={{
				'& .MuiTooltip-tooltip': {
					textWrap: 'nowrap!important',
				},
			}}>
			<LoadingButtonEx {...other} {...adjustedButtonProps} />
		</TooltipEx>
	);
};
