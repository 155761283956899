import type { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '../../app/createAppSlice';
import type { CompactionAnalysis } from './RollerAnalysis';

export interface AnalysisState {
	isProcessing: boolean;
	analysis?: CompactionAnalysis;
}

const initialState: AnalysisState = {
	isProcessing: false,
};

export const analysisSlice = createAppSlice({
	name: 'analysis',
	initialState,
	reducers: (create) => ({
		setIsProcessing: create.reducer((state, action: PayloadAction<boolean>) => {
			state.isProcessing = action.payload;
		}),

		setAnalysis: create.reducer((state, action: PayloadAction<CompactionAnalysis>) => {
			state.analysis = action.payload;
			state.isProcessing = false;
		})
	}),

	selectors: {
		selectIsProcessing: (state) => state.isProcessing,

		selectAnalysis: (state) => state.analysis,
		selectSetup: (state) => state.analysis?.setup,
		selectSetupSpecs: (state) => state.analysis?.setup.specifications,
		selectAnalysisType: (state) => state.analysis?.setup.analysisType,

		selectRollerData: (state) => state.analysis?.setup.rollerData,
		selectBoundaries: (state) => state.analysis?.setup.boundaries,

		selectChartData: (state) => state.analysis?.chartsData,
		selectCoverages: (state) => state.analysis?.coverages
	}
});

export const {
	setIsProcessing,
	setAnalysis
} = analysisSlice.actions;

export const {
	selectIsProcessing,
	selectSetup,
	selectSetupSpecs,
	selectAnalysis,
	selectAnalysisType,

	selectRollerData,
	selectBoundaries,
	selectChartData,
	selectCoverages
} = analysisSlice.selectors;

export const ignoredAnalysisActions = [
	setAnalysis.type,
];

export const ignoredAnalysisPaths = [
	'analysis.analysis',
];

export type AnalysisActions = typeof analysisSlice.actions;
export type AnalysisActionTypes = PayloadAction<AnalysisActions>;