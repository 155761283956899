import {
	selectReportType,
	removeRollerFile,
	type DataItem,
	updateRollerFile,
} from '../../../dataPickerSlice';
import type { ActiveLayerStatus } from './types';
import styles from './ActiveLayersViewItem.module.css';
import {
	Box,
	CircularProgress,
	IconButton,
	LinearProgress,
	Paper,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useEffect, useRef, useState } from 'react';
import useStartupEffect from '@hooks/useStartupEffect';
import { downloadParallel } from '@tools/downloader';
import {
	useLazyStartDynapacCreateReportQuery,
	useLazyGetDynapacReportStatusQuery,
} from '../../../../api/dynapacApiSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Cache } from '@data/Cache';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import type { SerializedError } from '@reduxjs/toolkit';

export interface ActiveLayersViewItemProps {
	item: DataItem;
}

interface LayerState {
	status: ActiveLayerStatus;
	error?: string | null;
	downloadId?: string;
	url?: string;
}

const ActiveLayersViewItem = (props: ActiveLayersViewItemProps) => {
	const dispatch = useAppDispatch();
	const { item } = props;
	const { key: layerId, name: layerName, isAvailable } = item;
	const [progress, setProgress] = useState<number>(0);

	const [state, setState] = useState<LayerState>({
		status: isAvailable ? 'complete' : 'init',
	});

	const reportType = useAppSelector(selectReportType);
	const [startCreateReport] = useLazyStartDynapacCreateReportQuery();
	const [getReportStatus] = useLazyGetDynapacReportStatusQuery();

	const cancelCallback = useRef<(() => void) | null>(null);
	const cancelRef = useRef(false);
	const abortRef = useRef<AbortController | null>(null);
	const getReportStatusRef = useRef<ReturnType<typeof getReportStatus> | null>(null);

	const infoText =
		state.status === 'processing'
			? 'Processing...'
			: state.status === 'downloading'
				? 'Downloading...'
				: state.status === 'complete'
					? 'Complete'
					: state.status === 'error'
						? state.error || 'Unknown Error'
						: 'Initializing...';

	/**
	 * cancels any ongoing requests
	 */
	const cancel = () => {
		cancelCallback.current?.();
		cancelRef.current = true;
		abortRef.current?.abort();
	};

	/**
	 * checks for file in the cache,
	 * if not found, starts the report generation process
	 */
	const init = async (force: boolean) => {
		const exists = !force && (await Cache.exists('rollers', layerId));

		if (exists) {
			setState({
				status: 'complete',
			});
			setProgress(100);
		} else {
			setState({
				status: 'processing',
			});

			cancelRef.current = false;
			const createReportRequest = startCreateReport({
				isBaseData: reportType === 'BASE',
				layerId: layerId,
				layerName: layerName,
			});

			cancelCallback.current = () => {
				console.log('cancelling request...');
				createReportRequest.abort();
				getReportStatusRef.current?.abort();
			};

			const result = await createReportRequest.unwrap();

			if (result.success && result.id) {
				setState({
					status: 'processing',
					downloadId: result.id,
				});

				while (!cancelRef.current) {
					getReportStatusRef.current = getReportStatus({ id: result.id, retries: 0 });
					const statusResult = await getReportStatusRef.current;
					getReportStatusRef.current = null;

					const data = statusResult.data;
					if (data) {
						const status = data.status;

						if (status === 'Completed') {
							console.log('report completed');

							setState({
								status: 'downloading',
								url: data.url,
								downloadId: result.id,
							});

							break;
						} else if (status === 'Running') {
							await new Promise((resolve) => {
								setTimeout(resolve, 5000);
							});
						} else if (status === 'Failed' || status === 'Error') {
							setState({
								status: 'error',
								error: data.error,
							});

							break;
						}
					} else {
						const statusError = statusResult.error as SerializedError;
						const errorMsg = statusError.name === 'AbortError' ? 'Cancelled' : statusError.message;

						setState({
							status: 'error',
							error: errorMsg,
						});

						break;
					}
				}
			} else {
				setState({
					status: 'error',
					error: result.error,
				});
			}
		}
	};

	useStartupEffect(() => {
		if (isAvailable) {
			console.log('file already available...');
			return;
		}

		init(false);

		return () => {
			// cleanup
			console.log('cleaning up...');
			cancel();
		};
	});

	useEffect(() => {
		if (state.status === 'downloading' && state.url) {
			// download the IC data
			const url = state.url;
			abortRef.current = new AbortController();
			const controller = abortRef.current;
			const download = async () => {
				console.log('downloading', url);

				const downloadData = await downloadParallel(url, {
					chunkCount: 4,
					progress: (p) => {
						console.log('progress', p);
						setProgress(p);
					},
					signal: controller.signal,
				});
				abortRef.current = null;

				const decoder = new TextDecoder('utf-8');
				const text = decoder.decode(downloadData);

				await Cache.saveRollerFile(layerId, text);

				setState((s) => ({
					...s,
					status: 'complete',
				}));

				const dataItem: DataItem = { name: layerName, key: layerId, isAvailable: true };
				dispatch(updateRollerFile(dataItem));
			};

			download();

			return () => {
				controller?.abort();
			};
		}
	}, [dispatch, layerId, layerName, state]);

	const handleCancel = () => {
		cancelRef.current = true;
		getReportStatusRef.current?.abort();
		abortRef.current?.abort();

		setState({
			status: 'error',
			error: 'Cancelled',
		});
	};

	const handleRemove = () => {
		console.log('removing', layerName);
		dispatch(removeRollerFile(item));
	};

	const handleRefresh = async () => {
		cancel();

		await init(true);
	};

	return (
		<Paper elevation={0} className={styles.layout} sx={{ overflow: 'hidden' }}>
			<Stack
				direction='row'
				spacing='4px'
				alignItems='center'
				className={styles.nameLayout}>
				{state.status === 'complete' && (
					<DoneAllOutlinedIcon
						color='success'
						sx={{
							width: 16,
						}}
					/>
				)}
				<Typography
					className={styles.nameText}
					sx={{
						fontSize: { xs: '0.75rem', sm: '1.0rem' },
						textAlign: 'start',
					}}>
					{layerName}
				</Typography>
			</Stack>

			<div className={styles.infoLayout}>
				<Stack direction='row' spacing={1} alignItems='center'>
					{state.status === 'processing' && (
						<CircularProgress
							size={16}
							sx={{
								mt: '4px!important',
								mb: '4px!important',
							}}
						/>
					)}
					<Typography
						textAlign='center'
						fontStyle='italic'
						fontWeight={500}
						color={state.status === 'error' ? 'error' : 'text.primary'}
						fontSize={'0.75rem'}>
						{infoText}
					</Typography>
				</Stack>
			</div>

			<Box className={styles.buttonsLayout} sx={{ zIndex: 1, backgroundColor: 'inherit' }}>
				{(state.status === 'processing' || state.status === 'downloading') && (
					<IconButton onClick={handleCancel}>
						<CancelOutlinedIcon color='action' />
					</IconButton>
				)}
				{(state.status === 'complete' || state.status === 'error') && (
					<>
						<Tooltip enterDelay={1000} title='Re-download IC layer'>
							<IconButton onClick={handleRefresh} sx={{ padding: '6px' }}>
								<RefreshIcon color='action' />
							</IconButton>
						</Tooltip>

						<Tooltip enterDelay={1000} title='Remove IC layer'>
							<IconButton onClick={handleRemove} sx={{ padding: '6px' }}>
								<DeleteOutlineIcon color='action' />
							</IconButton>
						</Tooltip>
					</>
				)}
			</Box>

			{!state.error && (
				<div
					style={{
						display: state.status === 'processing' || state.status === 'downloading' ? 'block' : 'none',
					}}
					className={styles.progressLayout}>
					<LinearProgress
						sx={{ borderBottomLeftRadius: 8, height: 6 }}
						variant='determinate'
						value={Math.max(0, progress)}
					/>
				</div>
			)}
		</Paper>
	);
};

export default ActiveLayersViewItem;
