import dayjs, { type Dayjs } from 'dayjs';
import { mergeAndSortRollerData, RollerCell, RollerData, type AmplitudeSetting, type Direction, type GNSSQuality, type OverviewInfo, type RollerPass } from './rollerTypes';
import { MercatorTransform } from '@shared/geometry/transforms/transforms';
import { Datum, type GridCoordinate } from '@shared/geometry/core/Coordinate';
import MathEx from '@tools/MathEx';
import { Cache } from './Cache';

function dynapacReviver(key: string, value: unknown) {
	if (key === 'utcdate' && typeof value === 'string') {
		return dayjs.utc(value);
	}

	if (key === 'amplitudesetting' && typeof value === 'number') {
		const rounded = Math.floor(value);
		if (rounded === 0) {
			return 'Static';
		} else if (rounded === 1) {
			return 'Low';
		} else if (rounded === 2) {
			return 'High';
		} else {
			return 'Unknown';
		}
	}

	if (key === 'gnssquality' && typeof value === 'number') {
		const rounded = Math.floor(value);
		if (rounded === 0) {
			return 'Low';
		} else if (rounded === 1) {
			return 'High';
		} else {
			return 'Unknown';
		}
	}

	if (key === 'drivingdirection' && typeof value === 'number') {
		const rounded = Math.floor(value);
		if (rounded === 0) {
			return 'N';
		}
		if (rounded === 1) {
			return 'F';
		}

		if (rounded === 2) {
			return 'R';
		}

		return 'Unknown';
	}

	return value;
}

export default class DynapacJSON {

	/**
   * Reads the roller data 'files' and combines them into a single RollerData object
   * @param files array of 'file' keys that point to sessionStorage items
   */
	static async read(files: string[]): Promise<RollerData> {
		const data: RollerData[] = [];
		const overviews: OverviewInfo[] = [];

		const result = await Promise.all(files.map(async (file) => {
			try {
				// TODO: create transform dynamically based on file content
				//const transform = MercatorTransform.fromGDA94ToGDA2020();
				//const transform = MercatorTransform.fromGDA2020ToGDA94();
				//const transform = MercatorTransform.createFromSingleDatum(Datum.GDA2020);
				const transform = MercatorTransform.fromWGS84ToGDA2020SAA(dayjs());
				return DynapacJSON.parse(file, transform);
			} catch (err) {
				console.error(err);
			}
		}));

		result.forEach((roller) => {
			if (!roller) {
				return;
			}

			const [rollerData, overview] = roller;
			data.push(rollerData);
			overviews.push(overview);
		});

		const merged = mergeAndSortRollerData(data, overviews);

		return merged;
	}

	private static async parse(filePath: string, transform?: MercatorTransform): Promise<[RollerData, OverviewInfo]> {
		const json = await Cache.getRollerFile(filePath);
		const parsed = JSON.parse(json, dynapacReviver) as DynapacDataJSON[];

		const overview: OverviewInfo = {
			engineer: 'Unknown',
			epsg: -1,
			epsgName: 'Unknown',
			project: 'Unknown',

			...extractOverviewInfo(parsed)
		};

		const data = new RollerData();

		for (const pass of parsed) {
			const { Easting, Northing, passes } = pass;

			const coord: GridCoordinate = {
				x: Easting,
				y: Northing,
				datum: Datum.GDA94,
				zone: 56
			};

			const transformed = transform?.transformRedfearn(coord) ?? coord;
			const cell = new RollerCell(transformed, passes.map((p, i) => {
				const pass: RollerPass = {
					rollerId: p.machineid,
					coords: transformed,
					passNumber: i + 1,
					timestamp: p.utcdate,

					speed: p.speed,
					amplitude: p.amplitude,
					amplitudeSetting: p.amplitudesetting,
					cmv: p.cmv === undefined ? null : p.cmv,
					temperature: p.temperature ?? 0,
					gnss: p.gnssquality ?? 'Unknown',

					direction: p.drivingdirection ?? 'Unknown'
				};

				return pass;
			}).sort((a, b) => a.timestamp.diff(b.timestamp)));

			data.addOrUpdate(cell);
		}

		return [data, overview];
	}
}

function extractOverviewInfo(data: DynapacDataJSON[]): {
	rollerId: string,
	date: Dayjs
} {
	const rollerId = data[0].passes[0].machineid;

	// find the earliest date
	//  - shift the date back by 5 hours so that data from midnight to 5am is included in the previous day
	const date = dayjs.unix(MathEx.min(data.flatMap((d) => d.passes).map((p) => p.utcdate.unix()))).subtract(5, 'hours');

	return { rollerId, date };
}

interface DynapacDataJSON {
	Easting: number;
	Northing: number;
	passes: DynapacPassJSON[];
}

interface DynapacPassJSON {
	machineid: string;
	utcdate: Dayjs;

	cmv?: number | null;
	temperature?: number;

	drivingdirection: Direction;
	speed: number;

	gnssquality?: GNSSQuality;
	amplitudesetting: AmplitudeSetting;
	amplitude: number;
}