import {
	selectAnalysisType,
	selectBoundaries,
	selectCoverages,
	selectRollerData,
} from '@analysis/analysisSlice';
import { isAsphaltSpec, isBaseSpec } from '@analysis/RollerAnalysis';
import { useAppSelector } from '@app/hooks';
import { useIsXSmall } from '@hooks/responsiveHooks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Container,
	Paper,
	Stack,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	useMediaQuery,
	type Theme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMemo, type ReactNode } from 'react';

const OverviewTablePaper = ({ children }: { children: ReactNode }) => {
	return (
		<Paper
			elevation={2}
			sx={{
				borderRadius: { xs: '8px', sm: '16px' },
				overflowX: 'hidden',
				height: '100%',
			}}>
			{children}
		</Paper>
	);
};

const ResponsiveTableCell = styled(TableCell)(({ theme }) => ({
	padding: '12px',
	[theme.breakpoints.down('sm')]: {
		padding: '8px',
	},

	textOverflow: 'ellipsis',
	overflow: 'hidden',
}));

interface CoverageTableProps {
	onExpandedChanged?: (expanded: boolean) => void;
}

interface OverviewItemStackProps {
	children: ReactNode[];
	underline: boolean;
	compact: boolean;
	hideBorder?: boolean;
}

const OverviewItemStack = ({ children, underline, hideBorder, compact }: OverviewItemStackProps) => (
	<Stack
		direction='row'
		justifyContent={underline ? 'space-between' : 'flex-start'}
		alignItems='center'
		spacing={2}
		sx={{
			pt: { xs: 1, md: 0 },
			pb: { xs: 1, md: 0 },
			pl: { xs: 0, md: hideBorder ? 0 : 2 },
			borderBottom: underline ? '1px solid rgba(224, 224, 224, 1)' : 'none',
			borderLeft: hideBorder || compact ? 'none' : '1px solid rgba(224, 224, 224, 1)',
		}}>
		{children}
	</Stack>
);

const OverviewHeader = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	fontSize: '14px',
	[theme.breakpoints.down('sm')]: {
		fontSize: '12px',
	},
}));

const OverviewText = styled(Typography)(({ theme }) => ({
	overflow: 'hidden',
	textOverflow: 'ellipsis',

	fontSize: '14px',
	[theme.breakpoints.down('sm')]: {
		fontSize: '12px',
	},
}));

const CoverageTable = (props: CoverageTableProps) => {
	const rollerData = useAppSelector(selectRollerData);
	const boundaries = useAppSelector(selectBoundaries);
	const coverages = useAppSelector(selectCoverages);
	const isAsphaltReport = useAppSelector(selectAnalysisType) !== 'BASE';

	const isXS = useIsXSmall();
	const isSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const { engineers, project } = useMemo(
		() => ({
			engineers: rollerData?.overviews
				.map((o) => o.engineer)
				.unique()
				.reduce((str, engineer) => {
					if (str) {
						return `${str}, ${engineer}`;
					}
					return engineer;
				}, ''),

			project: rollerData?.overviews[0].project.split(' (')[0],
		}),
		[rollerData]
	);

	const handleExpand = (event: React.SyntheticEvent, expanded: boolean) => {
		props.onExpandedChanged?.(expanded);
	};

	return (
		<OverviewTablePaper>
			<Accordion onChange={handleExpand}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					sx={{
						'&.Mui-expanded': {
							minHeight: { xs: '36px', sm: '48px' },
						},
						'& .MuiAccordionSummary-content': {
							marginTop: 0,
							marginBottom: 0,
						},
					}}>
					Report Specifications
				</AccordionSummary>
				<AccordionDetails>
					<Stack direction='column' sx={{ overflowX: 'auto' }}>
						<Container
							sx={{
								pl: '8px!important',
								pr: '8px!important',
								pb: { xs: 1 },
							}}>
							<Grid container sx={{ width: 1 }}>
								<Grid container size={{ xs: 12, md: 6 }} spacing={2}>
									<Grid size={6}>
										<OverviewItemStack compact={isSM} underline={isSM} hideBorder>
											<OverviewHeader>{isXS ? 'Area' : 'Total Area'}</OverviewHeader>
											<OverviewText>{boundaries?.area?.toFixed(1)}m²</OverviewText>
										</OverviewItemStack>
									</Grid>

									<Grid size={6}>
										<OverviewItemStack compact={isSM} underline={isSM}>
											<OverviewHeader>{isXS ? 'Rollers' : 'IC Rollers'}</OverviewHeader>
											<OverviewText>{rollerData?.overviews.length}</OverviewText>
										</OverviewItemStack>
									</Grid>
								</Grid>

								<Grid container size={{ xs: 12, md: 6 }} spacing={2}>
									<Grid size={6}>
										<OverviewItemStack compact={isSM} underline={isSM}>
											<OverviewHeader>Operator</OverviewHeader>
											<Tooltip title={engineers} arrow>
												<OverviewText>{engineers}</OverviewText>
											</Tooltip>
										</OverviewItemStack>
									</Grid>

									<Grid size={6}>
										<OverviewItemStack compact={isSM} underline={isSM}>
											<OverviewHeader>Project</OverviewHeader>
											<Tooltip title={project} arrow>
												<OverviewText>{project}</OverviewText>
											</Tooltip>
										</OverviewItemStack>
									</Grid>
								</Grid>
							</Grid>
						</Container>

						{/**
						 * Coverages Table - shows the coverage of each spec
						 */}
						<TableContainer sx={{ maxHeight: '200px' }}>
							<Table
								stickyHeader
								aria-label='coverages table'
								sx={{
									overflow: 'auto',
									'& thead th:first-of-type': {
										position: 'sticky',
										left: 0,
										zIndex: 3,
									},
									'& thead .MuiTableCell-root': {
										fontSize: { xs: '14px', sm: '16px' },
										p: { xs: 1, sm: 2 },
									},
								}}>
								<TableHead>
									<TableRow>
										<TableCell align='left' width={40}>
											Spec
										</TableCell>
										{isAsphaltReport && (
											<>
												<TableCell align='center'>{isXS ? 'Target' : 'Target Passes'}</TableCell>
												<TableCell align='center' sx={{ minWidth: '80px' }}>
													{isXS ? 'Time' : 'Time Limit'}
												</TableCell>
												<TableCell align='center'>{isXS ? 'Temperature' : 'Temperature Cut-Off'}</TableCell>
											</>
										)}
										{!isAsphaltReport && (
											<>
												<TableCell align='center'>{isXS ? 'CMV' : 'Target CMV'}</TableCell>
												{/* <TableCell align='center'>{isXS ? 'E-Vib' : 'Target E-Vib'}</TableCell> */}
											</>
										)}
										<TableCell align='center'>Area</TableCell>
										<TableCell align='center'>Coverage</TableCell>
										{/* <TableCell align='center'>Pass</TableCell> */}
									</TableRow>
								</TableHead>

								<TableBody
									sx={{
										'& th': {
											backgroundColor: 'white',
											position: 'sticky',
											left: 0,
											zIndex: 1,
										},
									}}>
									{coverages?.map((coverage, i) => (
										<TableRow
											key={i}
											sx={{
												'&:last-child td, &:last-child th': {
													border: 0,
												},
												'& td:nth-of-type(4)': {
													borderLeft: '1px solid rgba(224, 224, 224, 1)',
												},
											}}>
											<ResponsiveTableCell component='th' scope='row' align='center'>
												#{coverage.spec.specNumber}
											</ResponsiveTableCell>
											{isAsphaltSpec(coverage.spec) && (
												<>
													<ResponsiveTableCell align='center'>
														{coverage.spec.targetPasses}
													</ResponsiveTableCell>
													<ResponsiveTableCell align='center' sx={{ minWidth: '80px' }}>
														{coverage.spec.timeLimit
															? `${coverage.spec.timeLimit.asMinutes()} mins`
															: 'No Limit'}
													</ResponsiveTableCell>
													<ResponsiveTableCell align='center'>
														{coverage.spec.temperatureCutoff
															? `${coverage.spec.temperatureCutoff}°C`
															: 'No Cutoff'}
													</ResponsiveTableCell>
												</>
											)}
											{isBaseSpec(coverage.spec) && (
												<>
													<ResponsiveTableCell align='center' sx={{ minWidth: '80px' }}>
														{coverage.spec.targetCMV ? `${coverage.spec.targetCMV}` : 'N/A'}
													</ResponsiveTableCell>
												</>
											)}
											<ResponsiveTableCell align='center'>{coverage.area.toFixed(1)}m²</ResponsiveTableCell>
											<ResponsiveTableCell align='center'>
												{coverage.coverage.toFixed(1)}%
											</ResponsiveTableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Stack>
				</AccordionDetails>
			</Accordion>
		</OverviewTablePaper>
	);
};

export default CoverageTable;
