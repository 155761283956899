import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, styled } from '@mui/material';
import { calcPixelSize } from '@shared/nearMapTypes';
import { useEffect, useState } from 'react';
import { Datum, type Point } from '../../../../shared/src/geometry/core/Coordinate';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import type { Bounds } from '../../data/rollerTypes';
import { selectRollerData } from '../analysis/analysisSlice';
import { nearmapApi } from '../api/nearmapApiSlice';
import CoverageChart from './coverage/CoverageChart';
import CoverageTable from './coverage/CoverageTable';
import Canvas from './map/Canvas';
import ExportFileView from './pdf/ExportFileView';
import styles from './ReportViewer.module.css';
import { setBackgroundImage, type BackgroundImage } from './viewerSlice';
import useStartupEffect from '@hooks/useStartupEffect';
import MapView from './map/MapView';
import { ErrorBoundary } from 'react-error-boundary';

const { getNearmapImageWMS } = nearmapApi.endpoints;

const FullScreenTab = styled(TabPanel)(({ theme }) => ({
	height: 'calc(100% - 48px)', // 56px is the height of the tab list (48px + 8px paddingTop)
	overflow: 'hidden',
	[theme.breakpoints.down('sm')]: {
		paddingTop: 12,
		paddingBottom: 12,
		paddingLeft: 8,
		paddingRight: 8,

		height: 'calc(100% - 48px)', // 48px is the height of the tab list (40px + 8px paddingTop)
	},
}));

function createBounds(min: Point, max: Point): Bounds {
	return {
		min,
		max,
		centre: {
			x: (min.x + max.x) / 2,
			y: (min.y + max.y) / 2,
		},
		width: max.x - min.x,
		height: max.y - min.y,
	};
}

function resizeWidth(bounds: Bounds, width: number): Bounds {
	const centre = bounds.centre;
	const halfWidth = width / 2;
	return createBounds(
		{
			x: centre.x - halfWidth,
			y: bounds.min.y,
		},
		{
			x: centre.x + halfWidth,
			y: bounds.max.y,
		}
	);
}

function resizeHeight(bounds: Bounds, height: number): Bounds {
	const centre = bounds.centre;
	const halfHeight = height / 2;
	return createBounds(
		{
			x: bounds.min.x,
			y: centre.y - halfHeight,
		},
		{
			x: bounds.max.x,
			y: centre.y + halfHeight,
		}
	);
}

function inflate(bounds: Bounds, distance: number): Bounds {
	return createBounds(
		{
			x: bounds.min.x - distance,
			y: bounds.min.y - distance,
		},
		{
			x: bounds.max.x + distance,
			y: bounds.max.y + distance,
		}
	);
}

// function calculatePixelSize(zoom: number, latitude: number): number {
//   const dist = 40_075_016.686 * Math.cos(toRadians(latitude)) / MathEx.pow2(zoom + 8);
//   return Math.abs(dist);
// }

const ReportViewer = () => {
	const [currentTab, setCurrentTab] = useState('1');
	const rollerData = useAppSelector(selectRollerData);
	const [specsShown, setSpecsShown] = useState(false);

	const dispatch = useAppDispatch();

	const onChangeCurrentTab = (event: React.SyntheticEvent, newValue: string) => {
		setCurrentTab(newValue);
	};

	useStartupEffect(() => {
		document.title = 'Paveset IC - Viewer';
	});

	useEffect(() => {
		if (!rollerData || rollerData.length === 0) {
			console.log('No roller data available to display in the report viewer');
		} else if (rollerData) {
			console.log(`Roller data available: ${rollerData.length} cells`);

			let bounds = inflate(rollerData.calculateBounds(), 25);
			if (bounds.height > bounds.width) {
				const newWidth = bounds.height * 1.375;
				bounds = resizeWidth(bounds, newWidth);
			} else if (bounds.width > bounds.height) {
				const newHeight = bounds.width / 1.375;
				bounds = resizeHeight(bounds, newHeight);
			}

			void (async () => {
				const { data, error, isError, isSuccess, status } = await dispatch(
					getNearmapImageWMS.initiate({
						minX: bounds.min.x,
						minY: bounds.min.y,
						maxX: bounds.max.x,
						maxY: bounds.max.y,
						datum: Datum.GDA2020,
						zone: 56,
						zoomLevel: 19,
						format: 'jpeg',
						apiKey: 'MWQ5ZDZlYmYtMWFiZC00NTBjLWJlM2QtMjY0MWFhMWQ2NjJk',
					})
				);

				if (isError) {
					console.error(`Error fetching nearmap image ${status}:`);
					console.error(error);
				} else if (isSuccess && data) {
					const blob = new Blob([new Uint8Array(data.image)], { type: 'image/jpeg' });
					const image = await createImageBitmap(blob);

					const bg: BackgroundImage = {
						imageBuffer: data.image,
						image,
						position: {
							x: data.origin.x,
							y: data.origin.y,
							datum: Datum.GDA2020,
							zone: 56,
						},
						pixelSize: calcPixelSize(19),
					};

					dispatch(setBackgroundImage(bg));

					console.log(
						`Nearmap image fetched: ${image.width}x${image.height} (${(data.image.byteLength / 1024).toFixed(
							1
						)} KB)`
					);
				}
			})();
		}
	}, [dispatch, rollerData]);

	return (
		<Box className={styles.container}>
			<TabContext value={currentTab}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider', height: 48 }}>
					<TabList
						onChange={onChangeCurrentTab}
						orientation='horizontal'
						variant='fullWidth'
						centered
						style={{ height: '100%' }}
						aria-label='report-viewer-tabs'>
						<Tab label='Coverage' value='1' sx={{ fontSize: { xs: 'regular', sm: 'large' }, pt: 2 }} />
						<Tab label='Pass Map' value='2' sx={{ fontSize: { xs: 'regular', sm: 'large' }, pt: 2 }} />

						<Tab label='PDF' value='3' sx={{ fontSize: { xs: 'regular', sm: 'large' }, pt: 2 }} />
					</TabList>
				</Box>

				<FullScreenTab value='1'>
					<Stack
						height='100%'
						direction='column'
						alignItems='stretch'
						spacing={0}
						justifyContent='space-between'
						sx={{ pb: 1 }}>
						<Box sx={{ pb: 2 }}>
							<CoverageTable onExpandedChanged={(expanded) => setSpecsShown(expanded)} />
						</Box>

						<Box sx={{ height: 1 }}>
							<CoverageChart isSpecsShown={specsShown} />
						</Box>
					</Stack>
				</FullScreenTab>

				<FullScreenTab value='2'>
					{/* <MapCanvas
            zoomLevel={1}
            rollerData={rollerData}
            containerStyle={{
              borderRadius: '16px',
            }}
          /> */}

					<>
						<MapView />
						{/* <CircularProgress
              size={60}
              thickness={3}
              sx={{
                position: 'absolute',
                top: 'calc(50% - 30px)',
                left: 'calc(50% - 30px)',
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              }}
            />*/}
					</>
				</FullScreenTab>

				<FullScreenTab value='3'>
					<ErrorBoundary fallback={<div>Failed to render PDF export</div>}>
						<ExportFileView />
					</ErrorBoundary>
				</FullScreenTab>
			</TabContext>
		</Box>
	);
};

export default ReportViewer;
