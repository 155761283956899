import type { Point, Rect } from '@shared/geometry/core/Coordinate';

// adjust to device to avoid blur
const { devicePixelRatio: ratio = 1.0 } = window;

type CanvasStateArgs = null | CanvasState;
type CanvasStateUpdateCause = 'translate' | 'scale' | 'rotate' | 'all';
interface CanvasStateSnapshot {
	translateX: number;
	translateY: number;
	scale: number;
	prevScale: number;
	rotation: number;
}

interface CanvasStateChange {
	cause: CanvasStateUpdateCause;
	state: CanvasStateSnapshot;
}

// Define an event map for your custom events.
interface CanvasStateEventMap {
	// You can specify a more specific type than 'any' if you know the shape of your state.
	StateChanged: CustomEvent<CanvasStateChange>;
}

export class CanvasState extends EventTarget {
	private _translateX: number = 0;
	private _translateY: number = 0;

	private _prevScale: number = ratio;
	private _scale: number = ratio;

	private _rotation: number = 0;

	private _stateDirty: boolean = false;
	private _scaleDirty: boolean = false;
	private _translateDirty: boolean = false;

	get translateX() {
		return this._translateX;
	}
	get translateY() {
		return this._translateY;
	}
	get scale() {
		return this._scale;
	}
	set scale(value: number) {
		this._prevScale = this._scale;
		this._scaleDirty = true;
		this._scale = value;
	}

	constructor(args: CanvasStateArgs = null) {
		super();

		if (args) {
			this._translateX = args._translateX;
			this._translateY = args._translateY;
			this._scale = args._scale;
			this._prevScale = args._prevScale;
			this._rotation = args._rotation;
		}
	}

	public getTransform(): DOMMatrix {
		const transform = new DOMMatrix();
		transform.translateSelf(this._translateX, this._translateY);
		transform.scaleSelf(this._scale, -this._scale);
		transform.rotateSelf(this._rotation);
		return transform;
	}

	/**
	 * Gets the current state of the canvas	as a regular object (not a class)
	 * @returns the current state of the canvas
	 */
	public getSnapshot(): CanvasStateSnapshot {
		return {
			translateX: this._translateX,
			translateY: this._translateY,
			scale: this._scale,
			prevScale: this._prevScale,
			rotation: this._rotation,
		};
	}

	/**
	 * Copies the properties of this instance to a new instance
	 * @returns a new instance of CanvasState with the same properties as this instance
	 */
	public clone(): CanvasState {
		return new CanvasState(this);
	}

	public resetState(): void {
		this._translateX = 0;
		this._translateY = 0;
		this._prevScale = 1;
		this._scale = 1;
		this._rotation = 0;
	}

	public clearFlags(): void {
		this._translateDirty = false;
		this._scaleDirty = false;
		this._stateDirty = false;
	}

	public translateTo(x: number, y: number): void {
		this._translateX = x;
		this._translateY = y;
		this.onStateUpdated('translate');
	}

	public center(bounds: Rect, point: Point): void {
		this._translateX = bounds.width / 2 - point.x * this._scale;
		this._translateY = bounds.height / 2 - point.y * -this._scale;
		this.onStateUpdated('translate');
	}

	public zoomBy(factor: number, point: Point, worldUnits: boolean = false): void {
		// if (this._translateDirty || this._stateDirty || factor === 0) {
		//   //console.log('state dirty: ', this._stateDirty);
		//   //return;
		// }

		this.scale *= factor;
		let p = {
			x: point.x,
			y: point.y,
		};

		if (!worldUnits) {
			let mouseX = point.x - this._translateX;
			let mouseY = point.y - this._translateY;

			mouseX /= this._prevScale;
			mouseY /= -this._prevScale;

			p = { x: mouseX, y: mouseY };
		}

		const currX = p.x * this._prevScale;
		const currY = p.y * this._prevScale;
		const newX = p.x * this._scale;
		const newY = p.y * this._scale;

		this._translateX += -(newX - currX);
		this._translateY += newY - currY;

		this.onStateUpdated('all');
	}

	private onStateUpdated(cause: CanvasStateUpdateCause): void {
		this._stateDirty = true;
		this._translateDirty = cause === 'translate' || cause === 'all';
		this._scaleDirty = cause === 'scale' || cause === 'all';

		this.dispatchEvent(new CustomEvent('StateChanged', {
			detail: {
				cause: cause,
				state: this.getSnapshot(),
			}
		}));
	}

	// Typed overload for our custom event
	addEventListener<K extends keyof CanvasStateEventMap>(
		type: K,
		listener: ((this: CanvasState, ev: CanvasStateEventMap[K]) => void) | null,
		options?: boolean | AddEventListenerOptions
	): void;

	// General overload for other events
	addEventListener(
		type: string,
		listener: EventListenerOrEventListenerObject | null,
		options?: boolean | AddEventListenerOptions
	): void;

	// Implementation that calls the super method.
	addEventListener(
		type: string,
		listener: EventListenerOrEventListenerObject | null,
		options?: boolean | AddEventListenerOptions
	): void {
		super.addEventListener(type, listener, options);
	}

	// Similarly, provide overloads for removeEventListener.
	removeEventListener<K extends keyof CanvasStateEventMap>(
		type: K,
		listener: ((this: CanvasState, ev: CanvasStateEventMap[K]) => void) | null,
		options?: boolean | EventListenerOptions
	): void;

	removeEventListener(
		type: string,
		listener: EventListenerOrEventListenerObject | null,
		options?: boolean | EventListenerOptions
	): void;

	removeEventListener(
		type: string,
		listener: EventListenerOrEventListenerObject | null,
		options?: boolean | EventListenerOptions
	): void {
		super.removeEventListener(type, listener, options);
	}
}
