import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { GridCoordinate } from '../../../../shared/src/geometry/core/Coordinate';
import type { Boundary } from '../../data/rollerTypes';
import type { SliceActions } from '../../tools/helpers';
import type { Alignment } from '@shared/alignmentTypes';

export interface BackgroundImage {
	imageBuffer: ArrayBuffer;
	image: ImageBitmap;
	position: GridCoordinate;
	pixelSize: number;
}

export interface ViewerState {
	backgroundImage?: BackgroundImage;
	boundaries?: Boundary[];

	coverageChartImage?: string;

	alignment?: Alignment;
}

const initialState: ViewerState = {
	backgroundImage: undefined
};

export const viewerSlice = createSlice({
	name: 'viewer',
	initialState,
	reducers: (create) => ({
		setBackgroundImage: create.reducer((state, action: PayloadAction<BackgroundImage>) => {
			state.backgroundImage = action.payload;
		}),

		setCoverageChartImage: create.reducer((state, action: PayloadAction<string>) => {
			state.coverageChartImage = action.payload;
		}),

		setAlignment: create.reducer((state, action: PayloadAction<Alignment>) => {
			state.alignment = action.payload;
		}),
	}),

	selectors: {
		selectBackgroundImage: (state) => state.backgroundImage,
		selectCoverageChartImage: (state) => state.coverageChartImage,
		selectAlignment: (state) => state.alignment,
	}
});

export type ViewerActions = typeof viewerSlice.actions;
export type ViewerActionTypes = SliceActions<typeof viewerSlice.actions>;

export const {
	setBackgroundImage,
	setCoverageChartImage,
	setAlignment
} = viewerSlice.actions;

export const {
	selectBackgroundImage,
	selectCoverageChartImage,
	selectAlignment
} = viewerSlice.selectors;

export const ignoredViewerActions = [
	'viewer/setBackgroundImage',
];

export const ignoredViewerPaths = [
	'viewer.backgroundImage.image',
	'viewer.backgroundImage.imageBuffer',
];
