import dayjs from 'dayjs';
import { RollerCell, RollerData, type AmplitudeSetting, type OverviewInfo, type RollerPass } from './rollerTypes';
import { processTextFile } from './utils/files';
import { Datum } from '@shared/geometry/core/Coordinate';
import { MercatorTransform } from '@shared/geometry/transforms/transforms';

export default class ConplantCSV {

	static async read(files: File[]): Promise<RollerData> {
		const data: RollerData[] = [];
		const overviews: OverviewInfo[] = [];

		const result = await Promise.all(files.map((file) => ConplantCSV.parse(file)));

		result.forEach((roller) => {
			if (!roller) {
				return;
			}

			const [rollerData, overview] = roller;
			data.push(rollerData);
			overviews.push(overview);
		});

		if (data.length > 1) {
			// TODO: merge conplant files data
			console.error('Cannot merge Conplant files yet');
			return data[0];
		}

		data[0].overviews = overviews;
		return data[0];
	}

	static async parse(file: File): Promise<[RollerData, OverviewInfo]> {
		const data = new RollerData(0.2);
		const start = dayjs();

		const rollers: string[] = [];
		let earliestDate = dayjs('9999-12-31T23:59:59Z');
		let errorMsg: string | null = null;
		await processTextFile(file, { headerLines: 1 }, (line) => {
			const fields = line.split(';');

			const timestamp = dayjs(`${fields[0]} ${fields[1]}`, 'DD/MM/YYYY hh:mm:ss A');

			const easting = parseFloat(fields[2]);
			const northing = parseFloat(fields[3]);

			const passNumber = parseInt(fields[4]);
			const temperature = parseInt(fields[5]);
			const direction = fields[6] === 'Backward' ? 'R' : 'F';
			const amplitudeSetting: AmplitudeSetting = fields[10] === '<n.a.>' ? 'Static' : 'Unknown';
			const rollerId = fields[11];

			// const transform = MercatorTransform.fromGDA2020ToGDA94();
			// const coords = transform.transformRedfearn({ x: easting, y: northing, datum: Datum.GDA2020, zone: 56 });
			const coords = {
				x: easting,
				y: northing,
				datum: Datum.GDA2020,
				zone: 56
			};

			const rollerPass: RollerPass = {
				timestamp,
				coords,
				rollerId,
				passNumber,
				temperature,
				direction,
				amplitudeSetting,

				gnss: 'Unknown',
				amplitude: -1,
				cmv: -1,
				speed: -1,
			};

			if (!rollers.includes(rollerId)) {
				rollers.push(rollerId);
			}

			if (timestamp < earliestDate) {
				earliestDate = timestamp;
			}

			data.addOrUpdate(rollerPass);
		}, (error) => {
			errorMsg = `Error reading file ${file.name}: ${error}`;
		});

		const end = dayjs();
		console.log(`Conplant CSV read time: ${end.diff(start, 'millisecond')} ms`);

		if (errorMsg) {
			throw new Error(errorMsg);
		}

		for (const cell of data) {
			const passes = cell.passes.sort((a, b) => a.timestamp?.diff(b.timestamp) ?? -1);
			for (let i = 0; i < passes.length; ++i) {
				passes[i].passNumber = i + 1;
			}
		}

		const overview: OverviewInfo = {
			rollerId: rollers.join(', '),
			engineer: 'Unknown',
			epsg: 56,
			epsgName: 'GDA2020 / MGA zone 56',
			project: file.name,
			date: earliestDate.subtract(5, 'hours'),
		};

		return [data, overview];
	}
}