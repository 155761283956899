import type { GridCoordinate, Point } from './Coordinate';

export interface IVector2 {
	x: number;
	y: number;

	length(): number;
	normalize(): IVector2;

	add(v: IVector2 | Point): IVector2;
	subtract(v: IVector2 | GridCoordinate): IVector2;
	multiplyScalar(scalar: number): IVector2;

	invert(): IVector2;
	dot(v: IVector2): number;
	cross(v: IVector2): number;

	/**
	 * Calculates the angle between this vector and another vector in radians
	 * @param v The other vector
	 */
	angleBetween(v: IVector2): number;

	perpendicular(direction: 'clockwise' | 'counterclockwise'): IVector2;

	subtractFrom(coord: Point): Point;
	calculateAngle(): number;
}

export class Vector2 implements IVector2 {
	x: number;
	y: number;

	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
	}

	length(): number {
		return Math.sqrt(this.x * this.x + this.y * this.y);
	}

	normalize(): IVector2 {
		const len = this.length();
		return len > 0 ? new Vector2(this.x / len, this.y / len) : new Vector2(0, 0);
	}

	add(v: IVector2): IVector2 {
		return new Vector2(this.x + v.x, this.y + v.y);
	}

	subtract(v: IVector2): IVector2 {
		return new Vector2(this.x - v.x, this.y - v.y);
	}

	multiplyScalar(scalar: number): IVector2 {
		return new Vector2(this.x * scalar, this.y * scalar);
	}

	invert(): IVector2 {
		return new Vector2(-this.x, -this.y);
	}

	dot(v: IVector2): number {
		return this.x * v.x + this.y * v.y;
	}

	cross(v: IVector2): number {
		return this.x * v.y - this.y * v.x;
	}

	angleBetween(v: IVector2): number {
		const dotProduct = this.dot(v);
		const magProduct = this.length() * v.length();
		if (magProduct === 0) return 0;
		const cosValue = dotProduct / magProduct;
		const clamped = Math.clip(Math.min(1, cosValue), -1, 1);
		return Math.acos(clamped);
	}

	perpendicular(direction: 'clockwise' | 'counterclockwise'): IVector2 {
		if (direction === 'clockwise') {
			return new Vector2(this.y, -this.x);
		} else {
			return new Vector2(-this.y, this.x);
		}
	}

	/**
	 * Subtracts this vector from a coordinate
	 * @param coord The coordinate to subtract this vector from
	 * @returns A new coordinate representing the result of subtracting this vector from the given coordinate
	 */
	subtractFrom(coord: Point): Point {
		return {
			x: coord.x - this.x,
			y: coord.y - this.y,
		};
	}

	/**
	 * Calculates the angle of this vector in radians, measured clockwise from the positive x-axis
	 * @returns The angle of this vector in radians, measured clockwise from the positive x-axis
	 */
	calculateAngle(): number {
		// const angleInRadians = Math.atan2(this.y, this.x);
		// const angleInDegrees = angleInRadians * 180 / Math.PI;
		// const normalizedAngle = (angleInDegrees + 360) % 360;
		// const clockwiseAngle = 360 - normalizedAngle;
		// return clockwiseAngle;

		let angle = Math.atan2(-this.y, this.x);
		if (angle < 0) {
			angle += 2 * Math.PI;
		}
		return angle;
	}

	static directional(from: Point, to: Point, normalize: boolean): IVector2 {
		if (normalize) {
			return calculateDirectionalVector(from, to).normalize();
		} else {
			return calculateDirectionalVector(from, to);
		}
	}
}

/**
 * Creates a {@link Vector2} representing the direction from one coordinate to another
 * @param from The starting coordinate
 * @param to The ending coordinate
 * @returns A {@link Vector2} representing the direction from `from` to `to` (i.e. `to - from`)
 */
export function calculateDirectionalVector(from: Point, to: Point): IVector2 {
	return new Vector2(to.x - from.x, to.y - from.y);
}

export function subtract(coord: Point, vector: IVector2): Point {
	return {
		x: coord.x - vector.x,
		y: coord.y - vector.y,
	};
}