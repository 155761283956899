import type {
	AccountInfo, CacheOptions, Configuration, NavigationOptions,
	PopupRequest, RedirectRequest, SilentRequest, SsoSilentRequest
} from '@azure/msal-browser';
import { LogLevel, NavigationClient } from '@azure/msal-browser';
import { isEdge, isFirefox, isIE } from './tools/helpers';
import type { NavigateFunction } from 'react-router-dom';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
	names: {
		signIn: 'B2C_1_SignIn',
		// forgotPassword: 'B2C_1_reset_v3',
		// editProfile: 'B2C_1_edit_profile_v2',
	},
	authorities: {
		signIn: {
			authority: 'https://pavesetreporter.b2clogin.com/pavesetreport.com/b2c_1_signin',
		},
		// forgotPassword: {
		//     authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
		// },
		// editProfile: {
		//     authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
		// },
	},
	authorityDomain: 'pavesetreporter.b2clogin.com',
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
	auth: {
		clientId: 'b809d053-63fd-4c78-a0ab-21ed4e4dff55', // This is the ONLY mandatory field that you need to supply.
		authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
		knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
		redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
		postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.

		// If "true", will navigate back to the original request location before processing the auth code response.
		navigateToLoginRequestUrl: true,

	},

	cache: {
		// This configures where your cache will be stored
		// - supposedly less secure than sessionStorage but gives us single sign-on across tabs
		cacheLocation: 'localStorage',

		// store auth state in cookies for certain browsers
		storeAuthStateInCookie: isIE() || isEdge() || isFirefox(),

		// TODO turn to true at some point...
		secureCookies: true
	} as CacheOptions,
	system: {
		allowPlatformBroker: true, // disable WAM broker

		loggerOptions: {
			logLevel: LogLevel.Warning,

			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					console.log(message);
					return;
				}

				switch (level) {
					case LogLevel.Error:
						console.error(`[ERROR] ${message}`);
						return;
					case LogLevel.Info:
						console.log(`[INFO] ${message}`);
						return;
					case LogLevel.Verbose:
						console.log(`[VERBOSE] ${message}`);
						return;
					case LogLevel.Warning:
						console.warn(`[WARNING] ${message}`);
						return;
					case LogLevel.Trace:
						console.log(`[TRACE] ${message}`);
						return;
					default:
						return;
				}
			}
		}
	}
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: PopupRequest | RedirectRequest | SsoSilentRequest = {
	scopes: [
		'https://pavesetreport.com/ic-backend/user_access',
		'https://pavesetreport.com/ic-backend/Reports.Create',
	],
};

export function getSilentTokenRequest(account: AccountInfo): SilentRequest {
	return {
		scopes: ['https://pavesetreport.com/ic-backend/user_access'],
		account
	};
}

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
	graphMeEndpoint: 'Enter_the_Graph_Endpoint_Herev1.0/me' //e.g. https://graph.microsoft.com/v1.0/me
};

/**
 *
 */
export class CustomNavigationClient extends NavigationClient {
	readonly navigate: NavigateFunction;

	constructor(navigate: NavigateFunction) {
		super();
		this.navigate = navigate;
	}

	navigateInternal(url: string, options: NavigationOptions): Promise<boolean> {
		console.log('navigateInternal called');
		console.log('  url: ', url);
		console.log('  options: ', options);

		const relativePath = url.replace(window.location.origin, '');

		if (options.noHistory) {
			this.navigate(relativePath, {
				replace: true,
			});
		} else {
			this.navigate(relativePath);
		}

		return Promise.resolve(false);
	}
}