import type { DevToolsEnhancerOptions } from '@reduxjs/toolkit';
import { isDebug } from '../tools/helpers';
import type { RootState } from '@app/store';

const productionDevToolsOptions: DevToolsEnhancerOptions = {
	features: {
		pause: false,
		export: false,
		test: false,
	},

	/**
   * Sanitizes the current state by replacing very large properties (such as the report data rows) with simple
   * overviews of the underlying data. This way it doesn't explode the state viewer when debugging
   */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	stateSanitizer: <S>(state: S, _index: any) => {
		//const rootState = state as RootState;

		// if (rootState.viewer.report || rootState.viewer.plotEntries) {
		//     try {
		//         return {
		//             ...state,
		//             viewer: {
		//                 ...state.viewer,
		//                 report: {
		//                     ...state.viewer.report,
		//                     runs: `<< REPORT RUNS: ${rootState.viewer.report?.runs?.length} run(s) >>`
		//                 },
		//                 runEntries: rootState.viewer.runEntries != null && `<< RUN ENTRIES: ${rootState.viewer.runEntries?.length} entries >>`,
		//                 plotEntries: rootState.viewer.plotEntries != null && `<< PLOT ENTRIES: ${rootState.viewer.plotEntries?.first()?.ch} to ${rootState.viewer.plotEntries?.last()?.ch} >>`
		//             }
		//         } as RootState;
		//     } catch {
		//         return state;
		//     }
		// }

		return state;
	}
};

const developmentDevToolsOptions: DevToolsEnhancerOptions = {
	// put redux-devtools config here...
	features: {
		pause: true,
		test: true,
		persist: true,
		jump: true
	},

	stateSanitizer: <S>(state: S, _index: number) => {
		const rootState = state as RootState;

		if (rootState.analysis.analysis?.setup.rollerData) {
			try {
				return {
					...rootState,
					analysis: {
						...rootState.analysis,
						analysis: {
							...rootState.analysis.analysis,
							setup: {
								...rootState.analysis.analysis.setup,
								rollerData: `<< ROLLER DATA: ${rootState.analysis.analysis.setup.rollerData.length} rows >>`,
								boundaries: `<< BOUNDARIES: ${rootState.analysis.analysis.setup.boundaries.length} boundaries >>`
							},
							chartsData: `<< CHART DATA: ${rootState.analysis.analysis.chartsData[rootState.analysis.analysis.setup.analysisType].length} rows >>`
						}
					}
				} as S;
			} catch {
				console.log('error');
				return state;
			}
		}

		return state;
	}
};

export const getDevToolsOptions = () => {
	return isDebug
		? developmentDevToolsOptions
		: productionDevToolsOptions;
};