import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import Canvas from './Canvas';
import AlignmentPicker from '../../dataPicker/alignment/AlignmentPicker';
import { useAppSelector } from '@app/hooks';
import { selectAlignment } from '../viewerSlice';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MapView = () => {
	const alignment = useAppSelector(selectAlignment);
	const [expanded, setExpanded] = useState(!alignment);

	return (
		<Stack width={1} height={1}>
			<Accordion
				elevation={0}
				expanded={expanded}

				onChange={() => setExpanded((e) => !e)}
				sx={{
					borderTopLeftRadius: '16px',
					borderTopRightRadius: '16px',
					':first-of-type': {
						borderTopLeftRadius: '16px',
						borderTopRightRadius: '16px',
					},
					'&.Mui-expanded': {
						margin: 0,
						borderTopLeftRadius: '16px',
						borderTopRightRadius: '16px',
					}
				}}>
				<AccordionSummary id='alignment-picker-header' expandIcon={<ExpandMoreIcon fontSize='medium' />} aria-controls='alignment-picker-content'>
					<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
						<span style={{ textAlign: 'left', alignContent: 'center' }}>Alignment: {alignment?.name ?? 'N/A'}</span>
						{/* <Switch sx={{
							textAlign: 'right',
							// '& .MuiSwitch-switchBase': (theme) => ({
							// 	color: grey[300],
							// 	'&:hover': {
							// 		backgroundColor: alpha(grey[300], theme.palette.action.hoverOpacity),
							// 	},
							// })
						}}
						/> */}
					</div>
				</AccordionSummary>

				<AccordionDetails>
					<AlignmentPicker />
				</AccordionDetails>
			</Accordion>

			<Canvas isAlignmentPickerOpen={expanded} />
		</Stack>
	);
};

export default MapView;