
type ProgressCallback = React.Dispatch<React.SetStateAction<number>>;

// function startChunkDownload(apiUrl: string, start: number, end: number): Promise<Response> {
//   console.log('start chunk request');
//   return fetch(apiUrl, {
//     method: 'GET',
//     headers: {
//       'Range': `bytes=${start}-${end}`
//     },
//   }).then((response) => {
//     console.log('end chunk request');
//     return response;
//   });
// }

// async function processChunkDownload(response: Response, onProgress: (size: number) => void): Promise<Uint8Array> {
//   if (!response.ok) {
//     throw new Error(`Error fetching chunk: ${response.statusText}`);
//   }

//   const reader = response.body!.getReader();
//   const contentLength = parseInt(response.headers.get('Content-Length') || '0', 10);
//   const buffer = new Uint8Array(contentLength);
//   let position = 0;

//   // eslint-disable-next-line no-constant-condition
//   while (true) {
//     const { done, value } = await reader.read();
//     if (done) break;

//     if (value) {
//       buffer.set(value, position);
//       position += value.length;
//       onProgress(value.length);
//     }
//   }

//   return buffer;
// }

async function downloadChunk(
	apiUrl: string,
	start: number,
	end: number,
	onProgress: (size: number) => void,
	signal?: AbortSignal): Promise<Uint8Array> {

	const headers: HeadersInit = {
		Range: `bytes=${start}-${end}`
	};
	const response = await fetch(apiUrl, {
		headers,
		signal
	});

	if (!response.ok) {
		throw new Error(`Error fetching chunk: ${response.statusText}`);
	}

	const reader = response.body!.getReader();
	const buffer = new Uint8Array(end - start + 1);
	let position = 0;

	 
	while (true) {
		const { done, value } = await reader.read();
		if (done) break;

		if (value) {
			//console.log('chunk downloaded: ' + value.length);

			buffer.set(value, position);
			position += value.length;
			onProgress(value.length);
		}
	}

	return buffer;
}

export interface DownloadOptions {
	chunkCount: number;
	progress: ProgressCallback;
	signal?: AbortSignal;
}

export async function downloadParallel(url: string, options: DownloadOptions): Promise<Uint8Array> {
	const { chunkCount, progress, signal } = options;
	const apiUrl = 'api/dynapac/report-download?url=' + encodeURIComponent(url);
	//const apiUrl = 'api/test/download/range';
	// First, perform a HEAD request to get the Content-Length
	const response = await fetch(apiUrl, {
		method: 'HEAD',
		signal
	});

	if (response.status !== 200) {
		throw new Error(`Failed to fetch from ${url}: ${response.statusText}`);
	}

	// ISSUE: Azure SWA function proxy seems to overwrite the content-length header on HEAD requests.
	//        So, we need to use a custom header to get the content length.
	const contentLength = parseInt(response.headers.get('X-Content-Length') || '0', 10);
	if (contentLength === 0) {
		throw new Error('X-Content-Length is not provided or zero.');
	}

	// Calculate the size of each chunk
	const chunkSize = Math.ceil(contentLength / chunkCount);
	const rangeRequests: Promise<Uint8Array>[] = [];

	const onProgress = (size: number) => {
		const inc = size / contentLength * 100;
		progress((prev) => prev + inc);
	};

	// Create chunkCount parallel range requests
	for (let i = 0; i < chunkCount; i++) {
		const start = i * chunkSize;
		const end = Math.min(start + chunkSize - 1, contentLength - 1);

		//console.log('starting chunk:', i + 1);
		rangeRequests.push(
			downloadChunk(apiUrl, start, end, onProgress, signal)
		);
	}

	//console.log('waiting for chunks to download...');
	// Wait for all chunks to be downloaded
	const chunks = await Promise.all(rangeRequests);

	// Combine the chunks into a single ArrayBuffer
	const combined = new Uint8Array(contentLength);
	let offset = 0;
	for (const chunk of chunks) {
		combined.set(chunk, offset);
		offset += chunk.byteLength;
	}

	return combined;
}