export default class HashedMap<K, V> {
	private map: Map<number, V>;
	private hashFunction: (key: K) => number;

	constructor(hashFunction: (key: K) => number) {
		this.map = new Map<number, V>();
		this.hashFunction = hashFunction;
	}

	keys(): IterableIterator<number> {
		return this.map.keys();
	}

	set(key: K, value: V): void {
		const hash = this.hashFunction(key);
		this.map.set(hash, value);
	}

	get(key: K): V | undefined {
		const hash = this.hashFunction(key);
		return this.map.get(hash);
	}

	has(key: K): boolean {
		const hash = this.hashFunction(key);
		return this.map.has(hash);
	}

	delete(key: K): boolean {
		const hash = this.hashFunction(key);
		return this.map.delete(hash);
	}

	clear(): void {
		this.map.clear();
	}

	get size(): number {
		return this.map.size;
	}

	[Symbol.iterator](): Iterator<[number, V]> {
		return this.map.entries();
	}

	/**
   * More complex implementation to support a "reverseHashFunction" to retrieve original keys
   *
    [Symbol.iterator](): Iterator<[K, V]> {
    const keyIterator = this.map.entries();
    return {
      next: (): IteratorResult<[K, V]> => {
        const iteratorResult = keyIterator.next();
        if (iteratorResult.done) {
          return { value: null, done: true };
        } else {
          const [hash, value] = iteratorResult.value;
          // Assuming a reverseHashFunction exists to retrieve original key from hash
          // This function needs to be implemented based on the hashing mechanism
          const key = this.reverseHashFunction(hash);
          return { value: [key, value], done: false };
        }
      }
    };
  }

  // Placeholder for reverseHashFunction, needs proper implementation
  private reverseHashFunction(hash: number): K {
    throw new Error("reverseHashFunction needs to be implemented based on the hashing mechanism used.");
  }
   */
}